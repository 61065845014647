import React, { useContext, useState, useRef } from 'react'
import { View, ScrollView, Text, Pressable, KeyboardAvoidingView, Platform } from 'react-native'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import FooterMenu from '../components/FooterMenu'
import Input from '../components/Input'

import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'
import { axios } from '../scripts/Axios'

function ResetPasswordScreen({ route, navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, setLoading, mainStyles } = useContext(AppContext)

    const refPasswordConfirmation = useRef()

    const [inputs, setInputs] = useState({
        send_type: route.params.send_type,
        email: route.params.email,
        mobile: route.params.mobile,
        code: route.params.code,
        password: '',
        password_confirmation: '',
    })

    function savePassword(){

        setLoading(true)

        axios.post('reset-password', inputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                showAlert(t('alertPasswordChangedTitle'), response.data.message)
                navigation.navigate('Login')
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:30}}>
                    <Text style={mainStyles.title}>{t('resetPasswordTitle')}</Text>
                    <Text style={mainStyles.titleText}>{t('resetPasswordText')}</Text>
                </View>
                    
                <KeyboardAvoidingView enabled={Platform.OS === 'ios'} behavior="position" style={mainStyles.keyboardContent} keyboardVerticalOffset={0}>

                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Input name="password" secure={true} state={inputs} setState={setInputs} placeholder={t('resetPasswordPlaceholder')} icon={faLock} nextFocus={refPasswordConfirmation} />
                        </View>
                    </View>
                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Input reference={refPasswordConfirmation} name="password_confirmation" secure={true} state={inputs} setState={setInputs} placeholder={t('resetPasswordPlaceholderRepeat')} icon={faLock} />
                        </View>
                    </View>

                    <Pressable onPress={() => savePassword()} style={mainStyles.primaryButton}>
                        <Text style={mainStyles.primaryButtonText}>{t('resetPasswordButton')}</Text>
                    </Pressable>

                </KeyboardAvoidingView>
                    
                <FooterMenu navigation={navigation} />
            
            </View>

        </ScrollView>
    )

}

export default ResetPasswordScreen