import React, { useState, useEffect } from 'react'
import { authAxios } from '../scripts/Axios'
import Platzhalter from '../assets/person.png'
import { Video } from 'expo-av'

function AuthVideo({ video, width = 80, height = 80, styles = {}, chat = false, mode = 'cover' }) {

    const [src, setSrc] = useState(Platzhalter)

    useEffect(() => {
        loadVideo(video)
    }, [video])

    async function loadVideo(video){
        
        let videoResult = await authAxios.get('upload/' + video.id, {responseType: 'blob', timeout: 100000})
        if(videoResult.status === 200){
            let url = URL.createObjectURL(videoResult.data)
            setSrc({uri: url})
        }

    }

    return (        
        <Video          
        style={{width:width, height:height}}
        source={src}
        useNativeControls
        resizeMode="contain"
        onLoad={(load) => console.log('LOADED', load)}
        onError={(err) => console.log('ERROR', err)}
        />
    )

}

export default AuthVideo