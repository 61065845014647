import React, { useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faClock, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Countdown from '@freakycoder/react-native-countdown'

import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'


function RequestStatus({ request }) {

    const { colors } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)

    const states = [
        { text: t('requestStatusPending'), color: colors.blue, icon: faClock },
        { text: t('requestStatusDeclined'), color: colors.red, icon: faTimesCircle },
        { text: t('requestStatusAccepted'), color: colors.green, icon: faCheckCircle },
    ]

    const styles = StyleSheet.create({
        box: {
            backgroundColor: states[request.status].color,
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 8,
            paddingHorizontal: 15,
            marginHorizontal: -15,
            marginBottom: 15,
        },
        icon: {
            marginTop: 2,
        },
        textBox: {            
            marginLeft: 15,
        },
        requestStatus: {
            fontWeight: 'bold',
            fontSize: 14,
            color: colors.white,
        },
        timer: {
            fontSize: 12,
            color: colors.white,
        },
    })

    return (
        <View style={styles.box}>
            <FontAwesomeIcon icon={states[request.status].icon} size={22} color={colors.white} style={styles.icon} />
            <View style={styles.textBox}>
                <Text style={styles.requestStatus}>{ states[request.status].text }</Text>
                { request.status === 0 &&
                <View style={{flexDirection:'row'}}>
                    <Text style={styles.timer}>{t('requestAvailableTime')}: </Text>
                    <Text style={styles.timer}>
                        <Countdown
                        start={moment()}
                        end={moment(request.created_at).add(24, 'hours')}
                        textStyle={{color: colors.white, fontSize: 12, marginLeft: 4}}
                        defaultCountdown="-"
                        format={t('requestTimerFormat')} />
                    </Text>
                </View>
                }
            </View>
        </View>
    )

}

export default RequestStatus