import React, { useContext } from 'react'
import { View, Text } from 'react-native'
import { AnimatedCircularProgress } from 'react-native-circular-progress'
import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'

function MatchingCircle({ score, size = 'small', animate = true }) {

  const { colors } = useContext(AppContext)
  const { t } = useContext(LocalizationContext)

  return (
    <AnimatedCircularProgress
    size={size === 'small' ? 74 : 80}
    width={2}
    rotation={0}
    prefill={animate ? 0 : parseInt(score)}
    fill={parseInt(score)}
    tintColor={colors.primary}
    backgroundColor={colors.borderGrey}>
    {(fill) => (
        <View>
            <View style={{flexDirection:'row', marginBottom: -5}}>
                <Text style={{ fontSize: size === 'small' ? 26 : 32, color: colors.primary }}>{ Math.floor(fill) }</Text>
                <Text style={{ fontSize: size === 'small' ? 20 : 20, color: colors.primary, alignSelf:'flex-end', marginBottom:2 }}>%</Text>
            </View>
            <Text style={{ fontSize: 14, textAlign: 'center', color: colors.darkGrey }}>{t('match')}</Text>
        </View>
    )}
    </AnimatedCircularProgress>
  )

}

export default MatchingCircle
