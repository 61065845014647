import React, { useContext, useState, useRef } from 'react'
import { View, ScrollView, Text, Pressable, Platform, KeyboardAvoidingView } from 'react-native'

import Selector from '../../components/Selector'
import Input from '../../components/Input'
import Loader from '../../components/Loader'
import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { authAxios } from '../../scripts/Axios'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function ProfileAddressScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { user, token, setUserData } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const [inputs, setInputs] = useState({
        telefon: user.telefon || '',
        strasse: user.strasse || '',
        hausnummer: user.hausnummer || '',
        plz: user.plz || '',
        ort: user.ort || '',
        land: user.land || '',
    })

    const refStrasse = useRef()
    const refHausnummer = useRef()
    const refPLZ = useRef()
    const refOrt = useRef()

    function saveInputs(){

        setLoading(true)

        authAxios.put('user', inputs)
        .then(response => {

            setLoading(false)    

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{                
                setNewUserData(response.data.user)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    async function setNewUserData(data){
        await setUserData(data, token)
        navigation.navigate('PB Medien')
    }

    return (
        <KeyboardAvoidingView enabled={Platform.OS === 'ios'} behavior="padding" style={mainStyles.keyboardContent} keyboardVerticalOffset={80}>
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:30}}>
                    <Text style={mainStyles.title}>{t('profileAddressTitle')}</Text>
                    <Text style={mainStyles.titleText}>{t('profileAddressText')}</Text>
                </View>

                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input name="telefon" state={inputs} setState={setInputs} placeholder="Telefon" nextFocus={refStrasse} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={[mainStyles.formCol, {flex:.75}]}>
                        <Input reference={refStrasse} name="strasse" state={inputs} setState={setInputs} placeholder={t('fieldStreet')} nextFocus={refHausnummer} />
                    </View>
                    <View style={[mainStyles.formCol, {flex:.25}]}>
                        <Input reference={refHausnummer} name="hausnummer" state={inputs} setState={setInputs} placeholder={t('fieldStreetNr')} nextFocus={refPLZ} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input reference={refPLZ} name="plz" state={inputs} setState={setInputs} placeholder={t('fieldPLZ')} nextFocus={refOrt} />
                    </View>
                    <View style={mainStyles.formCol}>
                        <Input reference={refOrt} name="ort" state={inputs} setState={setInputs} placeholder={t('fieldCity')} cityAPI={true} cityPLZ={inputs.plz} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Selector name="land" options={[
                            {label: t('germany'), value: 'DE'},
                            {label: t('poland'), value: 'PL'},
                        ]} state={inputs} setState={setInputs} label={t('fieldCountry')} />
                    </View>
                </View>
            
                <View style={mainStyles.buttonContainer}>
                    <Pressable onPress={() => saveInputs()} style={mainStyles.secondaryButton}>
                        <Text style={mainStyles.secondaryButtonText}>{t('continue')}</Text>
                    </Pressable>
                </View>

            </View>

        </ScrollView>
        </KeyboardAvoidingView>
    )

}

export default ProfileAddressScreen