import React, { useContext } from 'react'
import { StyleSheet, View, Text, Pressable } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'

export const MultiPicker = ({multi, readonly, options, name, state, setState, resetID = null}) => {

    const { colors, mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)

    const styles = StyleSheet.create({
        multiPickerContainer: {
            marginVertical: 10,
            borderRadius: 10,
            borderWidth: 1,
            borderColor: colors.borderGrey,
            overflow: 'hidden',
        },
        multiPickerItem: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: colors.white,
            borderTopWidth: 1,
            borderColor: colors.borderGrey,
            paddingVertical: 10,
        },
        multiPickerItemHover: {
            backgroundColor: colors.lightGrey,
        },
        multiPickerIcon: {
            marginHorizontal: 10,
        },
        multiPickerItemText: {
            fontSize: 15,
            lineHeight: 22,
            width: '85%',
        },
        multiInfo: {
            color:colors.darkGrey,
            fontSize: 12,
            paddingLeft: 10,
            paddingVertical: 5,
            fontWeight: 'bold',
        },
    })

    const selectItem = (value) => {

        if(!readonly){
            if(multi){
                
                let currentState
                if(state[name] && state[name].length){
                    currentState = state[name]
                }else{
                    currentState = []
                }

                if(resetID === value){
                    // Reset                  
                    setState({
                        ...state,
                        [name]: [value],
                    })
                }else if(currentState.includes(value)){
                    // Remove item
                    let newArray = currentState.filter(item => item !== value)
                    setState({
                        ...state,
                        [name]: newArray,
                    })
                }else{
                    // Add item
                    let currentStateWithoutResetID = currentState.filter(item => item !== resetID)
                    let newArray = [...currentStateWithoutResetID, value]
                    setState({
                        ...state,
                        [name]: newArray,
                    })
                }

            }else{

                setState({
                    ...state,
                    [name]: value,
                })

            }
        }

    }

    return (
        <View style={styles.multiPickerContainer}>

            { multi && !readonly ? <Text style={styles.multiInfo}>{t('multiselect')}</Text> : null }

            { options.map((opt, index) => {

                let isActive = false
                if(state && state[name]){
                    if(multi){
                        isActive = state[name].includes(opt.value) ? true : false
                    }else{
                        isActive = state[name] === opt.value ? true : false
                    }
                }
                
                let itemStyle = styles.multiPickerItem
                let itemPressedStyle = [styles.multiPickerItem, styles.multiPickerItemHover]

                if(index === 0 && (!multi || readonly)){
                    itemStyle = [itemStyle, {borderTopWidth: 0}]
                    itemPressedStyle = [itemPressedStyle, {borderTopWidth: 0}]
                }

                return (
                    <View key={index}>
                    { (readonly && isActive) || !readonly ? (

                        <Pressable onPress={() => selectItem(opt.value)}>
                            {({pressed}) => (
                                <View style={!pressed ? itemStyle : itemPressedStyle}>
                                    <FontAwesomeIcon icon={faCheckCircle} size={20} style={styles.multiPickerIcon} color={ isActive ? colors.primary : colors.mediumGrey } />
                                    <Text style={[styles.multiPickerItemText, {color: isActive ? colors.primary : colors.mediumGrey }]}>{t(opt.label.split('.').join(''), { defaultValue: opt.label })}</Text>
                                </View>
                            )}
                        </Pressable>
                        
                    ) : null }
                    </View>
                )

            }) }
        </View>
    )

}
