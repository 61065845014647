import React, { useState, useEffect, useContext } from 'react'
import { View, ScrollView, Text, RefreshControl, Pressable, Platform } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCaretRight, faStar } from '@fortawesome/free-solid-svg-icons'
import Accordion from 'react-native-collapsible/Accordion'
import moment from 'moment'
import Axios from 'axios'

import { LocalizationContext } from '../../scripts/LocalizationContext'
import { AppContext } from '../../scripts/AppContext'
import { UserContext } from '../../scripts/UserContext'
import { authAxios } from '../../scripts/Axios'
import CareStatus from '../../components/CareStatus'
import UserPreview from '../../components/UserPreview'

function CareEventScreen({ route, navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles, webStyles } = useContext(AppContext)
    const { user, userDataReady, userCares, accountContact, accountProfile, openRatings } = useContext(UserContext)

    const [refreshing, setRefreshing] = useState(false)
    const [cares, setCares] = useState([])
    const [sectionCares, setSectionCares] = useState(userCares)
    const [activeSections, setActiveSections] = useState([0])

    const textSize = 16
    const textSizeSmall = 12

    /* navigation.addListener('focus', () => {
        getCares()
    }) */

    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {

        if(userDataReady){
            getCares()
        }
        return () => cancelSource.cancel('get cares canceled')
    }, [userDataReady])

    useEffect(() => {
        setCares(userCares)
    }, [userCares])

    useEffect(() => {
        let today = moment().format('YYYY-MM-DD')
        let currentCare = cares.find(c => c.status === 2 && c.startdatum && c.enddatum && (moment(c.startdatum).isBefore(today) || moment(c.startdatum).isSame(today)) && (moment(c.enddatum).isAfter(today) || moment(c.enddatum).isSame(today)))
        let nextCares = cares.filter(c => c.status !== 1 && c.startdatum && moment(c.startdatum).isAfter(today))
        let previousCares = cares.filter(c => c.enddatum && moment(c.enddatum).isBefore(today))
        let canceledCares = cares.filter(c => c.status === 1)
        setSectionCares({
            current: currentCare,
            next: nextCares,
            previous: previousCares,
            canceled: canceledCares,
        })
    }, [cares])

    const getCares = () => {

        setRefreshing(true)

        authAxios.get('cares', {cancelToken: cancelSource.token})
        .then(response => {

            setRefreshing(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setCares(response.data.cares)
            }

        })
        .catch(error => console.log(error))

    }


    function renderHeader(section){
        return (
            <View style={mainStyles.accordionHeader}>
                <Text style={mainStyles.accordionTitle}>{section.title}</Text>
            </View>
        )
    }

    function renderContent(section){
        let activeSectionCares = sectionCares[section.category] ? sectionCares[section.category] : null
        if(!activeSectionCares){ return }
        return (
            <View style={{paddingTop:15, backgroundColor: colors.lightGrey}}>
                {sectionCares[section.category].length < 1 ? <Text style={[mainStyles.lightText, {marginBottom:15}]}>{t('careEventsNoEvents')}</Text> : null }
                {sectionCares[section.category].map((care, index) => {
                    let borderColor
                    let s = care.status !== 1 && section.category === 'previous' ? 3 : care.status
                    if(s === 0){ borderColor = colors.blue }
                    else if(s === 1){ borderColor = colors.red }
                    else if(s === 2){ borderColor = colors.green }
                    else if(s === 3){ borderColor = colors.mediumGrey }
                    return (
                        <View key={index} style={[mainStyles.careListItem, {borderColor: borderColor}]}>
                            <View style={mainStyles.careListItemInner}>

                                <View style={{marginBottom:10}}>
                                    <CareStatus care={care} previous={section.category === 'previous'} />                                    
                                </View>

                                { CareTimes(care) }
                                { CareAddress(care) }
                                <UserPreview type="care" viewUser={care.user} navigation={navigation} />                                
                            
                                { user.user_role === 1 && openRatings.find(c => c.id === care.id) &&
                                    <Pressable onPress={() => navigation.navigate('PB Bewerten', {care_id: care.id})} style={mainStyles.ratingBox}>
                                        <FontAwesomeIcon icon={faStar} size={20} color={colors.yellow} />
                                        <View style={{flexShrink:1, paddingHorizontal:10}}>
                                            <Text style={{color: colors.black}}>{t('careEventsRatingTitleBK')}</Text>
                                            <Text style={[mainStyles.lightText, {fontSize:13, textAlign:'left'}]}>{t('careEventsRatingTextBK')}</Text>
                                        </View>
                                        <View style={{flex:1, flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                            <FontAwesomeIcon icon={faCaretRight} size={12} color={colors.mediumGrey} style={{marginLeft:5}} />
                                        </View>
                                    </Pressable>
                                }
                                { user.user_role === 2 && openRatings.find(c => c.id === care.id) &&
                                    <Pressable onPress={() => navigation.navigate('BK Bewerten', {care_id: care.id})} style={mainStyles.ratingBox}>
                                        <FontAwesomeIcon icon={faStar} size={20} color={colors.yellow} />
                                        <View style={{flexShrink:1, paddingHorizontal:10}}>
                                            <Text style={{color: colors.black}}>{t('careEventsRatingTitle')}</Text>
                                            <Text style={[mainStyles.lightText, {fontSize:13, textAlign:'left'}]}>{t('careEventsRatingText')}</Text>
                                        </View>
                                        <View style={{flex:1, flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                            <FontAwesomeIcon icon={faCaretRight} size={12} color={colors.mediumGrey} style={{marginLeft:5}} />
                                        </View>
                                    </Pressable>
                                }

                            </View>
                        </View>
                    )
                })}
            </View>
        )
    }

    function CareTimes(care){
        return care.status === 0 || care.status === 1 ? (
            <View style={{flexDirection:'row', marginBottom:15}}>
                <Text style={{fontSize: textSize, color: colors.darkGrey}}>{t('startdate')}: </Text>
                <Text style={{fontSize: textSize, color: colors.primary, fontWeight: 'bold'}}>{moment(care.startdatum).format('DD.MM.YYYY')}</Text>
            </View>
        ) : (
            <View style={{marginBottom:15}}>
                <View style={{flexDirection:'row'}}>
                    { care.startdatum &&
                    <View style={{flexDirection:'row'}}>
                        <Text style={{fontSize: textSize, color: colors.darkGrey}}>{t('from')} </Text>
                        <Text style={{fontSize: textSize, color: colors.primary, fontWeight: 'bold'}}>{moment(care.startdatum).format('DD.MM.YYYY')} </Text>
                    </View>
                    }
                    { care.startzeit &&
                    <View style={{flexDirection:'row'}}>
                        <Text style={{fontSize: textSize, color: colors.darkGrey}}>{t('at')} </Text>
                        <Text style={{fontSize: textSize, color: colors.primary, fontWeight: 'bold'}}>{moment(care.startzeit, 'HH:mm:ss').format('HH:mm')} </Text>
                        <Text style={{fontSize: textSize, color: colors.darkGrey}}>{t('clock')}</Text>
                    </View>
                    }
                </View>
                <View style={{flexDirection:'row'}}>
                    { care.enddatum &&
                    <View style={{flexDirection:'row'}}>
                        <Text style={{fontSize: textSizeSmall, color: colors.mediumGrey}}>{t('until')} </Text>
                        <Text style={{fontSize: textSizeSmall, color: colors.mediumGrey, fontWeight: 'bold'}}>{moment(care.enddatum).format('DD.MM.YYYY')} </Text>
                    </View>
                    }
                    { care.startzeit &&
                    <View style={{flexDirection:'row'}}>
                        <Text style={{fontSize: textSizeSmall, color: colors.mediumGrey}}>{t('at')} </Text>
                        <Text style={{fontSize: textSizeSmall, color: colors.mediumGrey, fontWeight: 'bold'}}>{moment(care.endzeit, 'HH:mm:ss').format('HH:mm')} </Text>
                        <Text style={{fontSize: textSizeSmall, color: colors.mediumGrey}}>{t('clock')}</Text>
                    </View>
                    }
                </View>
            </View>
        )
    }

    function CareAddress(care){
        return user.user_role === 1 && care.status === 2 ? (
            <View style={{marginBottom:15}}>
                <Text style={mainStyles.label}>{t('careEventsAddress')}:</Text>
                <Text style={{color: colors.black}}>{ care.address.strasse + ' ' + care.address.hausnummer}</Text>
                <Text style={{color: colors.black}}>{ care.address.plz + ' ' + care.address.ort}</Text>
            </View>
        ) : null
    }

    function updateSections(activeSections){
        setActiveSections(activeSections)
    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer} refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={getCares} tintColor={colors.black} />
        }>

            <View style={[mainStyles.screenContainer, { paddingHorizontal: Platform.OS === 'web' ? 20 : 0}]}>

                { cares.length > 0 ? (
                <View style={{marginBottom: 50}}>
                    
                    { Platform.OS !== 'web' ? <Text style={[mainStyles.refreshText, {marginBottom:20}]}>{t('refreshInfo')}</Text> : null }

                    { sectionCares.current &&
                    <View style={Platform.OS === 'web' ? webStyles.currentCare : mainStyles.currentCare}>
                        <View style={mainStyles.currentCareInner}>

                            <View style={{backgroundColor:colors.primary, height:50, justifyContent:'center', marginHorizontal: -15}}>
                                <Text style={[mainStyles.accordionTitle, {color:'#fff', marginHorizontal:20}]}>{t('careEventsCategory1')}</Text>
                            </View>
                            
                            { user.user_role === 1 && openRatings.find(care => care.id === sectionCares.current.id) &&
                                <Pressable onPress={() => navigation.navigate('PB Bewerten', {care_id: sectionCares.current.id})} style={mainStyles.ratingBox}>
                                    <FontAwesomeIcon icon={faStar} size={20} color={colors.yellow} />
                                    <View style={{flexShrink:1, paddingHorizontal:10}}>
                                        <Text style={{color: colors.black}}>{t('careEventsRatingTitleBK')}</Text>
                                        <Text style={[mainStyles.lightText, {fontSize:13, textAlign:'left'}]}>{t('careEventsRatingTextBK')}</Text>
                                    </View>
                                    <View style={{flex:1, flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                        <FontAwesomeIcon icon={faCaretRight} size={12} color={colors.mediumGrey} style={{marginLeft:5}} />
                                    </View>
                                </Pressable>
                            }
                            { user.user_role === 2 && openRatings.find(care => care.id === sectionCares.current.id) &&
                                <Pressable onPress={() => navigation.navigate('BK Bewerten', {care_id: sectionCares.current.id})} style={mainStyles.ratingBox}>
                                    <FontAwesomeIcon icon={faStar} size={20} color={colors.yellow} />
                                    <View style={{flexShrink:1, paddingHorizontal:10}}>
                                        <Text style={{color: colors.black}}>{t('careEventsRatingTitle')}</Text>
                                        <Text style={[mainStyles.lightText, {fontSize:13, textAlign:'left'}]}>{t('careEventsRatingText')}</Text>
                                    </View>
                                    <View style={{flex:1, flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                        <FontAwesomeIcon icon={faCaretRight} size={12} color={colors.mediumGrey} style={{marginLeft:5}} />
                                    </View>
                                </Pressable>
                            }

                            <View style={{borderLeftWidth:4, borderColor: colors.green, paddingLeft: 15, marginTop:15}}>

                                { CareTimes(sectionCares.current) }
                                { CareAddress(sectionCares.current) }
                                <UserPreview type="care" viewUser={sectionCares.current.user} navigation={navigation} />

                            </View>

                        </View>
                    </View>
                    }

                    <View style={{borderTopWidth: sectionCares.current ? 1 : 0, borderColor: colors.borderGrey}}>
                        <Accordion activeSections={activeSections} sections={[
                            { title: t('careEventsCategory2'), category: 'next' },
                            { title: t('careEventsCategory3'), category: 'previous' },
                            //{ title: t('careEventsCategory4'), category: 'canceled' },
                        ]}
                        expandMultiple
                        underlayColor={colors.lightGrey}
                        renderHeader={renderHeader}
                        renderContent={renderContent}
                        onChange={updateSections} />
                    </View>

                </View>
                ):(
                <View style={mainStyles.screenContainer}>

                    {user.user_role === 1 &&
                    <View>
                        <Text style={[mainStyles.title, {marginBottom:0, textAlign:'left'}]}>{t('careEventsWelcome')},</Text>
                        <Text style={[mainStyles.title, {marginTop:0, textAlign:'left'}]}>{accountProfile && accountProfile.fullname}!</Text>
                        <Text style={[mainStyles.text, {marginBottom:10}]}>{t('careEventsWelcomeTextBK1')}</Text>
                        <Text style={[mainStyles.text, {marginBottom:10}]}>{t('careEventsWelcomeTextBK2')}</Text>
                        <Text style={[mainStyles.text, {marginBottom:30}]}>{t('careEventsWelcomeTextBK3')}</Text>
                        <Pressable onPress={() => navigation.navigate('Suche')} style={mainStyles.primaryButton}>
                            <Text style={mainStyles.primaryButtonText}>{t('careEventsWelcomeButtonBK')}</Text>
                        </Pressable>
                    </View>
                    }

                    {user.user_role === 2 &&
                    <View>
                        <Text style={[mainStyles.title, {marginBottom:0, textAlign:'left'}]}>{t('careEventsWelcome')},</Text>
                        <Text style={[mainStyles.title, {marginTop:0, textAlign:'left'}]}>{accountContact && accountContact.fullname}!</Text>
                        <Text style={[mainStyles.text, {marginBottom:10}]}>{t('careEventsWelcomeTextPB1')}</Text>
                        <Text style={[mainStyles.text, {marginBottom:10}]}>{t('careEventsWelcomeTextPB2')}</Text>
                        <Text style={[mainStyles.text, {marginBottom:10}]}>{t('careEventsWelcomeTextPB3')}</Text>
                        <Text style={[mainStyles.text, {marginBottom:30}]}>{t('careEventsWelcomeTextPB4')}</Text>
                        <Pressable onPress={() => navigation.navigate('Suche')} style={mainStyles.primaryButton}>
                            <Text style={mainStyles.primaryButtonText}>{t('careEventsWelcomeButtonPB')}</Text>
                        </Pressable>
                    </View>
                    }

                    <View style={[mainStyles.row, {marginTop: 60, justifyContent: 'space-between'}]}>
                        <View>
                            <Text style={mainStyles.formLabel}>{t('careEventsQuestionsText1')}</Text>
                            <Text style={mainStyles.label}>{t('careEventsQuestionsText2')}</Text>
                        </View>
                        <Pressable onPress={() => Platform.OS === 'web' ? navigation.navigate('Chat') : navigation.navigate('Nachrichten', {screen: 'Kommunikation', params: { screen: 'Chat' }})} style={[mainStyles.outlineButton, {width: 120}]}>
                            <Text style={mainStyles.outlineButtonText}>{t('careEventsQuestionsButton')}</Text>
                        </Pressable>
                    </View>

                </View>
                )}
                
            </View>

        </ScrollView>
    )

}

export default CareEventScreen