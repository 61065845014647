import React, { useState, useEffect, useContext } from 'react'
import { View, ScrollView, Text } from 'react-native'
import Axios from 'axios'

import { authAxios } from '../../scripts/Axios'
import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

import UserPreview from '../../components/UserPreview'


function AccountApprovalScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, mainStyles } = useContext(AppContext)
    const { user } = useContext(UserContext)

    const [matchedUsers, setMatchedUsers] = useState([])
    
    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {
    
        authAxios.get('matched-users-preview', {cancelToken: cancelSource.token})
        .then(response => {
    
            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setMatchedUsers(response.data.users)
            }
    
        })
        .catch(error => {
            console.log(error.response)
        })

        return () => cancelSource.cancel('get matched-users-preview canceled')
    
    }, [])

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={[mainStyles.container, {marginBottom:30}]}>
                <Text style={mainStyles.title}>{t('accountApprovalTitle')}</Text>
                <Text style={mainStyles.titleText}>{t('accountApprovalText1')}</Text>
                <Text style={[mainStyles.titleText, {marginTop: 20}]}>{t('accountApprovalText2')}.</Text>
                <Text style={[mainStyles.titleText, {marginTop: 20}]}>{t('accountApprovalText3')}</Text>
            </View>

            { matchedUsers.length > 0 &&
            <View style={{marginBottom:50}}>
                <View style={[mainStyles.container, {marginBottom:10}]}>
                    <Text style={mainStyles.label}>{ user.user_role === 1 ? t('accountApprovalText4BK') : t('accountApprovalText4') }</Text>
                </View>
                {matchedUsers.map(matchUser => {
                    return (
                        <View key={matchUser.id} style={mainStyles.profileListItem}>
                            <View style={mainStyles.profileListItemInner}>
                                <UserPreview type="search" viewUser={matchUser} navigation={navigation} />
                            </View>
                        </View>
                    )
                })}
            </View>
            }

        </ScrollView>
    )

}

export default AccountApprovalScreen