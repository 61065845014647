import { StyleSheet, Platform } from 'react-native'

export const lightColors = {
    primary: '#ad208e',
    lightGrey: '#F1F4F5',
    mediumGrey: '#7B919D',
    darkGrey: '#30434D',
    black: '#1b2125',
    borderGrey: '#D8DFE3',
    white: '#FFF',
    buttonWhite: '#FFF',
    transWhite: 'rgba(255,255,255,.8)',
    green: '#1ABB8B',
    yellowGreen: '#9bbb1a',
    blue: '#309CCA',
    yellow: '#ffc700',
    red: '#d65454',
    neonRed: '#ff1c5f',
}


export const lightStyles = StyleSheet.create({
    wrapper: {
        flex: 1,
        backgroundColor: lightColors.white,
    },
    scrollContainer: {
        minHeight: '100%',
        backgroundColor: lightColors.white,
        padding: Platform.OS === 'web' ? 30 : 0,
    },
    keyboardContent: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: lightColors.white,
    },
    screenContainer: Platform.OS === 'web' ? {
        width: '100%',
        maxWidth: 600,
        alignSelf: 'center',
        paddingHorizontal: 20,
        flex: 1,
    } : {
        paddingHorizontal: 20,
        flex: 1,
    },
    container: {
        paddingHorizontal: 20,
    },
    centeredContent: {
        flex: 1,
        justifyContent: 'center',
    },
    row: {
        flexDirection: 'row',
    },
    formRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: -5,
    },
    formCol: {
        flex: 1,
        margin: 5,
    },
    tableRow: {
        flexDirection: 'row',
        marginBottom: 5,
    },
    logo: {
        width: '85%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    title: {
        color: lightColors.primary,
        textAlign: 'center',
        fontSize: 22,
        marginTop: 30,
        marginBottom: 15,
    },
    title2: {
        color: lightColors.primary,
        fontSize: 20,
    },
    title3: {
        color: lightColors.darkGrey,
        fontSize: 20,
    },
    titleLine: {
        width: 50,
        height: 2,
        backgroundColor: lightColors.borderGrey,
        marginVertical: 15,
    },
    lightText: {
        color: lightColors.mediumGrey,
        textAlign: 'center',
        fontSize: 14,
    },
    refreshText: {
        color: lightColors.mediumGrey,
        textAlign: 'center',
        fontSize: 12,
        marginTop: 10,
    },
    titleText: {
        color: lightColors.darkGrey,
        lineHeight: 20,
        textAlign: 'center',
    },
    text: {
        color: lightColors.darkGrey,
        lineHeight: 20,
    },
    li: {
        fontSize: 14,
        fontWeight: 'bold',
        color: lightColors.darkGrey,
        marginTop: 10,
    },
    formLabel: {
        color: lightColors.primary,
        fontSize: 14,
        fontWeight: 'bold',
    },
    label: {
        color: lightColors.darkGrey,
        fontSize: 14,
        fontWeight: 'bold',
    },
    checkboxLabel: {
        color: lightColors.darkGrey,
        fontSize: 15,
        marginLeft: 8,
    },
    textarea: {
        height: 100,
        borderBottomWidth: 1,
        marginVertical: 10,
        fontSize: 16,
        textAlignVertical: 'top',
        color: lightColors.black,
    },
    input: {
        height: 56,
        borderBottomWidth: 1,
        marginBottom: 10,
        fontSize: 16,
        color: lightColors.black,
        borderColor: lightColors.borderGrey,
    },
    inputText: {
        marginTop: 18,
        fontSize: 16,
        color: lightColors.black,
    },
    selectContainer: {
        paddingBottom: 1,
        borderBottomWidth: 1,
        borderBottomColor: lightColors.borderGrey,
        marginBottom: 10,
    },
    select: {
        fontSize: 16,
        color: lightColors.black,
        height: 55,
        borderColor: lightColors.borderGrey,
    },
    sortSelectContainer: {
        marginTop: 4,
        borderWidth: 1,
        borderColor: lightColors.borderGrey,
        borderRadius: 4,
    },
    sortSelect: {
        fontSize: 14,
        color: lightColors.black,
        borderColor: lightColors.borderGrey,
    },
    multiSelectContainer: {
        borderBottomColor: lightColors.borderGrey,
        marginBottom: 10,
        marginTop: 10,
    },
    codeInput: {
        height: 56,
        borderBottomWidth: 1,
        borderBottomColor: lightColors.borderGrey,
        marginBottom: 10,
        fontSize: 32,
        width: 50,
        textAlign: 'center',
        color: lightColors.black,
    },
    inputWithIcon: {
        height: 56,
        borderBottomWidth: 1,
        marginBottom: 10,
        fontSize: 16,
        paddingLeft: 40,
        color: lightColors.black,
    },
    inputIcon: {
        position: 'absolute',
        top: 18,
    }, 
    buttonContainer: Platform.OS === 'web' ? {
        flex: 1,
        paddingTop: 20,
        marginTop: 30,
        marginBottom: 40,
    } : {
        flex: 1,
        justifyContent: 'flex-end',
        paddingTop: 20,
        marginBottom: 40,
    },
    primaryButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Platform.OS === 'web' ? 'auto' : '100%',
        alignSelf: 'center',
        minHeight: 56,
        paddingHorizontal: 20,
        paddingVertical: 15,
        backgroundColor: lightColors.primary,
        borderRadius: 4,
        elevation: 20,
    },
    primaryButtonText: {
        color: lightColors.buttonWhite,
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    secondaryButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Platform.OS === 'web' ? 'auto' : '100%',
        alignSelf: 'center',
        minHeight: 56,
        paddingHorizontal: 20,
        paddingVertical: 15,
        backgroundColor: lightColors.darkGrey,
        borderRadius: 4,
        elevation: 20,
    },
    secondaryButtonText: {
        color: lightColors.white,
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    outlineButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Platform.OS === 'web' ? 'auto' : '100%',
        alignSelf: 'center',
        minHeight: 40,
        paddingHorizontal: 20,
        paddingVertical: 6,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: lightColors.darkGrey,
    },
    outlineButtonText: {
        color: lightColors.darkGrey,
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    filterButton: {
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start',
        minHeight: 40,
        paddingHorizontal: 20,
        paddingVertical: 6,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: lightColors.darkGrey,
    },
    filterButtonText: {
        color: lightColors.darkGrey,
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    registerButton: {
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        minHeight: 40,
        paddingHorizontal: 20,
        paddingVertical: 6,
        backgroundColor: lightColors.darkGrey,
        borderRadius: 4,
        marginTop: 10,
    },
    registerButtonText: {
        color: lightColors.white,
        textAlign: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    pinkLink: {
        color: lightColors.primary,
    },
    footerMenu: Platform.OS == 'web' ? {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 80,
    } : {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,
    },
    footerText: {
        fontSize: 10,
        color: lightColors.mediumGrey,
    },
    infoBox: {
        paddingVertical: 20,
        alignItems: 'center',
        marginHorizontal: -30,
        marginTop: 30,
    },
    infoBoxText: {
        color: lightColors.white,
        fontSize: 18,
    },
    blueBG: { backgroundColor: lightColors.blue },
    greenBG: { backgroundColor: lightColors.green },
    chatScrollContainer: {
        paddingHorizontal: 15,
        paddingBottom: 20,
    },
    chatContainer: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderTopWidth: 1,
        borderColor: lightColors.lightGrey,
    },
    chatInputContainer: {
        flexDirection: 'row',
        paddingBottom: 10,
        backgroundColor: lightColors.white,
    },
    addAttachmentIcon: {
        position: 'absolute',
        zIndex: 2,
        top: 13,
        left: 8,
    },
    chatInput: {
        color: lightColors.darkGrey,
        flex: 1,
        minHeight: 50,
        backgroundColor: lightColors.lightGrey,
        paddingLeft: 42,
        paddingRight: 10,
        paddingVertical: 10,
        fontSize: 16,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
    },
    chatButton: {
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        width: 54,
        backgroundColor: lightColors.darkGrey,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
    chatDate: {
        color: lightColors.primary,
        borderBottomWidth: 1,
        borderColor: lightColors.lightGrey,
        fontSize: 16,
        marginTop: 40,
        marginBottom: 20,
        paddingBottom: 5,
    },
    chatLine: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    chatItem: {
        flex: 1,
        maxWidth: Platform.OS === 'web' ? 'auto' : '70%',
        borderRadius: 4,
        overflow: 'hidden',
    },
    chatItemText: {
        fontSize: 16,
    },
    chatTime: {
        color: lightColors.mediumGrey,
        marginHorizontal: 10,
        fontSize: 12,
        alignSelf: 'center',
    },
    currentCare: {
        marginVertical: 15,
    },
    currentCareInner: {
        paddingHorizontal: 15,
        paddingBottom: 20,
    },
    profileListItem: {
        marginBottom: 0,
        borderBottomWidth: 1,
        borderColor: lightColors.borderGrey,
    },
    profileListItemInner: {
        padding: 15,
        backgroundColor: lightColors.white,
    },
    profileListPressable: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    profileTableText: {
        marginBottom: 30,
        color: lightColors.mediumGrey,
    },
    careListItem: {
        marginHorizontal: 15,
        marginBottom: 20,
        borderLeftWidth: 4,
        backgroundColor: lightColors.white,
    },
    careListItemInner: {
        padding: 15,
    },
    message: {
        paddingTop: 6,
        paddingBottom: 10,
        paddingHorizontal: 15,
        marginBottom: 6,
        borderBottomWidth: 1,
        borderColor: lightColors.borderGrey,
    },
    messageDate: {
        color: lightColors.mediumGrey,
        fontSize: 14,
    },
    messageSubject: {
        color: lightColors.darkGrey,
        fontSize: 16,
    },
    mediaGrid: {
        backgroundColor: lightColors.lightGrey,
        marginBottom: 10,
        padding: 10,
    },
    mediaGridInner: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        paddingHorizontal: 10,
    },
    mediaGridImage: {
        margin: 3,
        borderRadius: 3,
    },
    addMediaButton: {
        borderColor: lightColors.mediumGrey,
        borderWidth: 2,
        borderRadius: 3,
        borderStyle: 'dashed',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 3,
    },
    uploadInfo: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 5,
    },
    overlayDeleteIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: -12,
        marginTop: -12,
        opacity: .8,
    },
    inlineTabContainer: {
        flex: 1,
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: lightColors.borderGrey,
        borderRadius: 20,
        minHeight: 40,
        marginBottom: 15,
        overflow: 'hidden',
    },
    inlineTab: {
        flex: 1,
        //backgroundColor: '#f00',
        justifyContent: 'center',
        alignItems: 'center',
        borderRightWidth: 1,
        borderColor: lightColors.borderGrey,
    },
    inlineTabInner: {
        flex:1,
        width:'100%',
        height:'100%',
        justifyContent:'center',
        alignItems:'center',
    },
    accordionHeader: {
        paddingHorizontal: 15,
        height: 50,
        borderBottomWidth: 1,
        borderColor: lightColors.borderGrey,
        justifyContent: 'center',
    },
    accordionTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        color: lightColors.primary,
        textTransform: 'uppercase',
    },
    ratingBox: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderColor: lightColors.borderGrey,
        borderRadius: 4,
    },
    openRating: {
        padding: 15,
        borderBottomWidth: 1,
        borderColor: lightColors.borderGrey,
    },
    messageHeader: {
        backgroundColor: lightColors.lightGrey,
        padding: 15,
    },
    messageHeaderSubject: {
        fontWeight: 'bold',
        color: lightColors.darkGrey,
        fontSize: 16,
        marginTop: 5,
    },
    messageContainer: {
        flex: 1,
        paddingHorizontal: 10,
        backgroundColor: lightColors.white,
    },
    messageAttachments: {
        paddingBottom: 20,
        backgroundColor: lightColors.white,
    },
    messageAttachmentsTitle: {
        paddingHorizontal: 15,
        paddingVertical: 10,
        backgroundColor: lightColors.lightGrey,
        color: lightColors.mediumGrey,
        fontSize: 13,
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
})

export const lightWebStyles = StyleSheet.create({
    app: {
        width: '100%',
        height: '100%',
        backgroundColor: lightColors.borderGrey
    },
    wrapper: {
        flex: 1,
        width: '100%',
        maxWidth: 1024,
        marginHorizontal: 'auto',
    },
    sideMenu: {
        flexBasis: 300,
        backgroundColor: lightColors.white,
        borderRightWidth: 1,
        borderColor: lightColors.borderGrey,
        padding: 25,
    },
    logo: {
        width: 250,
        height: 200,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    smallScreenContainer: {        
        width: '100%',
        maxWidth: 400,
        alignSelf: 'center',
        paddingHorizontal: 20,
        flex: 1,
    },
    currentCare: {
        marginVertical: 15,
        borderWidth: 1,
        borderColor: lightColors.primary,
    },
})