import Axios from 'axios'
import { Platform } from 'react-native'
import * as Localization from 'expo-localization'
import AsyncStorage from '@react-native-async-storage/async-storage'


const API_URL = __DEV__ ? 'http://192.168.178.29:8000/v1/' : 'https://api.senihelp24.de/v1/'
//const API_URL = 'https://api.senihelp24.de/v1/'



const axios = Axios.create({
    baseURL: API_URL,
})

axios.interceptors.request.use(async (config) => {

    config.params = {
        'origin': Platform.OS,
        'locale': Localization.locale,
    }
    return config

}, error => {
    console.log(error.response.data)
})



axios.interceptors.response.use((response) => {
    return response
}, error => {
    if(error){
        if(error.response){
            if(error.response.status && error.response.status === 401){
                
                const deleteStorageData = async () => {
                    await AsyncStorage.removeItem('user')
                    await AsyncStorage.removeItem('token')
                }
                deleteStorageData()

            }
        }
    }
})




const authAxios = Axios.create({
    baseURL: API_URL,
})

authAxios.interceptors.request.use(async (config) => {

    let token = await AsyncStorage.getItem('token')
    config.headers = {
        'Authorization': 'Bearer ' + JSON.parse(token),
    }
    config.params = {
        'origin': Platform.OS,
        'locale': Localization.locale,
    }
    return config

}, error => {
    console.log(error.response.data)
})





authAxios.interceptors.response.use((response) => {
    return response
}, error => {
    if(error){
        if(error.response){
            if(error.response.status && error.response.status === 401){
                
                const deleteStorageData = async () => {
                    await AsyncStorage.removeItem('user')
                    await AsyncStorage.removeItem('token')
                }
                deleteStorageData()

            }
        }
    }
})




export {axios, authAxios}