import React, { useState, useEffect, useRef, useContext } from 'react'
import { View, ScrollView, Text, Pressable, StyleSheet } from 'react-native'

import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { authAxios } from '../../scripts/Axios'
import { LocalizationContext } from '../../scripts/LocalizationContext'

import { MultiPicker } from '../../components/MultiPicker'
import Input from '../../components/Input'
import Loader from '../../components/Loader'
import Datepicker from '../../components/Datepicker'
import Selector from '../../components/Selector'

function ProfileAddPBScreen({ route, navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, mainStyles, colors } = useContext(AppContext)
    const { profileOptions } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const [inputs, setInputs] = useState({
        anrede: '',
        titel: '',
        vorname: '',
        nachname: '',
        geburtsdatum: null,
        nationalitaet: '',
        groesse: '',
        gewicht: '',
        familienstand: [],
        pflegegrad: [],
        beweglichkeit: [],
        einschraenkungen: [],
        besserung: [],
        sonstige_einschraenkungen: '',
        sinneswahrnehmung: [],
        geisteszustand: [],
        schlafverhalten: [],
        essen: [],
        grundpflege: [],
        hygiene: [],
        urinkontrolle: [],
        stuhlkontrolle: [],
        ankleiden: [],
        wohnsituation: [],
        wohnzustand: [],
        wohnort: [],
        haustiere: [],
        haustiere_welche: [],
        einkaufsmoeglichkeiten: [],
        bk_wohnen: [],
        charaktereigenschaften: [],
        raucher: [],
        bk_aktivitaeten: [],
        bk_anrede: [],
        bk_deutsch: [],
        bk_fuehrerschein: [],
        hobbys: '',
        umfeld: '',
        sonstiges: '',
    })

    const mandatoryFields = [
        ['anrede', 'vorname', 'nachname', 'familienstand', 'geburtsdatum', 'nationalitaet'],
        ['pflegegrad', 'beweglichkeit'],
        ['einschraenkungen', 'besserung'],
        ['sinneswahrnehmung', 'geisteszustand', 'schlafverhalten'],
        ['essen', 'grundpflege', 'hygiene', 'urinkontrolle', 'stuhlkontrolle', 'ankleiden'],
        ['wohnsituation', 'wohnzustand', 'wohnort', 'haustiere', 'einkaufsmoeglichkeiten', 'bk_wohnen'],
        ['charaktereigenschaften', 'raucher'],
        ['bk_aktivitaeten', 'bk_anrede', 'bk_deutsch', 'bk_fuehrerschein'],
    ]

    const refVorname = useRef()
    const refNachname = useRef()
    const refGroesse = useRef()
    const refGewicht = useRef()

    useEffect(() => {
        if(route.params && route.params.edit){
            setInputs(route.params.edit)
        }
    }, [route])

    useEffect(() => {

        const unsubListener = navigation.addListener('beforeRemove', (e) => {

            e.preventDefault()

            if(e.data.action.type === 'NAVIGATE'){
                navigation.dispatch(e.data.action)
            }else{
                showAlert(t('alertDiscardChangesTitle'), t('alertDiscardChanges'), [
                    { text: t('discardContinue'), style: 'cancel' },
                    { text: t('discard'), style: 'destructive', onPress: () => navigation.dispatch(e.data.action) },
                ])
            }

        })

        return unsubListener

    }, [navigation])


    function saveInputs(){

        setLoading(true)

        authAxios.post('profile', inputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{  
                navigation.navigate('Pflegebedürftige Personen', {profile: response.data.profile})
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    const styles = StyleSheet.create({
        page: {
            // ...
        },
    })

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer}>
                <View key={0} style={styles.page}>

                    <View style={{marginBottom:30}}>
                        <Text style={mainStyles.title}>{t('profileAddPBTitle')}</Text>
                        <Text style={mainStyles.titleText}>{t('profileAddPBText')}</Text>
                    </View>

                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Selector name="anrede" options={[
                                {label: t('fieldSalutation1'), value: 'Herr'},
                                {label: t('fieldSalutation2'), value: 'Frau'},
                                {label: t('fieldSalutation3'), value: 'Divers'},
                            ]} state={inputs} setState={setInputs} label={t('fieldSalutationLabel')} />
                        </View>
                        <View style={mainStyles.formCol}>
                            <Input name="titel" state={inputs} setState={setInputs} placeholder={t('fieldTitle')} nextFocus={refVorname} />
                        </View>
                    </View>
                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Input reference={refVorname} name="vorname" state={inputs} setState={setInputs} placeholder={t('fieldName')} nextFocus={refNachname} />
                        </View>
                        <View style={mainStyles.formCol}>
                            <Input reference={refNachname} name="nachname" state={inputs} setState={setInputs} placeholder={t('fieldSurname')} />
                        </View>
                    </View>
                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Selector name="familienstand" options={[
                                {label: t('fieldPersonalState1'), value: 1},
                                {label: t('fieldPersonalState2'), value: 2},
                                {label: t('fieldPersonalState3'), value: 3},
                                {label: t('fieldPersonalState4'), value: 4},
                            ]} state={inputs} setState={setInputs} label={t('fieldPersonalStateLabel')} />
                        </View>
                    </View>
                    <Datepicker name="geburtsdatum" state={inputs} setState={setInputs} label={t('fieldBirth')} />
                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Input name="nationalitaet" state={inputs} setState={setInputs} placeholder={t('fieldNationality')} nextFocus={refGroesse} />
                        </View>
                    </View>
                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Input reference={refGroesse} name="groesse" state={inputs} setState={setInputs} placeholder={t('fieldSize')} nextFocus={refGewicht} />
                        </View>
                        <View style={mainStyles.formCol}>
                            <Input reference={refGewicht} name="gewicht" state={inputs} setState={setInputs} placeholder={t('fieldWeight')} />
                        </View>
                    </View>
                        
                </View>
                <View key={1} style={styles.page}>

                    <View style={{marginBottom:30}}>
                        <Text style={mainStyles.title}>{t('profileAddPB1Title')}</Text>
                        <Text style={mainStyles.titleText}>{t('profileAddPB1Text')}</Text>
                    </View>

                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddCareLevelLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'pflegegrad').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="pflegegrad" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddMovementLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'beweglichkeit').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="beweglichkeit" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>

                </View>
                <View key={2} style={styles.page}>

                    <View style={{marginBottom:30}}>
                        <Text style={mainStyles.title}>{t('profileAddPB2Title')}</Text>
                        <Text style={mainStyles.titleText}>{t('profileAddPB2Text')}</Text>
                    </View>

                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddSicknessLabel')}</Text>
                        <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'einschraenkungen').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="einschraenkungen" state={inputs} setState={setInputs} style={mainStyles.select} resetID={17} />
                    </View>
                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Text style={mainStyles.label}>{t('profileAddCureLabel')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'besserung').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="besserung" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                    </View>
                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Text style={mainStyles.label}>{t('profileAddOtherSicknessLabel')}</Text>
                            <Input name="sonstige_einschraenkungen" state={inputs} setState={setInputs} placeholder={t('profileAddOtherSickness')} />
                        </View>
                    </View>

                </View>
                <View key={3} style={styles.page}>

                    <View style={{marginBottom:30}}>
                        <Text style={mainStyles.title}>{t('profileAddPB3Title')}</Text>
                        <Text style={mainStyles.titleText}>{t('profileAddPB3Text')}</Text>
                    </View>

                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddSenseLabel')}</Text>
                        <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'sinneswahrnehmung').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="sinneswahrnehmung" state={inputs} setState={setInputs} style={mainStyles.select} resetID={38} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddMentalLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'geisteszustand').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="geisteszustand" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddSleepLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'schlafverhalten').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="schlafverhalten" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>

                </View>
                <View key={4} style={styles.page}>

                    <View style={{marginBottom:30}}>
                        <Text style={mainStyles.title}>{t('profileAddPB4Title')}</Text>
                        <Text style={mainStyles.titleText}>{t('profileAddPB4Text')}</Text>
                    </View>

                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddEatingLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'essen').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="essen" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddBaseCareLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'grundpflege').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="grundpflege" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddHygieneLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'hygiene').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="hygiene" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddPeeLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'urinkontrolle').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="urinkontrolle" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddPooLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'stuhlkontrolle').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="stuhlkontrolle" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddClothingLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'ankleiden').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="ankleiden" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>

                </View>
                <View key={5} style={styles.page}>

                    <View style={{marginBottom:30}}>
                        <Text style={mainStyles.title}>{t('profileAddPB5Title')}</Text>
                        <Text style={mainStyles.titleText}>{t('profileAddPB5Text')}</Text>
                    </View>

                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddLivingSituationLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'wohnsituation').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="wohnsituation" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddLivingConditionLabel')}</Text>
                        <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'wohnzustand').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="wohnzustand" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddLivingPlaceLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'wohnort').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="wohnort" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddPetsLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'haustiere').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="haustiere" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    { inputs && inputs.haustiere === 86 &&
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddWhichPetsLabel')}</Text>
                        <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'haustiere_welche').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="haustiere_welche" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    }
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddShoppingLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'einkaufsmoeglichkeiten').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="einkaufsmoeglichkeiten" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddLivingBKLabel')}</Text>
                        <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'bk_wohnen').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="bk_wohnen" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>

                </View>
                <View key={6} style={styles.page}>

                    <View style={{marginBottom:30}}>
                        <Text style={mainStyles.title}>{t('profileAddPB6Title')}</Text>
                        <Text style={mainStyles.titleText}>{t('profileAddPB6Text')}</Text>
                    </View>

                    <View style={mainStyles.multiSelectContainer}>
                                <Text style={mainStyles.label}>{t('profileAddCharacterLabel')}</Text>
                        <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'charaktereigenschaften').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="charaktereigenschaften" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                                <Text style={mainStyles.label}>{t('profileAddSmokingLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'raucher').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="raucher" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>

                </View>
                <View key={7} style={styles.page}>

                    <View style={{marginBottom:30}}>
                        <Text style={mainStyles.title}>{t('profileAddPB7Title')}</Text>
                        <Text style={mainStyles.titleText}>{t('profileAddPB7Text')}</Text>
                    </View>

                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddActivitiesBKLabel')}</Text>
                        <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'bk_aktivitaeten').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="bk_aktivitaeten" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddSexBKLabel')}</Text>
                        <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'bk_anrede').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="bk_anrede" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddGermanBKLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'bk_deutsch').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="bk_deutsch" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('profileAddDrivingBKLabel')}</Text>
                        <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'bk_fuehrerschein').map(opt => { return {label: opt.value, value: opt.id} })}
                        name="bk_fuehrerschein" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>

                </View>
                <View key={8} style={styles.page}>

                    <View style={{marginBottom:30}}>
                        <Text style={mainStyles.title}>{t('profileAddPB8Title')}</Text>
                        <Text style={mainStyles.titleText}>{t('profileAddPB8Text')}</Text>
                    </View>

                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Text style={mainStyles.label}>{t('profileAddTextHobbiesLabel')}</Text>
                            <Input name="hobbys" multiline={true} state={inputs} setState={setInputs} placeholder={t('profileAddTextHobbies')} />
                        </View>
                    </View>
                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Text style={mainStyles.label}>{t('profileAddTextEnvironmentLabel')}</Text>
                            <Input name="umfeld" multiline={true} state={inputs} setState={setInputs} placeholder={t('profileAddTextEnvironment')} />
                        </View>
                    </View>
                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Text style={mainStyles.label}>{t('profileAddTextOtherLabel')}</Text>
                            <Input name="sonstiges" multiline={true} state={inputs} setState={setInputs} placeholder={t('profileAddTextOther')} />
                        </View>
                    </View>

                </View>
                
                            
                <View style={[mainStyles.buttonContainer, {marginBottom:30}]}>
                    <Pressable onPress={() => saveInputs()} style={mainStyles.primaryButton}>
                        <Text style={mainStyles.primaryButtonText}>{t('save')}</Text>
                    </Pressable>
                </View>

            </View>

        </ScrollView>
    )

}

export default ProfileAddPBScreen