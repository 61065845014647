import React, { useState, useContext } from 'react'
import { View, ScrollView, Text, Pressable, KeyboardAvoidingView, Platform } from 'react-native'
import { faComment, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

import FooterMenu from '../components/FooterMenu'
import { ChooseIcons, ChooseIcon } from '../components/ChooseIcons'
import Input from '../components/Input'
import MobileInput from '../components/MobileInput'

import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'
import { axios } from '../scripts/Axios'

function ForgotPasswordScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { setLoading, showAlert, mainStyles } = useContext(AppContext)

    const [inputs, setInputs] = useState({
        send_type: 'email',
        email: '',
        mobile: '',
    })

    function sendVerifyNotification(){

        setLoading(true)

        axios.post('forgot-password', inputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                navigation.navigate('Passwort vergessen - Verifizierung', inputs)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    return (
        <KeyboardAvoidingView enabled={Platform.OS === 'ios'} behavior="padding" style={mainStyles.keyboardContent} keyboardVerticalOffset={80}>
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:30}}>
                    <Text style={mainStyles.title}>{t('forgotPasswordTitle')}</Text>
                    <Text style={mainStyles.titleText}>{t('forgotPasswordText')}</Text>
                </View>

                <View style={mainStyles.centeredContent}>

                    <ChooseIcons name="send_type" state={inputs} setState={setInputs}>
                        <ChooseIcon label={t('fieldPerMail')} value="email" icon={faEnvelope} iconSize={50} />
                        <ChooseIcon label={t('fieldPerSMS')} value="sms" icon={faComment} iconSize={50} />
                    </ChooseIcons>

                    { inputs.send_type === 'email' && <Input type="email-address" name="email" state={inputs} setState={setInputs} placeholder={t('fieldEmail')} icon={faEnvelope} /> }
                    { inputs.send_type === 'sms' && <MobileInput name="mobile" state={inputs} setState={setInputs} placeholder={t('fieldMobile')} icon={faPhone} /> }

                    <Pressable onPress={() => sendVerifyNotification()} style={mainStyles.primaryButton}>
                        <Text style={mainStyles.primaryButtonText}>{t('forgotPasswordButton')}</Text>
                    </Pressable>

                </View>

                <FooterMenu navigation={navigation} />

            </View>

        </ScrollView>
        </KeyboardAvoidingView>
    )

}

export default ForgotPasswordScreen