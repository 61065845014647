import React, { useState, useContext } from 'react'
import { View, ScrollView, Text, Pressable, KeyboardAvoidingView, Platform } from 'react-native'
import { faComment, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

import FooterMenu from '../../components/FooterMenu'
import { ChooseIcons, ChooseIcon } from '../../components/ChooseIcons'
import Input from '../../components/Input'
import MobileInput from '../../components/MobileInput'

import { LocalizationContext } from '../../scripts/LocalizationContext'
import { AppContext } from '../../scripts/AppContext'
import { UserContext } from '../../scripts/UserContext'
import { authAxios } from '../../scripts/Axios'

function AccountConfirmationScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { setLoading, showAlert, mainStyles } = useContext(AppContext)
    const { user } = useContext(UserContext)

    const [inputs, setInputs] = useState({
        send_type: 'email',
        mobile: '',
    })

    function sendVerifyNotification(){

        setLoading(true)

        authAxios.post('account-confirmation', inputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                navigation.navigate('Konto Verifizierung 2', inputs)
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    return (
        <KeyboardAvoidingView enabled={Platform.OS === 'ios'} behavior="padding" style={mainStyles.keyboardContent} keyboardVerticalOffset={80}>
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:30}}>
                    <Text style={mainStyles.title}>{t('accountConfirmationTitle')}</Text>
                    <Text style={mainStyles.titleText}>{t('accountConfirmationText')}</Text>
                    <Text style={mainStyles.titleText}>{t('accountConfirmationText2')}</Text>
                    <Text style={mainStyles.titleText}>{t('accountConfirmationText3')}</Text>
                </View>
                    
                <View style={mainStyles.centeredContent}>

                    <ChooseIcons name="send_type" state={inputs} setState={setInputs}>
                        <ChooseIcon label={t('fieldPerMail')} value="email" icon={faEnvelope} iconSize={50} />
                        <ChooseIcon label={t('fieldPerSMS')} value="sms" icon={faComment} iconSize={50} />
                    </ChooseIcons>

                    { inputs.send_type === 'email' && <Input type="email-address" value={user.email} placeholder={t('fieldEmail')} icon={faEnvelope} editable={false} /> }
                    { inputs.send_type === 'sms' && <MobileInput name="mobile" state={inputs} setState={setInputs} placeholder={t('fieldMobile')} icon={faPhone} /> }

                    <Pressable onPress={() => sendVerifyNotification()} style={mainStyles.primaryButton}>
                        <Text style={mainStyles.primaryButtonText}>{t('accountConfirmationButton')}</Text>
                    </Pressable>

                </View>
                    
                <FooterMenu navigation={navigation} />
            
            </View>

        </ScrollView>
        </KeyboardAvoidingView>
    )

}

export default AccountConfirmationScreen