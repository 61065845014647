import React, { useContext, useState, useEffect } from 'react'
import { Image, Platform } from 'react-native'
import { authAxios } from '../scripts/Axios'
import { UserContext } from '../scripts/UserContext'
import Platzhalter from '../assets/person.png'
import VideoPlatzhalter from '../assets/video.png'

function AuthImage({ image, width = 80, height = 80, styles = {}, chat = false, mode = 'cover' }) {

    const { token } = useContext(UserContext)
    const [src, setSrc] = useState(Platzhalter)

    useEffect(() => {

        if(image && Platform.OS !== 'web'){

            let uri
            if(chat){

                uri = authAxios.defaults.baseURL + 'chat-upload/' + image.id
                setSrc({
                    uri: uri,
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })

            }else{

                let type = image.typ ? image.typ : image.type
                if(type.startsWith('video')){

                    let src = image.thumbnail ? {
                        uri: image.thumbnail,
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    } : VideoPlatzhalter
                    setSrc(src)

                }else{

                    uri = image.uri
                    setSrc({
                        uri: uri,
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })

                }
            }

        }
        else if(image && Platform.OS === 'web'){
            loadImage(image)
        }

    }, [image])


    async function loadImage(image){

        if(image.base64){
            setSrc({uri: image.uri})
        }else{

            let endpoint
            if(chat){ endpoint = '/chat-upload' }
            else{
                let type = image.typ ? image.typ : image.type
                endpoint = type.startsWith('video') ? '/thumbnail' : '/upload'
            }
    
            let imageResult = await authAxios.get(endpoint  + '/' + image.id, {responseType: 'blob', timeout: 30000})
            if(imageResult.status === 200){
                let url = URL.createObjectURL(imageResult.data)
                setSrc({uri: url})
            }

        }

    }

    return (
        <Image source={src} style={{width: width, height: height, ...styles}} resizeMode={mode} />
    )

}

export default AuthImage