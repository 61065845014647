import React, { useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import moment from 'moment'

import Rating from '../components/Rating'
import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'

function RatingList({ rating = 0, data, role }) {

    const { colors, mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)

    const styles = StyleSheet.create({
        head: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
        },
        item: {
            backgroundColor: colors.lightGrey,
            padding: 20,
            marginTop: 20,
            borderRadius: 3,
        },
        ratingRow: {
            flexDirection:'row',
            justifyContent: 'space-between',
        },
        name: {
            fontWeight: 'bold',
            color: colors.primary,
        },
        ratingLabel: {
            flex: .7,
            color: colors.darkGrey,
        },
        text: {
            color: colors.darkGrey,
            marginTop: 10,
            fontStyle: 'italic',
        },
    })

    return (
        <View style={[styles.container, {marginBottom:60}]}>

            { data.length <= 0 ? <Text style={[mainStyles.lightText, {marginTop: 30, marginBottom: 10}]}>{t('ratingsNoRatings')}</Text> : null }
            
            { data.length > 0 && 
                <View style={{marginTop:20}}>
                    <Rating value={rating} withText={true} size={28} />
                </View>
            }
            
            { data.map(item => {

                return (
                    <View key={item.id} style={styles.item}>
                        <View style={styles.head}>
                            <View>
                                <Text style={styles.name}>{item.name}</Text>
                                <Text style={{color: colors.mediumGrey}}>{t('atDate')} { moment(item.created_at).format('DD.MM.YYYY') }</Text>
                            </View>
                            <View>
                                <Rating value={item.rating_gesamt} />
                            </View>
                        </View>
                        { role === 1 ? (
                            <View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryBK1')}</Text>
                                    <Rating value={item.rating_erscheinungsbild} />
                                </View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryBK2')}</Text>
                                    <Rating value={item.rating_aufgeschlossenheit} />
                                </View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryBK3')}</Text>
                                    <Rating value={item.rating_kommunikation} />
                                </View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryBK4')}</Text>
                                    <Rating value={item.rating_kompetenz} />
                                </View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryBK5')}</Text>
                                    <Rating value={item.rating_haushalt} />
                                </View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryBK6')}</Text>
                                    <Rating value={item.rating_sprachkenntnisse} />
                                </View>
                                { item.rating_text ? (<Text style={styles.text}>{item.rating_text}</Text>) : null }
                            </View>
                        ) : (
                            <View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryPB1')}</Text>
                                    <Rating value={item.rating_wohnqualitaet} />
                                </View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryPB2')}</Text>
                                    <Rating value={item.rating_charakter} />
                                </View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryPB3')}</Text>
                                    <Rating value={item.rating_pflegeaufwand} />
                                </View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryPB4')}</Text>
                                    <Rating value={item.rating_umfeld} />
                                </View>
                                <View style={styles.ratingRow}>
                                    <Text style={styles.ratingLabel}>{t('ratingsCategoryPB5')}</Text>
                                    <Rating value={item.rating_freizeit} />
                                </View>
                                { item.rating_text ? (<Text style={styles.text}>{item.rating_text}</Text>) : null }
                            </View>
                        )}
                    </View>
                )
            }) }

        </View>
    )

}


export default RatingList