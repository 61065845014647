import React, { useEffect, useState, useContext } from 'react'
import { View, ScrollView } from 'react-native'
import Axios from 'axios'

import { authAxios } from '../../scripts/Axios'
import { UserContext } from '../../scripts/UserContext'
import RatingList from '../../components/RatingList'
import Loader from '../../components/Loader'
import { AppContext } from '../../scripts/AppContext'

function ProfileRatingsScreen({ route, navigation }) {

    const { showAlert, mainStyles, colors } = useContext(AppContext)
    const { user } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const [ratings, setRatings] = useState([])
    const[overallRating, setOverallRating] = useState(0)

    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {

        setLoading(true)

        authAxios.get('ratings/' + route.params.user_id, {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setRatings(response.data.ratings)
            }

        })
        .catch(error => {
            console.log(error.response)
        })
        .then(() => setLoading(false))

        return () => cancelSource.cancel('get ratings canceled')

    }, [])

    
    useEffect(() => {
        let overall = 0
        ratings.map(r => overall += r.rating_gesamt)
        overall = overall ? overall / ratings.length : 0
        setOverallRating(overall)
    }, [ratings])

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer}>
                
                <RatingList rating={overallRating} role={user.user_role === 1 ? 0 : 1} data={ratings} />

            </View>

        </ScrollView>
    )

}

export default ProfileRatingsScreen