import React, { useContext, useState } from 'react'
import { View, ScrollView, Text, Pressable } from 'react-native'
import moment from 'moment'

import Datepicker from '../../components/Datepicker'
import Loader from '../../components/Loader'
import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { authAxios } from '../../scripts/Axios'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function ProfileFinishScreen() {

    const { t } = useContext(LocalizationContext)
    const { showAlert, mainStyles, colors } = useContext(AppContext)
    const { user, token, setUserData } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const [inputs, setInputs] = useState({
        startdatum: null
    })

    function handleFinish(){

        if(user.user_role === 1){
            finish()
        }else{

            if(!moment(inputs.startdatum).isValid() || moment(inputs.startdatum).isBefore(moment())){
                showAlert(t('alertInvalidStartDateTitle'), t('alertInvalidStartDate'))
                return
            }

            showAlert(
                t('alertSaveStartDateTitle'),
                t('alertSaveStartDate', {date: moment(inputs.startdatum).format('D.M.YYYY')}),
                [
                    { text: t('no'), style: 'cancel' },
                    { text: t('yes'), onPress: () => finish() },
                ],
            )

        }

    }

    function finish(){
        
        setLoading(true)

        authAxios.post('profile-finish', inputs)
        .then(response => {
            
            if(response.data.type === 'success'){                
                setUserData(response.data.user, token)
            }
        })
        .catch(error => {
            console.log(error)
        })

    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer}>

                <View>
                    <Text style={mainStyles.title}>{t('profileFinishTitle')}</Text>
                    <Text style={mainStyles.titleText}>{t('profileFinishText')}</Text>
                </View>
                
                { user.user_role === 2 &&
                <View style={{flex:1}}>
                    <View style={mainStyles.centeredContent}>                
                        <Datepicker name="startdatum" state={inputs} setState={setInputs} label={t('profileFinishDateLabel')} minYear={moment().year()} addYears={1} />
                    </View>
                </View>
                }

                { user.user_role === 2 ? <Text style={mainStyles.titleText}>{t('profileFinishText2')}</Text> : null }

                <View style={mainStyles.buttonContainer}>
                    <Pressable onPress={handleFinish} style={mainStyles.primaryButton}>
                        <Text style={mainStyles.primaryButtonText}>{t('profileFinishButton')}</Text>
                    </Pressable>
                </View>
                
            </View>

        </ScrollView>
    )

}

export default ProfileFinishScreen