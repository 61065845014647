import React, { useContext } from 'react'
import { StyleSheet, View, Text, Pressable } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import { AppContext } from '../scripts/AppContext'


export const ChooseIcons = ({name, state, setState, children}) => {

    const { colors } = useContext(AppContext)

    const styles = StyleSheet.create({
        chooseIconContainer: {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            marginBottom: 30,
        },
        chooseIcon: {
            borderWidth: 2,
            borderRadius: 4,
            padding: 30,
        },
        chooseIconText: {
            lineHeight: 20,
            textAlign: 'center',
            marginTop: 5,
        },
    })

    return (
        <View style={styles.chooseIconContainer}>
        { children.map((child, index) => {
            let isActive = child.props.value === state[name] ? true : false
            return (
                <Pressable key={index} onPress={() => setState({...state, [name]: child.props.value})}>                                
                    <View style={[styles.chooseIcon, {borderColor: isActive ? colors.primary : colors.mediumGrey }]}>
                        <FontAwesomeIcon icon={ child.props.icon } size={ child.props.iconSize } color={ isActive ? colors.primary : colors.mediumGrey } />
                    </View>
                    <Text style={[styles.chooseIconText, {color: isActive ? colors.primary : colors.mediumGrey}]}>{ child.props.label }</Text>
                </Pressable>
            )
        }) }
        </View>
    )

}

export const ChooseIcon = () => {}