import React, { useContext } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../scripts/AppContext'


function Rating({ value, size = 16, withText = false }) {

    const { colors } = useContext(AppContext)

    const styles = StyleSheet.create({
        container: {
            flexDirection:'row',
            alignItems:'center',
        },
        text: {
            color: colors.darkGrey,
            fontWeight: 'bold',
            fontSize: size,
            marginRight: 6,
            marginTop: 1,
        }
    })

    return (
        <View style={styles.container}>
            { withText && value !== 0 ? <Text style={styles.text}>{ value.toFixed(1) }</Text> : null }
            <FontAwesomeIcon icon={value > 0.25 && value <= .75 ? faStarHalfAlt : faStar} size={size} color={value > 0.25 ? colors.yellow : colors.borderGrey} />
            <FontAwesomeIcon icon={value > 1.25 && value <= 1.75 ? faStarHalfAlt : faStar} size={size} color={value > 1.25 ? colors.yellow : colors.borderGrey} />
            <FontAwesomeIcon icon={value > 2.25 && value <= 2.75 ? faStarHalfAlt : faStar} size={size} color={value > 2.25 ? colors.yellow : colors.borderGrey} />
            <FontAwesomeIcon icon={value > 3.25 && value <= 3.75 ? faStarHalfAlt : faStar} size={size} color={value > 3.25 ? colors.yellow : colors.borderGrey} />
            <FontAwesomeIcon icon={value > 4.25 && value <= 4.75 ? faStarHalfAlt : faStar} size={size} color={value > 4.25 ? colors.yellow : colors.borderGrey} />
        </View>
    )

}

export default Rating