import React, { useState, useEffect, useContext } from 'react'
import { View, ScrollView, Text, Pressable, Platform, Dimensions } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCheckCircle, faExclamationCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import * as ImagePicker from 'expo-image-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import { useActionSheet } from '@expo/react-native-action-sheet'
import Axios from 'axios'

import { authAxios } from '../../scripts/Axios'
import { calculateSize, base64MimeType } from '../../scripts/Helpers'
import Loader from '../../components/Loader'
import AuthImage from '../../components/AuthImage'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function ProfileMediaBKScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, mainStyles, colors } = useContext(AppContext)
    const { showActionSheetWithOptions } = useActionSheet()

    const [loading, setLoading] = useState(false)

    const [personImages, setPersonImages] = useState([])    
    const [personUploaded, setPersonUploaded] = useState(false)
    
    const imageRowCount = 3
    const imagePreviewSize = Platform.OS === 'web' ? 120 : (Dimensions.get('window').width / imageRowCount) - 20
    const imageQuality = .4
    const imageCompression = .7
    const maxPersonImages = 3
    
    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {

        setLoading(true)

        checkPermissions()

        authAxios.get('uploads', {cancelToken: cancelSource.token})
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setPersonImages(response.data.uploads)
            }

        })
        .catch(error => console.log(error.response))

        return () => cancelSource.cancel('get uploads canceled')

    }, [])

    useEffect(() => {

        if(personUploaded){
            setLoading(false)
            navigation.navigate('Referenzen')
        }

    }, [personUploaded])


    async function checkPermissions(){
        if (Platform.OS !== 'web') {
            let libraryPermission = await ImagePicker.requestMediaLibraryPermissionsAsync()
            if (libraryPermission.status !== 'granted') {
                showAlert(t('alertNoMediaPermissionTitle'), t('alertNoMediaPermission'))
            }
            let cameraPermission = await ImagePicker.requestCameraPermissionsAsync()
            if (cameraPermission.status !== 'granted') {
                showAlert(t('alertNoCameraPermissionTitle'), t('alertNoCameraPermission'))
            }
        }
    }


    function chooseFile(type){
        if(Platform.OS === 'web'){
            selectAction(1, type)
        }else{
            showActionSheetWithOptions(
                {
                    options: [t('profileMediaMakeImageButton'), t('profileMediaChooseImageButton'), t('cancel')],
                    cancelButtonIndex: 2,
                    destructiveButtonIndex: null
                },
                (buttonIndex) => {
                    selectAction(buttonIndex, type)
                }
            )
        }
    }


    async function saveInputs(){

        if(Platform.OS !== 'web' && personImages.length < 1){
            showAlert(t('alertMissingMediaBKTitle'), t('alertMissingMediaBK'))
        }else{

            setLoading(true)
            setPersonUploaded(false)

            // Person Fotos
            let data1 = await makeFormData('person', personImages)
            authAxios.post('upload', data1, {
                onUploadProgress: progress => {
                    //let percentCompleted = Math.round((progress.loaded * 100) / progress.total)
                    //console.log('personImages (BK) upload:', percentCompleted, '%')
                }
            })
            .then(response => {

                if(response.data.type === 'error'){
                    setLoading(false)
                    showAlert(t('error'), response.data.message)
                }else{
                    setPersonUploaded(true)
                }

            })
            .catch(error => {
                setLoading(false)
                console.log(error)
            })
        
        }

    }

    
    function skipInputs(){
        navigation.navigate('Referenzen')
    }



    async function makeFormData(cat, arr){

        let data = new FormData()
        data.append('kategorie', cat)
        for(let [index, value] of arr.entries()){
            let file = await asyncAppend(value)
            if(file){
                data.append('files[]', file)
            }
        }
        return data

    }

    async function asyncAppend(file){

        let newFile = null

        if(!file.id){
            if(Platform.OS === 'web'){
                let res = await fetch(file.uri)
                newFile = res.blob()
            }else{
                let uriParts = file.uri.split('/');
                let fileName = uriParts[uriParts.length - 1];
                let fileNameParts = fileName.split('.');
                let fileExt = fileNameParts[fileNameParts.length - 1];
                let fileType = file.type + '/' + fileExt
                newFile = {
                    uri: file.uri,
                    name: fileName,
                    type: fileType
                }
            }
        }

        return newFile

    }

    function handleDeleteMedia(id, index){
        showAlert(
            t('alertDeleteFileTitle'),
            t('alertDeleteFile'),
            [
                { text: t('cancel'), style: 'cancel' },
                { text: t('delete'), onPress: () => deleteMedia(id, index), style: 'destructive' },
            ],
        )
    }

    async function deleteMedia(id, index){

        setPersonImages(personImages.filter((m, i) => i !== index))
        if(id){ authAxios.delete('upload/' + id) }

    }

    
    async function selectAction(index, category){

        if(category === 'person' && index === 0){

            let result = await ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsMultipleSelection: false,
                quality: imageQuality,
            })
            if (!result.cancelled) {
                let targetSize = calculateSize(1024, result.width, result.height)                
                let resizedImage = await ImageManipulator.manipulateAsync(
                    result.uri,
                    [{ resize: targetSize }],
                    {
                        compress: imageCompression,
                        format: ImageManipulator.SaveFormat.JPEG
                    }
                )
                if(resizedImage){
                    result.uri = resizedImage.uri
                    result.width = resizedImage.width
                    result.height = resizedImage.height
                    if(Platform.OS === 'web'){
                        result.base64 = true
                        result.type = base64MimeType(result.base64)
                    }
                    setPersonImages([...personImages, result])
                }
            }

        }
        else if(category === 'person' && index === 1){

            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsMultipleSelection: false,
                quality: imageQuality,
            })
            if (!result.cancelled) {
                let targetSize = calculateSize(1024, result.width, result.height)                
                let resizedImage = await ImageManipulator.manipulateAsync(
                    result.uri,
                    [{ resize: targetSize }],
                    {
                        compress: imageCompression,
                        format: ImageManipulator.SaveFormat.JPEG
                    }
                )
                if(resizedImage){
                    result.uri = resizedImage.uri
                    result.width = resizedImage.width
                    result.height = resizedImage.height
                    if(Platform.OS === 'web'){
                        result.base64 = true
                        result.type = base64MimeType(result.base64)
                    }
                    setPersonImages([...personImages, result])
                }
            }

        }

    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.container}>
                <View style={{marginBottom:30}}>
                    <Text style={mainStyles.title}>{t('profileMediaTitle')}</Text>
                    <Text style={mainStyles.titleText}>{t('profileMediaText')}</Text>
                </View>
                <Text style={[mainStyles.label, {marginTop:10}]}>{t('profileMediaBKCategory')}</Text>
                <Text style={{color: colors.darkGrey, fontSize:12, marginBottom:10}}>{t('maxFilesize')} 5 MB</Text>
            </View>

            <View style={mainStyles.mediaGrid}>
                <View style={mainStyles.uploadInfo}>
                    {personImages.length >= 1 ? <FontAwesomeIcon icon={faCheckCircle} size={16} color={colors.green} style={{marginRight:5}} /> : <FontAwesomeIcon icon={faExclamationCircle} size={16} color={colors.yellow} style={{marginRight:5}} /> }
                    <Text style={mainStyles.lightText}>{t('profileMediaImageInfo', {count: personImages.length + '/' + maxPersonImages})}</Text>
                </View>
                <View style={mainStyles.mediaGridInner}>                        
                    {personImages.map((m, index) => (
                        <Pressable key={index} onPress={() => handleDeleteMedia(m.id, index)}>
                            <AuthImage image={m} width={imagePreviewSize} height={imagePreviewSize} styles={mainStyles.mediaGridImage} />
                        </Pressable>
                    ))}
                    {personImages.length < maxPersonImages &&
                    <Pressable key={99} onPress={() => chooseFile('person')} style={[mainStyles.addMediaButton, {width: imagePreviewSize, height: imagePreviewSize}]}>
                        <FontAwesomeIcon icon={faPlusCircle} size={36} color={colors.green} />
                    </Pressable>
                    }
                </View>
            </View>

            <View style={mainStyles.screenContainer}>

                <View style={mainStyles.buttonContainer}>
                    <View style={mainStyles.row}>
                        <Pressable onPress={() => saveInputs()} style={[mainStyles.secondaryButton, { flex: 1, marginRight: 15 }]}>
                            <Text style={mainStyles.secondaryButtonText}>{t('profileMediaButton')}</Text>
                        </Pressable>
                        <Pressable onPress={() => skipInputs()} style={[mainStyles.outlineButton, { width: 'auto', minHeight: 56, paddingVertical: 15 }]}>
                            <Text style={mainStyles.outlineButtonText}>{t('later')}</Text>
                        </Pressable>
                    </View>
                </View>
                
            </View>

        </ScrollView>
    )

}

export default ProfileMediaBKScreen