import React, { useState, useEffect, useContext } from 'react'
import { View, ScrollView, Text, Pressable, Platform } from 'react-native'
import * as DocumentPicker from 'expo-document-picker'
import * as ImagePicker from 'expo-image-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import { useActionSheet } from '@expo/react-native-action-sheet'
import Axios from 'axios'

import { authAxios } from '../../scripts/Axios'
import { calculateSize } from '../../scripts/Helpers'
import DocumentList from '../../components/DocumentList'
import Loader from '../../components/Loader'
import { AppContext } from '../../scripts/AppContext'
import { UserContext } from '../../scripts/UserContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function ProfileReferencesScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, mainStyles, colors } = useContext(AppContext)
    const { token, setUserData } = useContext(UserContext)
    const { showActionSheetWithOptions } = useActionSheet()

    const [loading, setLoading] = useState(false)

    const [documents, setDocuments] = useState([])    
    const [documentsUploaded, setDocumentsUploaded] = useState(false)
    
    const imageQuality = .4
    const imageCompression = .7

    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {
        
        setLoading(true)

        authAxios.get('documents', {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setDocuments(response.data.documents)
            }

        })
        .catch(error => {
            console.log(error.response)
        })
        .then(() => {
            setLoading(false)
        })

        return () => cancelSource.cancel('get references canceled')

    }, [])


    useEffect(() => {

        if(documentsUploaded){
            setLoading(false)
            finish()
        }

    }, [documentsUploaded])


    function chooseFile(){
        if(Platform.OS === 'web'){
            selectAction(1)
        }else{
            showActionSheetWithOptions(
                {
                    options: [t('profileMediaMakeImageButton'), t('profilReferencesButton') + ' ...', t('cancel')],
                    cancelButtonIndex: 2,
                    destructiveButtonIndex: null
                },
                (buttonIndex) => {
                    selectAction(buttonIndex)
                }
            )
        }
    }


    async function saveInputs(){

        setLoading(true)
        setDocumentsUploaded(false)

        let data = await makeFormData('referenz', documents)
        authAxios.post('document', data)
        .then(response => {

            if(response.data.type === 'error'){
                setLoading(false)
                showAlert(t('error'), response.data.message)
            }else{
                setDocumentsUploaded(true)
            }

        })
        .catch(error => {
            setLoading(false)
            console.log(error)
        })

    }

    
    async function makeFormData(cat, arr){

        let data = new FormData()
        data.append('kategorie', cat)
        for(let [index, value] of arr.entries()){
            let file = await asyncAppend(value)
            if(file){
                data.append('files[]', file)
            }
        }
        return data

    }

    async function asyncAppend(file){

        let newFile = null

        if(!file.id){
            if(Platform.OS === 'web'){
                let res = await fetch(file.uri)
                newFile = res.blob()
            }else{
                let uriParts = file.uri.split('/');
                let fileName = uriParts[uriParts.length - 1];
                let fileNameParts = fileName.split('.');
                let fileExt = fileNameParts[fileNameParts.length - 1];
                let fileType = file.type + '/' + fileExt
                newFile = {
                    uri: file.uri,
                    name: fileName,
                    type: fileType
                }
            }
        }

        return newFile

    }


    function handleDeleteDocument(id, index){
        showAlert(
            t('alertDeleteFileTitle'),
            t('alertDeleteFile'),
            [
                { text: t('cancel'), style: 'cancel' },
                { text: t('delete'), onPress: () => deleteDocument(id, index), style: 'destructive' },
            ],
        )
    }

    function deleteDocument(id, index){

        setDocuments(documents.filter((d, i) => i !== index))
        if(id){ authAxios.delete('document/' + id) }

    }

    
    async function selectAction(index){

        if(index === 0){

            let result = await ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsMultipleSelection: false,
                quality: imageQuality,
            })
            if (!result.cancelled) {
                let targetSize = calculateSize(1024, result.width, result.height)                
                let resizedImage = await ImageManipulator.manipulateAsync(
                    result.uri,
                    [{ resize: targetSize }],
                    {
                        compress: imageCompression,
                        format: ImageManipulator.SaveFormat.JPEG
                    }
                )
                if(resizedImage){
                    result.uri = resizedImage.uri
                    result.width = resizedImage.width
                    result.height = resizedImage.height
                    if(Platform.OS === 'web'){
                        result.base64 = true
                        result.type = base64MimeType(result.base64)
                    }
                    setDocuments([...documents, result])
                }
            }

        }
        else if(index === 1){

            let result = await DocumentPicker.getDocumentAsync()
            if (result.type !== 'cancel') {
                setDocuments([...documents, result])
            }

        }

    }


    function finish(){
        
        setLoading(true)

        authAxios.post('profile-finish')
        .then(response => {
            
            if(response.data.type === 'success'){                
                setUserData(response.data.user, token)
            }
        })
        .catch(error => {
            console.log(error)
        })

    }


    return (
    <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

        <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

        <View style={mainStyles.screenContainer}>
            <Text style={mainStyles.title}>{t('profilReferencesTitle')}</Text>
            <Text style={mainStyles.titleText}>{t('profilReferencesText')}</Text>
        </View>

        <View style={{marginVertical:30}}>
            <DocumentList data={documents} onDelete={handleDeleteDocument} />
        </View>

        <View style={mainStyles.screenContainer}>

            <Pressable onPress={() => chooseFile()} style={mainStyles.secondaryButton}>
                <Text style={mainStyles.secondaryButtonText}>{t('profileMediaButton')}</Text>
            </Pressable>

            <View style={mainStyles.buttonContainer}>
                <Pressable onPress={() => saveInputs()} style={mainStyles.primaryButton}>
                    <Text style={mainStyles.primaryButtonText}>{t('profileFinishButton')}</Text>
                </Pressable>
            </View>

        </View>

    </ScrollView>
    )

}

export default ProfileReferencesScreen