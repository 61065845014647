import React, { useContext, useEffect, useState } from 'react'
import { View, ScrollView, Text, Pressable } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faSignOutAlt, faSun, faMoon } from '@fortawesome/free-solid-svg-icons'

import { UserContext } from '../../scripts/UserContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { AppContext } from '../../scripts/AppContext'
import { ChooseIcons, ChooseIcon } from '../../components/ChooseIcons'
import FooterMenu from '../../components/FooterMenu'

function AccountScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { logout } = useContext(UserContext)
    const { theme, changeTheme, mainStyles, colors, showAlert } = useContext(AppContext)

    const [settings, setSettings] = useState({
        theme: theme,
    })

    useEffect(() => {
        changeTheme(settings.theme)
    }, [settings.theme])

    function handleLogout(){
        showAlert(
            t('logout'),
            'Möchten Sie sich wirklich ausloggen?', // übersetzen
            [
                { text: t('cancel'), style: 'cancel' },
                { text: t('logout'), onPress: () => logout() },
            ],            
        )
    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={mainStyles.screenContainer}>

                <Text style={mainStyles.title}>{t('settingsScreen')}</Text>
                
                <Text style={[mainStyles.lightText, {marginTop: 20, marginBottom: 20}]}>{t('chooseTheme')}:</Text>

                <ChooseIcons name="theme" state={settings} setState={setSettings}>
                    <ChooseIcon label={t('themeLight')} value="light" icon={faSun} iconSize={50} />
                    <ChooseIcon label={t('themeDark')} value="dark" icon={faMoon} iconSize={50} />
                </ChooseIcons>

                <Pressable onPress={handleLogout} style={[mainStyles.registerButton, {flexDirection:'row', alignItems:'center', marginTop: 'auto'}]}>
                    <FontAwesomeIcon icon={faSignOutAlt} size={22} color={colors.white} style={{marginRight:10}} />
                    <Text style={mainStyles.registerButtonText}>{t('logout')}</Text>
                </Pressable>

                <Text style={[mainStyles.lightText, {marginTop: 50}]}>{t('settingsDeleteAccountInfo')}</Text>
                <Pressable onPress={() => navigation.navigate('Konto löschen')}><Text style={[mainStyles.lightText, {marginBottom: 40}]}>{t('settingsDeleteAccountAction')}</Text></Pressable>

                <FooterMenu navigation={navigation} />

            </View>

        </ScrollView>
    )

}

export default AccountScreen