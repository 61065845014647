import React, { useEffect, useState, useContext } from 'react'
import { Platform, TextInput, View } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import Selector from './Selector'
import { AppContext } from '../scripts/AppContext'

function MobileInput({ name, state, setState, icon, placeholder = ''}) {

    const { colors, mainStyles } = useContext(AppContext)

    const [isFocused, setIsFocused] = useState(false)

    const styles = [mainStyles.input, Platform.OS === 'web' ? {
        outlineWidth: 0,
        borderColor: isFocused ? colors.primary : colors.borderGrey
    } : {
        borderColor: isFocused ? colors.primary : colors.borderGrey
    }]
    const iconStyles = [mainStyles.inputIcon, {color: isFocused ? colors.primary : colors.mediumGrey}]

    const [mobile, setMobile] = useState({
        pre: '+49',
        number: '',
    })

    useEffect(() => {
        setState({...state, [name]: mobile.pre + mobile.number})
    }, [mobile])

    useEffect(() => {
        let firstChar = mobile.number[0]
        if(firstChar === '+' || firstChar === '0'){
            setMobile({...mobile, number: mobile.number.slice(1)})
        }
    }, [mobile.number])

    return (
        <View style={{position:'relative'}}>
            { icon && <FontAwesomeIcon icon={icon} size={20} style={iconStyles} /> }  
            <View style={{flexDirection:'row', marginLeft:30}}>
                <View style={{flexBasis:100, flexGrow:0, flexShrink:0}}>
                    <Selector name="pre" options={[
                        {label: '+49', value: '+49'},
                        {label: '+48', value: '+48'},
                        {label: '+43', value: '+43'},
                        {label: '+41', value: '+41'},
                        {label: '+36', value: '+36'},
                        {label: '+421', value: '+421'},
                        {label: '+387', value: '+387'},
                        {label: '+385', value: '+385'},
                        {label: '+380', value: '+380'},
                        {label: '+359', value: '+359'},
                    ]} state={mobile} setState={setMobile} />
                </View>
                <View style={{flex: 1}}>
                    <TextInput
                    keyboardType={'phone-pad'}
                    value={mobile.number}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChangeText={val => setMobile({...mobile, number: val})}
                    placeholder={placeholder}
                    placeholderTextColor={colors.mediumGrey}
                    style={styles} />
                </View>
            </View>
        </View>
    )

}

export default MobileInput