import React, { useContext, useState } from 'react'
import { View, ScrollView, Text, Pressable } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios'

import { UserContext } from '../../scripts/UserContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { AppContext } from '../../scripts/AppContext'
import { authAxios } from '../../scripts/Axios'

import Loader from '../../components/Loader'

function AccountDeleteScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { logout } = useContext(UserContext)
    const { mainStyles, colors, showAlert } = useContext(AppContext)
    
    const cancelSource = Axios.CancelToken.source()

    const [loading, setLoading] = useState(false)
    const [accountDeleted, setAccountDeleted] = useState(false)

    function handleDelete(){
        showAlert(
            t('alertDeleteAccountTitle'),
            t('alertDeleteAccount'),
            [
                { text: t('cancel'), style: 'cancel' },
                { text: t('delete'), onPress: () => deleteAccount() },
            ],            
        )
    }

    function deleteAccount(){
        setLoading(true)
        
        authAxios.post('delete-account', {cancelToken: cancelSource.token})
        .then(response => {

            setLoading(false)
            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                showAlert(t('success'), response.data.message)
                setAccountDeleted(true)
            }

        })
        .catch(error => {
            setLoading(false)
        })
        .then(() => {
            if(accountDeleted) logout()
        })

    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer}>

                <Text style={mainStyles.title}>{t('settingsDeleteAccount')}</Text>

                <View style={{ alignItems: 'flex-start' }}>
                    <Text style={mainStyles.label}>{t('settingsDeleteAccountText1')}</Text>
                    <Text style={mainStyles.lightText}>{t('settingsDeleteAccountText2')}</Text>
                    <Text style={mainStyles.li}>• {t('settingsDeleteAccountText3')}</Text>
                    <Text style={mainStyles.li}>• {t('settingsDeleteAccountText4')}</Text>
                    <Text style={mainStyles.li}>• {t('settingsDeleteAccountText5')}</Text>
                </View>

                <Pressable onPress={handleDelete} style={[mainStyles.registerButton, {flexDirection:'row', alignItems:'center', marginTop: 40}]}>
                    <FontAwesomeIcon icon={faTimes} size={22} color={colors.white} style={{marginRight:10}} />
                    <Text style={mainStyles.registerButtonText}>{t('settingsDeleteAccount')}</Text>
                </Pressable>

            </View>

        </ScrollView>
    )

}

export default AccountDeleteScreen