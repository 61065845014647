import React, { useState, useEffect, useContext } from 'react'
import { View, Platform, TextInput, Pressable } from 'react-native'
import { Picker } from '@react-native-picker/picker'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'

function SortSelect({name, options, state, setState, label = 'Sortierung', callback = null}) {

    const { colors, mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)

    const { showActionSheetWithOptions } = useActionSheet()
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        if(options){
            setSelected(options.find(o => o.value === state[name]))
        }
    }, [])

    useEffect(() => {
        setSelected(options.find(o => o.value === state[name]))
    }, [state])

    function showActionSheet(){
        showActionSheetWithOptions(
            {
                options: [...options.map(o => o.label), t('cancel')],
                cancelButtonIndex: options.length,
                destructiveButtonIndex: null
            },
            (buttonIndex) => {
                if(buttonIndex !== options.length){
                    select(options[buttonIndex].value)
                }
            }
        )
    }

    function select(value){
        if(callback != null) callback()
        setSelected(options.find(o => o.value === value))
        setState({...state, [name]: value})
    }

    return Platform.OS === 'ios' ? (
        <View style={mainStyles.sortSelectContainer}>
            <Pressable onPress={showActionSheet}>
                <TextInput pointerEvents="none" editable={false} value={selected ? selected.label : ''} placeholder={label} placeholderTextColor={colors.mediumGrey} style={[mainStyles.sortSelect, {height:40, paddingHorizontal: 10}]} />
                <FontAwesomeIcon icon={faCaretDown} size={16} color={colors.mediumGrey} style={{position:'absolute', right:10, top:12}} />
            </Pressable>
        </View>
    ) : (
        <View style={mainStyles.sortSelectContainer}>
            <Picker selectedValue={selected ? selected.value : ''} onValueChange={value => select(value)} style={mainStyles.sortSelect}>
                { options && options.map((o, index) => <Picker.Item key={index} label={o.label} value={o.value} />) }                
            </Picker>
        </View>
    )

}

export default SortSelect