import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCheckCircle, faCircle } from '@fortawesome/free-regular-svg-icons'

import { UserContext } from '../scripts/UserContext'
import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'


function ProfileTable({ profil, fields, isMe = false }) {

    const { colors, mainStyles } = useContext(AppContext)
    const { t, locale } = useContext(LocalizationContext)
    const { user, profiles, profileOptions } = useContext(UserContext)

    const [translation, setTranslation] = useState(profil)
    
    const styles = StyleSheet.create({
        table: {
            marginBottom: 30,
        },
        row: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottomWidth: 1,
            borderColor: colors.borderGrey,
            paddingTop: 12,
            paddingBottom: 8,
        },
        rowBig: {
            flexDirection: 'column',
            borderBottomWidth: 1,
            borderColor: colors.borderGrey,
            paddingTop: 12,
            paddingBottom: 12,
        },
        title: {
            color: colors.primary,
            fontSize: 16,
            marginTop: 10,
            marginHorizontal: 8,
        },
        valueContainer: {
            flexDirection: 'row',
            minHeight: 20,
            lineHeight: 20,
            marginVertical: 1,
            alignItems: 'center',
        },
        label: {
            color: colors.darkGrey,
            fontSize: 13,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            marginBottom: 4,
        },
        value: {
            color: colors.darkGrey,
            paddingRight: 15,
        },
        icon: {
            marginRight: 8,
        },
        pflegegradText: {
            marginLeft: 10,
            fontSize: 28,
            fontWeight: 'bold',
        },
    })

    useEffect(() => {

        if(profil.translations){
            let t = JSON.parse(profil.translations.translation).find(t => t.id === profil.id)
            if(t){
                setTranslation(t)
            }
        }

    }, [])

    function getValue(name){

        if(!profiles[0]) return null

        let selected = !Array.isArray(profil[name]) ? [profil[name]] : profil[name]
        let mySelected
        
        if(profiles[0][name]){
            mySelected = !Array.isArray(profiles[0][name]) ? [profiles[0][name]] : profiles[0][name] // nur 1. Profil wird gecheckt
        }else{
            mySelected = null
        }

        let field = fields.find(f => f.name === name)
        if(!field){ return }

        let options = profileOptions.filter(opt => selected.includes(opt.id))
        if(!options){ return '' }
        if(!Array.isArray(options)){
            options = [options]
        }

        let returnOptions = options.map((opt, index) => {
            
            let isChecked = false

            if(mySelected){

                if(field.type === 'equalOrBigger'){
                    if(user.user_role === 1 && opt.id <= mySelected){
                        isChecked = true
                    }
                    else if(user.user_role === 2 && opt.id >= mySelected){
                        isChecked = true
                    }
                }
                else if(field.type === 'equalOrSmaller'){
                    if(user.user_role === 1 && opt.id >= mySelected){
                        isChecked = true
                    }
                    else if(user.user_role === 2 && opt.id <= mySelected){
                        isChecked = true
                    }
                }
                else if(field.type === 'includes' || field.type === 'includesBK'){
                    if(mySelected.includes(opt.id)){
                        isChecked = true
                    }
                }
                else if(field.type === 'noOrSame'){
                    if(mySelected === field.no || mySelected.includes(opt.id)){
                        isChecked = true
                    }
                }
                else if(field.type === 'pets'){
                    if(mySelected.includes(opt.id)){
                        isChecked = true
                    }
                }
                else if(field.type === 'sex'){
                    let profileSex = profil.anrede === 'Herr' ? field.m : field.w
                    if(mySelected.includes(profileSex) && profileSex === opt.id){
                        isChecked = true
                    }
                }

            }
           
            return (
                <View key={index} style={styles.valueContainer}>
                    { isChecked ? <FontAwesomeIcon icon={faCheckCircle} color={colors.green} size={18} style={styles.icon} /> : <FontAwesomeIcon icon={faCircle} color={colors.lightGrey} size={18} style={styles.icon} /> }
                    <Text style={[styles.value, {color: isChecked ? colors.darkGrey : colors.mediumGrey}]}>{t(opt.value.split('.').join(''), { defaultValue: opt.value })}</Text>
                </View>
            )
        })

        return returnOptions;

    }

    function getSexValue(){
        if(!profiles[0]) return null

        let sexes = [
            { name: 'Frau', option_id: 117 },
            { name: 'Herr', option_id: 118 },
        ]

        let sex = sexes.find(s => s.name === profil.anrede)
        let mySelected = !Array.isArray(profiles[0].bk_anrede) ? [profiles[0].bk_anrede] : profiles[0].bk_anrede

        let isChecked = false
        if( (sex.option_id === 117 && mySelected.includes(117)) || (sex.option_id === 118 && mySelected.includes(118)) ){
            isChecked = true
        }

        let option = profileOptions.find(opt => opt.id === sex.option_id)
        if(!option) return null

        return (
            <View style={styles.valueContainer}>
                { isChecked ? <FontAwesomeIcon icon={faCheckCircle} color={colors.green} size={18} style={styles.icon} /> : <FontAwesomeIcon icon={faCircle} color={colors.lightGrey} size={18} style={styles.icon} /> }
                <Text style={[styles.value, {color: isChecked ? colors.darkGrey : colors.mediumGrey}]}>{t(option.value.split('.').join(''), { defaultValue: option.value })}</Text>
            </View>
        )
    }

    function getMySexValue(){
        if(!profiles[0]) return null

        let sexes = [
            { name: 'Frau', option_id: 117 },
            { name: 'Herr', option_id: 118 },
        ]

        let mySex = sexes.find(s => s.name === profiles[0].anrede)
        let targetSelected = !Array.isArray(profil.bk_anrede) ? [profil.bk_anrede] : profil.bk_anrede

        let options = profileOptions.filter(opt => opt.id === 117 || opt.id === 118)
        if(!options) return null

        return options.filter(opt => targetSelected.includes(opt.id)).map(opt => {
            let isChecked = false
            if(opt.id === mySex.option_id){
                isChecked = true
            }
            return (
            <View key={opt.id} style={styles.valueContainer}>
                { isChecked ? <FontAwesomeIcon icon={faCheckCircle} color={colors.green} size={18} style={styles.icon} /> : <FontAwesomeIcon icon={faCircle} color={colors.lightGrey} size={18} style={styles.icon} /> }
                <Text style={[styles.value, {color: isChecked ? colors.darkGrey : colors.mediumGrey}]}>{t(opt.value.split('.').join(''), { defaultValue: opt.value })}</Text>
            </View>
            )

        })
    }

    function getMyProfileSexValue(){
        if(!profiles[0]) return null

        let sexes = [
            { name: 'Frau', option_id: 117 },
            { name: 'Herr', option_id: 118 },
        ]

        let mySelected = !Array.isArray(profiles[0].bk_anrede) ? [profiles[0].bk_anrede] : profiles[0].bk_anrede

        let options = profileOptions.filter(opt => mySelected.includes(opt.id))
        if(!options) return null

        return options.map(opt => {
            let isChecked = false
            if(mySelected.includes(opt.id)){
                isChecked = true
            }
            return (
            <View key={opt.id} style={styles.valueContainer}>
                { isChecked ? <FontAwesomeIcon icon={faCheckCircle} color={colors.green} size={18} style={styles.icon} /> : <FontAwesomeIcon icon={faCircle} color={colors.lightGrey} size={18} style={styles.icon} /> }
                <Text style={[styles.value, {color: isChecked ? colors.darkGrey : colors.mediumGrey}]}>{t(opt.value.split('.').join(''), { defaultValue: opt.value })}</Text>
            </View>
            )

        })
    }

    return (
        <View style={{marginTop:30}}>

            { !isMe ? <Text style={mainStyles.profileTableText}>{user.user_role === 1 ? t('profileBeforeTableTextBK') : t('profileBeforeTableText')}</Text> : null }
            
            <Text style={mainStyles.title2}>{t('profileCategory1')}</Text>
            <View style={styles.table}>
                { user.user_role === 2 &&
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('careLevel')}</Text>
                    { getValue('pflegegrad') }
                </View>
                }
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileMovementLabel')}</Text>
                    { getValue('beweglichkeit') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileSicknessLabel')}</Text>
                    { getValue('einschraenkungen') }
                </View>
                { profil['sonstige_einschraenkungen'] &&
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileOtherSicknessLabel')}</Text>
                    <Text style={[styles.value, {color: colors.darkGrey}]}>{ profil['sonstige_einschraenkungen'] }</Text>
                </View>
                }
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileSenseLabel')}</Text>
                    { getValue('sinneswahrnehmung') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileMentalLabel')}</Text>
                    { getValue('geisteszustand') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileSleepLabel')}</Text>
                    { getValue('schlafverhalten') }
                </View>
            </View>

            <Text style={mainStyles.title2}>{t('profileCategory2')}</Text>
            <View style={styles.table}>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileEatingLabel')}</Text>
                    { getValue('essen') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileBaseCareLabel')}</Text>
                    { getValue('grundpflege') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileHygieneLabel')}</Text>
                    { getValue('hygiene') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profilePeeLabel')}</Text>
                    { getValue('urinkontrolle') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profilePooLabel')}</Text>
                    { getValue('stuhlkontrolle') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileClothingLabel')}</Text>
                    { getValue('ankleiden') }
                </View>
            </View>

            <Text style={mainStyles.title2}>{t('profileCategory3')}</Text>
            <View style={styles.table}>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileLivingSituationLabel')}</Text>
                    { getValue('wohnsituation') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileLivingConditionLabel')}</Text>
                    { getValue('wohnzustand') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileLivingPlaceLabel')}</Text>
                    { getValue('wohnort') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profilePetsLabel')}</Text>
                    { getValue('haustiere') }
                    { profil.haustiere === 86 && getValue('haustiere_welche') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileShoppingLabel')}</Text>
                    { getValue('einkaufsmoeglichkeiten') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileLivingBKLabel')}</Text>
                    { getValue('bk_wohnen') }
                </View>
            </View>

            <Text style={mainStyles.title2}>{t('profileCategory4')}</Text>
            <View style={styles.table}>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileCharacterLabel')}</Text>
                    { getValue('charaktereigenschaften') }
                </View>
                {/* <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileSmokingLabel')}</Text>
                    { getValue('raucher') }
                </View> */}
            </View>

            <Text style={mainStyles.title2}>{t('profileCategory5')}</Text>
            <View style={styles.table}>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileActivitiesBKLabel')}</Text>
                    { getValue('bk_aktivitaeten') }
                </View>
                { isMe ? (
                    <View style={styles.rowBig}>
                        <Text style={styles.label}>{t('profileSexBKLabel2')}</Text>
                        { getMyProfileSexValue() }
                    </View>
                ) : (
                    <View style={[styles.row, { paddingBottom: 12 }]}>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.label}>{t('profileSexBKLabel')}</Text>
                            { getSexValue() }
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.label}>{t('profileSexBKLabel2')}</Text>
                            { getMySexValue() }
                        </View>
                    </View>
                )}
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileGermanBKLabel')}</Text>
                    { getValue('bk_deutsch') }
                </View>
                <View style={styles.rowBig}>
                    <Text style={styles.label}>{t('profileDrivingBKLabel')}</Text>
                    { getValue('bk_fuehrerschein') }
                </View>
            </View>

            { (profil.hobbys || profil.umfeld || profil.sonstiges) &&
            <View>
                <Text style={mainStyles.title2}>{t('profileCategory6')}</Text>
                <View style={styles.table}>
                    { profil.hobbys &&
                    <View style={styles.rowBig}>
                        <Text style={styles.label}>{t('profileHobbiesLabel')}</Text>
                        <Text style={styles.value}>
                            { user.user_role === 1 ?
                                isMe ? (locale.startsWith('de') ? translation.hobbys : profil.hobbys) : (locale.startsWith('de') ? profil.hobbys : translation.hobbys) :
                                isMe ? (locale.startsWith('de') ? profil.hobbys : translation.hobbys) : (locale.startsWith('de') ? translation.hobbys : profil.hobbys)
                            }
                        </Text>
                    </View>
                    }
                    { profil.umfeld &&
                    <View style={styles.rowBig}>
                        <Text style={styles.label}>{t('profileEnvironmentLabel')}</Text>
                        <Text style={styles.value}>
                            { user.user_role === 1 ?
                                isMe ? (locale.startsWith('de') ? translation.umfeld : profil.umfeld) : (locale.startsWith('de') ? profil.umfeld : translation.umfeld) :
                                isMe ? (locale.startsWith('de') ? profil.umfeld : translation.umfeld) : (locale.startsWith('de') ? translation.umfeld : profil.umfeld)
                            }
                        </Text>
                    </View>
                    }
                    { profil.sonstiges &&
                    <View style={styles.rowBig}>
                        <Text style={styles.label}>{t('profileOtherLabel')}</Text>
                        <Text style={styles.value}>
                            { user.user_role === 1 ?
                                isMe ? (locale.startsWith('de') ? translation.sonstiges : profil.sonstiges) : (locale.startsWith('de') ? profil.sonstiges : translation.sonstiges) :
                                isMe ? (locale.startsWith('de') ? profil.sonstiges : translation.sonstiges) : (locale.startsWith('de') ? translation.sonstiges : profil.sonstiges)
                            }
                        </Text>
                    </View>
                    }
                </View>
            </View>
            }

        </View>
    )

}

export default ProfileTable