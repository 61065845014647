import React, { useContext } from 'react'
import { StyleSheet, View, Text, ActivityIndicator } from 'react-native'
import { LocalizationContext } from '../scripts/LocalizationContext'

function Loader({ visible, color = '#30434D', background = 'rgba(255,255,255,.8)', progress = null }) {

    const { t } = useContext(LocalizationContext)
    
    const styles = StyleSheet.create({
        container: {
            position: 'absolute',
            zIndex: 999,
            width: '100%',
            height: '100%',
            backgroundColor: background,
            alignItems: 'center',
            justifyContent: 'center',
        },
        text: {
            fontSize: 20,
            textAlign: 'center',
            marginTop: 10,
        }
    })

    return visible ? (
        <View style={styles.container}>
            <ActivityIndicator size="large" color={color} />
            { progress !== null ? <Text style={styles.text}>{ t('loadingText') + ' ' + progress + '%' }</Text> : null }
        </View>
    ) : null

}

export default Loader
