export const pl = {
    // General
    "appversion": "Wersja aplikacji",
    "yes": "Tak",
    "no": "Nie",
    "cancel": "Przerwać",
    "save": "Zapisać",
    "delete": "Usunąć",
    "continue": "Dalej",
    "back": "Wróć",
    "accept": "Akceptować",
    "decline": "Odrzucić",
    "send": "Wysłać",
    "discard": "Anuluj",
    "discardContinue": "Kontynuuj",
    "error": "Błąd",
    "multiselect": "Możliwy wybór wielu opcji",
    "refreshInfo": "Aby zaktualizować przewiń w dół",
    "age": "Wiek",
    "day": "Dzień",
    "month": "Miesiąc",
    "year": "Rok",
    "BK": "Opiekun/-ka",
    "PB": "Podopieczn-y/a",
    "PB2": "Osoba do opieki",
    "PB3": "Osoba do opieki",
    "copyright": "2021 © Senihelp24",
    "agb": "Warunki usługi",
    "dataPrivacy": "Polityka prywatności",
    "loadingText": "Ładuje ...",
    "noFiles": "Brak plików",
    "createdAt": "Utworzono",
    "maxFilesize": "Max. rozmiar pliku:",
    "login": "Login",
    "logout": "Wyloguj",
    "logoutQuestion": "Czy na pewno chcesz się wylogować?",
    "startdate": "Data rozpoczęcia",
    "from": "Od",
    "until": "do",
    "later": "Później",
    "at": "o",
    "clock": "",
    "isNotAvailable": "Niedostępny",
    "isAvailable": "Dostępność",
    "isRequested": "Zapytanie",
    "isRequestedBK": "Wybrane",
    "statusLabel": "Wasz status",
    "statusNone": "Brak statusu",
    "statusBronze": "Status brązowy",
    "statusSilver": "Status srebrny",
    "statusGold": "Status złoty",
    "in": "w",
    "atDate": "od",
    "instantly": "od zaraz",
    "match": "Match",
    "person": "Osoba",
    "noMatch": "Brak zgody",
    "chooseTheme": "Wybierz motyw",
    "themeLight": "Jasny",
    "themeDark": "Ciemny",
    "careLevel": "Stopień pielęgnacyjny",
    "image": "Zdjęcie",
    // Countries
    "germany": "Niemcy",
    "poland": "Polska",
    // Fields
    "fieldEmail": "E-mail",
    "fieldPassword": "Hasło",
    "fieldPasswordRepeat": "Powtórz hasło",
    "fieldMobile": "Telefon komórkowy",
    "fieldContactTypeLabel": "Jestem ...",
    "fieldContactType1": "Opiekun prawny",
    "fieldContactType1": "Kontakt główny",
    "fieldContactType2": "Odbiorca rachunku",
    "fieldSalutationLabel": "Płeć",
    "fieldSalutation1": "Pan",
    "fieldSalutation2": "Pani",
    "fieldSalutation3": "Inny",
    "fieldTitle": "Tytuł",
    "fieldName": "Imię",
    "fieldSurname": "Nazwisko",
    "fieldBirth": "Data urodzenia",
    "fieldSize": "wzrost (cm)",
    "fieldWeight": "waga (kg)",
    "fieldNationality" : "Narodowość",
    "fieldPersonalStateLabel": "Stan cywilny",
    "fieldPersonalState1": "wolny",
    "fieldPersonalState2": "żonaty/zamężna",
    "fieldPersonalState3": "rozwiedziony/-a",
    "fieldPersonalState4": "wdowiec/ wdowa",
    "fieldStreet": "ulica",
    "fieldStreetNr": "numer",
    "fieldPLZ": "kod pocztowy",
    "fieldCity": "miasto/miejscowość",
    "fieldCountry": "Kraj",
    "fieldPhone": "Telefon",
    "fieldPerMail": "E-mailem",
    "fieldPerSMS": "Sms-em",
    // Alerts
    "alertInvalidLoginTitle": "Błąd",
    "alertInvalidLogin": "Dane logowania są niepoprawne.",
    "alertPasswordChangedTitle": "Udało się!",
    "alertPasswordChanged": "Twoje hasło zostało zapisane. Możesz się teraz zalogować.",
    "alertDiscardChangesTitle": "Anulować zmiany",
    "alertDiscardChanges": "Jesteś tylko kilka minut od celu! Jeśli teraz przerwiesz, następnym razem będziesz zaczynać od początku.",
    "alertMissingFieldsTitle": "Błąd",
    "alertMissingFields": "Proszę wypełnić pola. %{fields}",
    "alertMissingProfilesTitle": "Błąd",
    "alertMissingProfiles": "Proszę stworzyć min. 1 profil.",
    "alertDeleteProfileTitle": "Usuń profil",
    "alertDeleteProfile": "Czy chcesz rzeczywiście usunąć ten profil?",
    "alertNoMediaPermissionTitle": "Błąd",
    "alertNoMediaPermission": "Aplikacja wymaga zazwolenia dostępu do galerii/zdjęć. Proszę zmienić tę opcję w ustawieniach telefonu.",
    "alertNoCameraPermissionTitle": "Błąd",
    "alertNoCameraPermission": "Aplikacja wymaga zazwolenia dostępu do aparatu. Zmień tą opcję w ustawieniach telefonu.",
    "alertMissingMediaPBTitle": "Błąd",
    "alertMissingMediaPB": "Załącz min. 1 swoje zdjęcie.",
    "alertMissingMediaBKTitle": "Błąd",
    "alertMissingMediaBK": "Proszę załącz w każdej z kategorii min. 1 zdjęcie, film. W aplikacji (przez telefon) możesz bezpośdrenio zrobić zdjęcie/nakręcić film.",
    "alertDeleteContactTitle": "Usuń osobę do kontaktu",
    "alertDeleteContact": "Czy chcesz rzeczywiście usunąć osobę kontaktową?",
    "alertDeleteFileTitle": "Usuń dane",
    "alertDeleteFile": "Czy chcesz rzeczywiście usunąć ten plik?",
    "alertInvalidStartDateTitle": "Błąd",
    "alertInvalidStartDate": "Wprowadź prawidłową datę rozpoczęcia ",
    "alertSaveStartDateTitle": "Potwierdzasz?",
    "alertSaveStartDate": "Jest ten termin %{date} Twoją planopwaną datą rozpoczęcia?",
    "alertSendRequestTitle": "Wyślij zapytanie",
    "alertSendRequest": "Wysłanie zapytania jest wiążące. Możesz wysłać 3 zapytania jednocześnie. Czy napewno chcesz wysłać zapytanie?",
    "alertSendPokeTitle": "Anstupsen", // DE
    "alertSendPoke": "Czcesz napewno kontaktować tą opiekunkę? Dziennie możesz kontaktować maksymalnie 3 opiekunki.",
    "alertRequestSentTitle": "Zapytanie wysłane",
    "alertRequestSent": "Twoje zapytanie zostało wysłane. Od teraz ta osoba ma 1 dzień czasu na odpowiedź.",
    "alertPokeSentTitle": "Erfolg!", // DE
    "alertPokeSent": "Skontaktowałaś się z opiekunką. Zobaczymy czy wyśle Tobie zapytanie. Jeśli tak to otrzymasz niezwłocznie odpowiedź.",
    "alertDeclineRequestTitle": "Odrzuć",
    "alertDeclineRequest": "Czy chcesz rzeczywiście odrzucić zapytanie?",
    "alertAcceptRequestTitle": "Akceptuj zapytanie",
    "alertAcceptRequest": "Czy chcesz zaakceptować ofertę wiążąco? Skontaktujemy się niezwłocznie, by skoordynować dalsze kroki.",
    "alertRequestDeclinedTitle": "Zapytanie odrzucone",
    "alertRequestDeclined": "Odrzuciłeś ofertę.",
    "alertRequestAcceptedTitle": "Zapytanie zaakceptowane ",
    "alertRequestAccepted": "Zaakceptowałeś ofertę. Wkrótce skontaktuje się z Tobą pracownik, by omówić dalsze procedury.",
    "alertSendImageTitle": "Wysłać?",
    "alertSendImage": "Chcesz wysłać to zdjęcie do Senihelp24?",
    "alertRatingDoneTitle": "Gotowe",
    "alertRatingDone": "Twoja ocena została zapisana.",
    "alertDeleteAccountTitle": "Usuwanie konta",
    "alertDeleteAccount": "Czy na pewno chcesz usunąć swoje konto wraz ze wszystkimi danymi?",
    // Login Screen
    "loginForgotPassword": "Nie pamiętam hasła",
    "loginStayLoggedIn": "Pozostań zalogowany",
    "loginNoAccount": "Nie masz jeszcze konta?",
    "loginButton": "Zaloguj",
    "loginRegisterButton": "Zarejestruj się teraz",
    // Forgot Password Screen
    "forgotPasswordScreen": "Nie pamiętam hasła",
    "forgotPasswordTitle": "Zapomniałeś hasła?",
    "forgotPasswordText": "Nie ma problemu, tu możesz nadać nowe hasło. W tym celu otrzymasz kod weryfikacyjny. W jaki sposób mamy go wysłać?",
    "forgotPasswordButton": "Zresetuj hasło",
    // Forgot Password Verify Screen
    "forgotPasswordVerifyScreen": "Nie pamiętam hasła",
    "forgotPasswordVerifyTitle": "Weryfikacja",
    "forgotPasswordVerifyEmailText": "Wysłaliśmy kod SMS-em. Podaj otrzymany kod.",
    "forgotPasswordVerifySMSText": "Wysłaliśmy kod e-mailem. Podaj otrzymany kod.",
    "forgotPasswordVerifyCodeText": "Podaj kod:",
    "forgotPasswordVerifyButton": "Weryfikowanie",
    // Reset Password Screen
    "resetPasswordScreen": "Zresetuj hasło",
    "resetPasswordTitle": "Nadanie nowego hasła",
    "resetPasswordText": "Podaj nam nowe hasło.",
    "resetPasswordPlaceholder": "Nowe hasło",
    "resetPasswordPlaceholderRepeat": "Powtórz nowe hasło",
    "resetPasswordButton": "Zapamiętaj nowe hasło",
    // Register Screen
    "registerScreen": "Rejestracja",
    "registerTitle": "Zarejestruj się teraz",
    "registerText": "Zarejestruj się bezpłatnie w Senihelp24 i określ swoje indywidualne wymagania.",
    "registerCategoryLabel": "Jestem ...",
    "registerCategoryBK": "Opiekun/-ka",
    "registerCategoryPB": "Podopieczny/-a / Osoba do kontaktu",
    "registerAGBInfo": "Zapoznał-am/-em się z OWH (Ogólnymi Warunkami Handlowymi) i wyrażam zgodę.",
    "registerDataPrivacyInfo": "Zapoznałam/em się z Oświadczeniem o ochronie danych osobowych  i wyrażam zgodę.",
    "registerButton": "Zarejestruj się teraz",
    // Account Confirmation Screen
    "accountConfirmationScreen": "Weryfikacja konta",
    "accountConfirmationTitle": "Weryfikacja ",
    "accountConfirmationText": "Ochrona danych osobowych jest dla nas ważna!",
    "accountConfirmationText2": "Potwierdź rejestrację. W celu weryfikacji otrzymasz kod.",
    "accountConfirmationText3": "Jak chcesz go otrzymać?",
    "accountConfirmationButton": "Wyślij potwierdzenie ",
    // Account Confirmation Verify Screen
    "accountConfirmationVerifyScreen": "Weryfikacja konta ",
    "accountConfirmationVerifyTitle": "Weryfikacja ",
    "accountConfirmationVerifySMSText": "Otrzymałeś/-aś od nas kod sms-em. Wpisz kod tutaj.",
    "accountConfirmationVerifyEmailText": "Otrzymał-eś/-aś od nas kod e-mailem. Wpisz kod tutaj.",
    "accountConfirmationVerifyCodeText": "Podaj kod:",
    "accountConfirmationVerifyButton": "Zatwierdź",
    // Profile Intro Screen
    "profileIntroScreen": "Weryfikacja konta ",
    "profileIntroTitle": "Witaj w Senihelp24",
    "profileIntroText": "Możesz kontynuować wprowadzanie swoich danych",
    "profileIntroInfoBar": "Weryfikacja konta ",
    "profileIntroListBKTitle": "Podczas rejestracji poprosimy o podanie danych:",
    "profileIntroListBK1": "Podstawowe dane",
    "profileIntroListBK2": "Preferencje zawodowe",
    "profileIntroListBK3": "Aktualne zdjęcie",
    "profileIntroListBK4": "Referencje (opcjonalnie)",
    "profileIntroListPBTitle": "Będziemy kolejno zbierać dane na Twój temat i podopiecznego:",
    "profileIntroListPB1": "Podstawowe dane",
    "profileIntroListPB2": "Podstawowe dane o stanie zdrowia podopieczne-go/-j",
    "profileIntroListPB3": "Adres podopieczne-go/-j",
    "profileIntroListPB4": "Zdjęcia i filmy podopieczne-j/-ego",
    "profileIntroListPB5": "Osoba kontaktowa",
    "profileIntroListPB6": "Deklarowany termin rozpoczęcia zlecenia",
    "profileIntroButton": "Dalej",
    // Profile PersonalData Screen
    "profilePersonalDataPBScreen": "Podstawowe dane",
    "profilePersonalDataPBTitle": "Dane osobowe",
    "profilePersonalDataPBText": "Kim jesteś?",
    // Profile PersonalData BK Screen
    "profilePersonalDataBKScreen": "Podstawowe dane",
    "profilePersonalDataBKTitle": "Dane osobowe",
    "profilePersonalDataBKText": "Proszę podać swoje podstawowe dane:",
    // Profile PB List Screen
    "profilePBListScreen": "Profile",
    "profilePBListTitle": "Podopieczna/-y",
    "profilePBListText": "Stwórz profil podopiecznej/-go",
    "profilePBListText2": "",
    "profilePBListNoProfiles": "Nie stworzyłeś jeszcze profilu.",
    "profilePBListProfileButton": "Stworzyć profil",
    "profilePBListProfileButton2": "Stworzyć kolejny profil",

    // Profile Add PB-General Screen
    "profileAddPBScreen": "Załączyć profil",
    "profileAddPBTitle": "Ogólne dane",
    "profileAddPBText": "Podaj ogólne dane podopiecznej/-go",
    "profileAddPB1Title": "Opis stanu zdrowia podopiecznego",
    "profileAddPB1Text": "Podaj dane dotyczące zdrowia podopiecznej/-go",
    "profileAddPB2Title": "Opis stanu zdrowia podopiecznego 2",
    "profileAddPB2Text": "Jakimi jednostkami chorobowymi może się Pan/i zająć?",
    "profileAddPB3Title": "Opis stanu zdrowia podopiecznego 3",
    "profileAddPB3Text": "Proszę podać tutaj dane zdrowotne osoby wymagającej opieki.",
    "profileAddPB4Title": "Zakres opieki",
    "profileAddPB4Text": "Proszę podać tutaj dane dotyczące zakresu opieki",
    "profileAddPB5Title": "Sytuacja mieszkaniowa",
    "profileAddPB5Text": "Proszę podać informacje o warunkach zamieszkania podopiecznego",
    "profileAddPB6Title": "Osobowość",
    "profileAddPB6Text": "Proszę podać dokładne dane dotyczące charakteru osoby wymagającej opieki.",
    "profileAddPB7Title": "Inne wymagania",
    "profileAddPB7Text": "Oczekiwania dotyczące zakresu obowiązków opiekuna/-ki",
    "profileAddPB8Title": "Dodatkowe informacje",
    "profileAddPB8Text": "Proszę opisać możliwie jak najdokładniej, jak powinna wyglądać codzienna interakcja",
    // Profile Add BK-General Screen
    "profileAddBKScreen": "Uzupełnij profil",
    "profileAddBK1Title": "Preferencje zawodowe",
    "profileAddBK1Text": "Jakim seniorem może się Pan/i zająć?",
    "profileAddBK2Title": "Preferencje zawodowe",
    "profileAddBK2Text": "Jakimi problemami zdrowotnymi może sie Pan/i zająć?",
    "profileAddBK3Title": "Preferencje zawodowe",
    "profileAddBK3Text": "Jakimi problemami zdrowotnymi może sie Pan/i zająć?",
    "profileAddBK4Title": "Preferencje zawodowe",
    "profileAddBK4Text": "Jakich problemów zdrowotnych się Pan/i podejmie?",
    "profileAddBK5Title": "Sytuacja życiowa",
    "profileAddBK5Text": "Podaj informacje o warunkach w jakich chcesz pracować.",
    "profileAddBK6Title": "Osobowość",
    "profileAddBK6Text": "Jakie cechy charakteru podopiecznej/-go może Pan/i zaakceptować?",
    "profileAddBK7Title": "Dodatkowe informacje",
    "profileAddBK7Text": "Jakie wymagania jest Pan/-i w stanie spełnić?",
    "profileAddBK8Title": "Parę słów o mnie",
    "profileAddBK8Text": "Proszę napisać parę słów o sobie, swoim doświadczeniu zawodowym, swoich cechach charakteru i hobby.",

    // Profile Add-1 Screen
    "profileAddCareLevelLabel": "Podopieczna/-y ma",
    "profileAddMovementLabel": "Mobilność podopiecznego",
    // Profile Add-2 Screen
    "profileAddSicknessLabel": "Aktualny stan zdrowia podopiecznego:",
    "profileAddCureLabel": "Czy można spodziewać się poprawy obecnego stanu?",
    "profileAddOtherSicknessLabel": "Podaj inne ograniczenia zdrowotne",
    "profileAddOtherSickness": "Pozostałe ograniczenia ",
    // Profile Add-3 Screen
    "profileAddSenseLabel": "Percepcja zmysłowa podopiecznego",
    "profileAddMentalLabel": "Stan umysłu podopiecznej/-go",
    "profileAddSleepLabel": "Zaburzenia snu",
    // Profile Add-4 Screen
    "profileAddEatingLabel": "Zakres pomocy w czasie jedzenia",
    "profileAddBaseCareLabel": "Podstawowa opieka/ higiena ciała podopiecznego",
    "profileAddHygieneLabel": "Higiena po skorzystaniu z toalety",
    "profileAddPeeLabel": "Kontrola moczu",
    "profileAddPooLabel": "Kontrola kału",
    "profileAddClothingLabel": "Podczas ubierania podopieczny/-a potrzebuje ",
    // Profile Add-5 Screen
    "profileAddLivingSituationLabel": "Warunki mieszkaniowe osoby podopiecznej",
    "profileAddLivingConditionLabel": "Stan/wyposażenie domu podopiecznej/-go",
    "profileAddLivingPlaceLabel": "Miejsce zamieszkania podopiecznej/-go",
    "profileAddPetsLabel": "Zwierzęta domowe u podopiecznej/-go",
    "profileAddWhichPetsLabel": "Jakie zwierzęta?",
    "profileAddShoppingLabel": "Odległość do najbliższego sklepu",
    "profileAddLivingBKLabel": "Opiekun/-ka ma do dyspozycji",
    // Profile Add-6 Screen
    "profileAddCharacterLabel": "Cechy charakteru osoby, którą należy się opiekować",
    "profileAddSmokingLabel": "Podopieczny/-a jest palaczem",
    // Profile Add-7 Screen
    "profileAddActivitiesBKLabel": "Oprócz opieki i prowadzenia gospodarstwa domowego oczekuje się jeszcze od opiekuna/ -ki",
    "profileAddSexBKLabel": "Płeć opiekuna/-ki",
    "profileAddGermanBKLabel": "Poziom języka niemieckiego opiekuna/-ki powinien by przynajmniej",
    "profileAddSmokingBKLabel": "Opiekun-/-ka ma być osobą niepalącą",
    "profileAddDrivingBKLabel": "Opiekun/-ka ma posiada czynne prawo jazdy",
    // Profile Add-8 Screen
    "profileAddTextHobbiesLabel": "Czynności w ciągu dnia",
    "profileAddTextHobbies": "np. czytanie gazety, tv/radio, rozmowy telefoniczne z rodziną i przyjaciółmi",
    "profileAddTextEnvironmentLabel": "Środowisko socjalne ",
    "profileAddTextEnvironment": "np. 2 dzieci z rodziną, które regularnie odwiedzają, dobry kontakt z sąsiadami",
    "profileAddTextOtherLabel": "Pozostałe",
    "profileAddTextOther": "np. babcia przykłada dużą wagę do wyglądu zewnętrznego, ważne są dobre maniery, jest bardzo wrażliwa, trzeba zbudować sobie zaufanie ",

    // Profile Add-1 Screen BK
    "profileAddCareLevelLabelBK": "Zaopiekuję się osobą, która posiada maksymalnie",
    "profileAddMovementLabelBK": "Zaopiekuję się osobą z nastepującymi jednostkami chorobowymi",
    // Profile Add-2 Screen BK
    "profileAddSicknessLabelBK": "Opiekuję się osobami z następującymi ograniczeniami chorobowymi",
    // Profile Add-3 Screen BK
    "profileAddSenseLabelBK": "Opiekuję się osobami z następującą percepcją zmysłową",
    "profileAddMentalLabelBK": "Zadbam o osoby z następującym stanem umysłu",
    "profileAddSleepLabelBK": "Zaburzenia snu",
    // Profile Add-4 Screen BK
    "profileAddEatingLabelBK": "Zakres pomocy w czasie jedzenia",
    "profileAddBaseCareLabelBK": "Zajmę się osobą potrzebującą następującej pomocy podczas higieny ciała",
    "profileAddHygieneLabelBK": "Zajmę się osobą potrzebującą następującej pomocy podczas higieny toaletowej",
    "profileAddPeeLabelBK": "Podejmę się opieki osoby z problemem nietrzymania moczu",
    "profileAddPooLabelBK": "Podejmę się opieki osoby z problemem nietrzymania kału",
    "profileAddClothingLabelBK": "Podejmę się opieki osoby, która potrzebuje pomocy przy ubieraniu",
    // Profile Add-5 Screen BK
    "profileAddLivingSituationLabelBK": "Chciał/a/bym pracować w następujących warunkach mieszkaniowych",
    "profileAddLivingConditionLabelBK": "Chciał/a/bym pracować w gospodarstwie domowym o następującym standardzie.",
    "profileAddLivingPlaceLabelBK": "Chciał/a/bym pracować w",
    "profileAddPetsLabelBK": "Obecność zwierząt domowych",
    "profileAddWhichPetsLabelBK": "Zwierzęta domowe:",
    "profileAddShoppingLabelBK": "Możliwości zakupów (osiągalne na pieszo)",
    "profileAddLivingBKLabelBK": "Oczekuję następujących warunków zakwaterowania",
    // Profile Add-6 Screen BK
    "profileAddCharacterLabelBK": "Cechy charakteru",
    "profileAddSmokingLabelBK": "Mogę pracować z osobą palącą.",
    // Profile Add-7 Screen BK
    "profileAddActivitiesBKLabelBK": "Dodatkowo chętnie podejmę się:",
    "profileAddSexBKLabelBK": "Płeć podopiecznej/-ego",
    "profileAddGermanBKLabelBK": "Deklaruję następujący poziom znajomości języka niemieckiego",
    "profileAddSmokingBKLabelBK": "Czy pali Pan/-i papierosy?",
    "profileAddDrivingBKLabelBK": "Czy posiada Pan/-i czynne prawo jazdy?",
    // Profile Add-8 Screen BK    
    "profileAddTextBKLabel": "Opis",
    "profileAddTextBK": "Z zawodu jestem kucharką. Mam 7 lat doświadczenia w opiece nad osobami starszymi. Do tej pory opiekowałam się seniorami z demencją, osteoporozą i po udarze mózgu. Prywatnie lubię oglądać seriale i czytać lekką literaturę. W czasie wolnym spaceruję albo piekę ciasta. Jestem z natury radosną i bezkonfliktową osobą, która zajmuje się podopiecznymi z życzliwością i taktem.",




    // Profile Address Screen
    "profileAddressScreen": "Adres",
    "profileAddressTitle": "Dane adresowe",
    "profileAddressText": "Proszę podać nr telefonu i adres podopieczne-go/-j",
    // Profile Media PB Screen
    "profileMediaScreen": "Media",
    "profileMediaTitle": "Wyślij zdjęcia i wideo",
    "profileMediaText": "Jedno zdjęcie powie więcej niż 1000 słów! Nie martw się, twój wizerunek będzie dostępny tylko do wewnętrznych potrzeb i rekrutacji.",
    "profileMediaCategory1": "Zdjęcia podopiecznej/-go",
    "profileMediaCategory2": "Zdjęcia mieszkania/domu",
    "profileMediaCategory3": "Film / obejście po domu ",
    "profileMediaImageInfo": "Wybrano %{count} zdjęć",
    "profileMediaVideoInfo": "Wybrano %{count} filmów",
    "profileMediaActionLabel": "Wybierz",
    "profileMediaMakeImageButton": "Zrób nowe zdjęcie",
    "profileMediaChooseImageButton": "Wybierz zdjęcie ...",
    "profileMediaMakeVideoButton": "Zrób nowy film",
    "profileMediaChooseVideoButton": "Wybierz film ...",
    "profileMediaButton": "Dodaj multimedia",
    // Profile Media BK Screen
    "profileMediaBKTitle": "Załącz zdjęcia",
    "profileMediaBKText": "Jedno zdjęcie powie więcej niż 1000 słów! Nie martw się, Twój wizerunek będzie dostępny tylko do wewnętrznych potrzeb rekrutacji.",
    "profileMediaBKCategory": "Dodaj pliki",
    // Profile Contact List Screen
    "profilContactListScreen": "Osoby kontaktowe",
    "profileContactListTitle": "Osoby kontaktowe",
    "profileContactListText": "Stworzyłeś siebie jako osobę kontaktową i wprowadziłeś do systemu wszystkie informacje dotyczące osoby (osób), którymi należy się opiekować. Tutaj możesz dodać dalsze osoby kontaktowe.",
    "profileContactListButton": "Dodaj inne osoby kontaktowe",
    // Profile Add Contact Screen
    "profilAddContactScreen": "Wprowadź osoby kontaktowe",
    "profileAddContactTitle": "Osoba kontaktowa",
    "profileAddContactText": "Proszę podać nastepujące informacje.",
    // Profile References Screen
    "profilReferencesScreen": "Referencje",
    "profilReferencesTitle": "Twoje referencje",
    "profilReferencesText": "Załącz posiadane referencje i certyfikaty / dyplomy ukończonych kursów.",
    "profilReferencesButton": "Wybierz plik",
    // Profile Finish Screen
    "profileFinishScreen": "Zakończ rejestrację",
    "profileFinishTitle": "Gotowe!",
    "profileFinishText": "Proszę podać datę od kiedy jesteś gotowy/a zacząć pracę w Niemczech.",
    "profileFinishDateLabel": "Data rozpoczęcia",
    "profileFinishText2": "Nie wie Pani /an jeszcze dokładnie? To proszę podać pierwszego następnego miesiąca,",
    "profileFinishButton": "Zakończ zapytanie",
    // Account Approval Screen
    "accountApprovalScreen": "Udostępnienie konta",
    "accountApprovalTitle": "Weryfikacja konta",
    "accountApprovalText1": "Poczekaj aż Twoje konto zostanie zweryfikowane. Do tego czasu masz ograniczony dostęp do zawartości aplikacji.",
    "accountApprovalText2": "Wkrótce skontakuje się z Tobą pracownik Senihelp24 w celu omówienia dalszych kroków dotyczących współpracy.",
    "accountApprovalText3": "Dziękujemy, że zdecydowali się Państwo na wybór Senihelp24.",
    "accountApprovalText4": "Podgląd pierwszych 3 opiekunów",
    "accountApprovalText4BK": "Podgląd pierwszych 3 podopiecznych",





    // Cares Screen
    "caresScreen": "Opieka",
    "caresTabEvents": "Terminy",
    "caresTabSearch": "Szukaj",
    "caresTabRequests": "Zapytania",
    // Events
    "careEventsWelcome": "Witaj",
    "careEventsWelcomeTextBK1": "Podane informacje zostaną sprawdzone, następnie utworzony profil opiekunki/a.",
    "careEventsWelcomeTextBK2": "Możesz wysłać zapytanie max. do 3 osób.",
    "careEventsWelcomeTextBK3": "Jak tylko Twoja oferta zostanie przyjęta, skontaktujemy się z Tobą aby omówi szczegóły współpracy i podpisać umowę.",
    "careEventsWelcomeButtonBK": "Wyświetl profil podopieczej/go",
    "careEventsWelcomeTextPB1": "Twoje dane zostały wprowadzone i profil opiekunki jest teraz aktywowany.",
    "careEventsWelcomeTextPB2": "Co dalej?",
    "careEventsWelcomeTextPB3": "W zależności od potrzeb możesz tutaj wybrać swoje perły. Możesz wybrać do 3 osób jednocześnie.",
    "careEventsWelcomeTextPB4": "Jak tylko wyślesz zapytanie, możesz zdecydować czy zdecydujesz się na daną opiekunk-ę/-a.",
    "careEventsWelcomeButtonPB": "Wyświetla opiekunów ",
    "careEventsQuestionsText1": "Masz pytania?",
    "careEventsQuestionsText2": "Napisz do nas na czacie:",
    "careEventsQuestionsButton": "Czat",
    "careEventsCategory1": "Aktualny termin",
    "careEventsCategory2": "Nadchodzące terminy",
    "careEventsCategory3": "Zakończone terminy",
    "careEventsCategory4": "Anulowane terminy",
    "careEventsStatusOrganized": "Termin opieki ustalany ...",
    "careEventsStatusCanceled": "Termin opieki anulowany",
    "careEventsStatusConfirmed": "Termin opieki potwierdzony",
    "careEventsStatusOld": "Upłynął termin opieki",
    "careEventsNoEvents": "Brak dostępnych terminów",
    "careEventsAddress": "Adres",
    "careEventsRatingTitle": "Oceń swoją opiekunkę/-a",
    "careEventsRatingText": "Państwa opinia jest dla nas ważna. Dlatego chcemy prosić o opisanie Państwa doświadczenia z naszą opiekunką.",
    "careEventsRatingTitleBK": "Proszę ocenić swojego podopiecznego",
    "careEventsRatingTextBK": "Państwa opinia jest dla nas ważna. Dlatego prosimy o opisanie doświadczenia ze współpracy z podopiecznym.",
    // Search
    "careSearchSortLabel": "Sortowanie",
    "careSearchSort1": "Uporządkuj malejąco",
    "careSearchSort2": "Uporządkuj rosnąco",
    "careSearchSort3": "Ocena malejąca",
    "careSearchSort4": "Ocena rosnąca",
    "careSearchSort5": "Status w porządku malejącym ",
    "careSearchSort6": "Status w porządku rosnącym ",
    "careSearchSort7": "Miasto A-Z",
    "careSearchSort8": "Miasto Z-A",
    "careSearchSort9": "Najwcześniejszy termin rozpoczęcia",
    "careSearchSort10": "Najpóźniejszy termin rozpoczęcia",
    "careSearchSort11": "Najwcześniejsza dostępność",
    "careSearchSort12": "Najnowsza dostępność",
    // Requests
    "careRequestsNoRequests": "Brak zapytań",
    "careRequestsPokeCount": "Pozostałe bodźce",
    "careRequestsRequestCount": "Pozostałe wnioski",
    "careRequestsOld": "Stare zapytania",
    "requestStatusPending": "Czekaj na odpowiedź",
    "requestStatusDeclined": "Zapytanie odrzucone",
    "requestStatusAccepted": "Zapytanie przyjęte",
    "requestAvailableTime": "Pozostały czas",
    "requestTimerFormat": "h [godzina] m [minuty]",
    // Profile
    "profileScreen": "Profil",
    "profileRatingsText": "Pokazać oceny",
    "profileIsCaring": "W opiece",
    "profileRequestButton": "Wybrać",
    "profileRequestButtonDone": "Wybrane",
    "profileRequestButtonDisabled": "Brak pozstałych wybranych",
    "profileRequestButtonInfo": "Pozostałe wnioski",
    "profileRequestButtonBK": "Wyślij zapytanie",
    "profileRequestButtonDoneBK": "Zapytanie wysłane",
    "profileRequestButtonDisabledBK": "Brak zapytań",
    "profileRequestButtonInfoBK": "Pozostałe wnioski",
    "profileBeforeTableText": "Folgende Parameter stimmen mit Ihren Anforderungen überein:",
    "profileBeforeTableTextBK": "Poniżej możesz zobaczyć wymagania podopieczne-j/-go. Zgodności są zaznaczone zielonym odhaczeniem:",
    // Profile Table
    "profileCategory1": "Zdrowie",
    "profileCategory2": "Pomoc",
    "profileCategory3": "Mieszkanie",
    "profileCategory4": "Osoba",
    "profileCategory5": "Wymagane od opiekun-a/-ki",
    "profileCategory6": "Tekst dowolny",
    "profileMovementLabel" : "Mobilność",
    "profileSicknessLabel": "Choroby/ograniczenia zdrowotne",
    "profileOtherSicknessLabel": "Pozostałe ograniczenia",
    "profileSenseLabel": "Percepcja zmysłowa",
    "profileMentalLabel": "Stan umysłowy",
    "profileSleepLabel": "Zaburzenia snu",
    "profileEatingLabel": "Pomoc przy jedzeniu",
    "profileBaseCareLabel": "Podstawowa opieka/ higiena ciała",
    "profileHygieneLabel": "Higiena po korzystaniu z toalety",
    "profilePeeLabel": "Kontrola moczu",
    "profilePooLabel": "Kontrola kału",
    "profileClothingLabel": "Pomoc w ubieraniu",
    "profileLivingSituationLabel": "Sytuacja mieszkaniowa",
    "profileLivingConditionLabel": "Stan/wyposażenie domu osoby podopiecznej",
    "profileLivingPlaceLabel": "Miejsce zamieszkania",
    "profilePetsLabel": "Zwierzęta domowe",
    "profileShoppingLabel": "Odległość do najbliższego sklepu",
    "profileLivingBKLabel": "Dla opiekunki jest udostępniony",
    "profileCharacterLabel": "Cechy charakteru",
    "profileSmokingLabel": "Jestem osobą palącą",
    "profileActivitiesBKLabel": "Aktywności",
    "profileSexBKLabel": "Płeć",
    "profileSexBKLabel2": "Preferuje opiekuna płci",
    "profileGermanBKLabel": "Znajomość jęz. niemieckiego",
    "profileSmokingBKLabel": "Palacz",
    "profileDrivingBKLabel": "Prawo jazdy",
    "profileHobbiesLabel": "Czynności dzienne",
    "profileEnvironmentLabel": "Środowisko socjalne",
    "profileOtherLabel": "Pozostałe",
    // Profile Ratings Screen
    "profileRatingsScreen": "Oceny",
    // Messages Screen
    "messagesScreen": "Wiadomości",
    "messagesTabEmails": "E-mail",
    "messagesTabChat": "Czat",
    // Chat
    "messagesChatTitle": "W czym możemy pomóc?",
    "messagesChatText1": "Proszę wpisać treść wiadomości poniżej i kliknąć przycisk wyślij.",
    "messagesChatText2": "Możecie Państwo wysłać również zdjęcie, klikając na symbol spinacza.",
    "messagesChatInput": "Wpisać wiadomość ...",
    // E-Mails
    "messagesEmailBy": "od",
    "messagesEmailAttachments": "Załączniki:",
    // My Profile Screen
    "myProfileScreen": "Mój profil",
    "myProfileHello": "Dzień dobry",
    "myProfileMenuPB1": "Podopieczn-y/-a",
    "myProfileMenuPB2": "Dokumenty",
    "myProfileMenuPB3": "Oceny",
    "myProfileMenuPB4": "Osoby kontaktowe",
    "myProfileMenuPB5": "Ustawienia",
    "myProfileMenuBK1": "Mój profil",
    "myProfileMenuBK2": "Dokumenty",
    "myProfileMenuBK3": "Oceny",
    "myProfileMenuBK4": "Ustawienia",
    // My Profile Documents Screen
    "documentsScreen": "Dokumenty",
    "documentsCategory1": "Umowy",
    "documentsCategory2": "Rachunki",
    "documentsCategory3": "Moje referencje",
    "documentsCategory4": "Upomnienia",
    "documentsCategory5": "Wyliczenie wynagordzenia",
    "documentsCategory6": "Pozostałe",
    // My Profile Ratings Screen
    "ratingsScreen": "Oceny",
    "ratingsTabToRate": "Do oceny",
    "ratingsTabMyRatings": "Moje oceny",
    "ratingsNothingToRate": "Aktualnie nie do oceny",
    "ratingsNoRatings": "Brak dostępnych ocen",
    "ratingsCategoryBK1": "Prezentacja",
    "ratingsCategoryBK2": "Otwartość",
    "ratingsCategoryBK3": "Współpraca",
    "ratingsCategoryBK4": "Kompetencje",
    "ratingsCategoryBK5": "Gospodarstwo domowe",
    "ratingsCategoryBK6": "Znajomość języka niemieckiego",
    "ratingsCategoryPB1": "Jakość mieszkaniowa",
    "ratingsCategoryPB2": "Charakter",
    "ratingsCategoryPB3": "Wysiłek podstawowej pielęgnacji",
    "ratingsCategoryPB4": "Środowisko socjalne",
    "ratingsCategoryPB5": "Regulacja czasu wolnego ",
    "ratingsRateNowText": "Państwa opinia jest dla nas ważna",
    "ratingsRateNowText2": "Oceń teraz!",
    // My Profile Rate BK Screen
    "rateBKScreen": "Oceń opiekun-a/-kę",
    "rateBKTitle": "Dokonaj oceny",
    "rateBKText": "W ten sposób aktywnie przyczyniasz się do tego, że w przyszłości oczekiwania naszych klientów mogą być jeszcze lepiej spełnione. Dla naszego personelu Twoja opinia jest ważnym elementem w życiu zawodowym.",
    "rateBKText2": "Oceń jakość pracy dając gwiazdki od 1 (niedostateczny) do 5 (bardzo dobry)",
    "rateBKCategory1": "Zewnętrzna prezentacja opiekunki",
    "rateBKCategory2": "Otwartość do podopieczne-j/-go i jego wymagań",
    "rateBKCategory3": "Komunikacja z pacjentem i rodziną",
    "rateBKCategory4": "Kompetencje zawodowe w zakresie pielęgnacji ciała",
    "rateBKCategory5": "Prowadzenie gospodarstwa domowego",
    "rateBKCategory6": "Znajmośc języka niemieckiego",
    "rateBKOtherLabel": "Pozostałe uwagi",
    "rateBKOther": "Osobiste słowa do opiekunki",
    // My Profile Rate PB Screen
    "ratePBScreen": "Oceń podopieczn-ą/-ego",
    "ratePBTitle": "Dokonaj oceny",
    "ratePBText": "Oceń domostwo podopiecznych. Tym sposobem pomożesz swoim koleżankom, które przyjadą po Tobie.",
    "ratePBText2": "Oceń za pomocą gwiazdek, od 1 (niewystarczająco) do 5 (bardzo dobrze)",
    "ratePBCategory1": "Standard mieszkania",
    "ratePBCategory2": "Charakter",
    "ratePBCategory3": "Podstawowa higiena ciała",
    "ratePBCategory4": "Środowisko socjalne",
    "ratePBCategory5": "Regulacja czasu wolnego ",
    "ratePBOtherLabel": "Pozostałe uwagi",
    "ratePBOther": "Krótki opis domu podopiecznych",
    // My Profile Contacts Screen
    "contactsScreen": "Kontakty",
    // My Profile Settings Screen
    "settingsScreen": "Ustawienia",
    "settingsDeleteAccountInfo": "Chcą Państwo usunąć swoje konto?",
    "settingsDeleteAccountAction": "Proszę klinąć tutaj",
    "settingsDeleteAccount": "Usuwanie konta",
    "settingsDeleteAccountText1": "Usunięcia Twojego konta nie można cofnąć!",
    "settingsDeleteAccountText2": "Usunięcie konta ma następujące konsekwencje:",
    "settingsDeleteAccountText3": "Twoje konto zostanie usunięte",
    "settingsDeleteAccountText4": "Wszystkie Twoje dane osobowe zostaną usunięte",
    "settingsDeleteAccountText5": "Cała Twoja zaplanowana lub trwająca opieka nad dzieckiem zostanie anulowana",



    
    // PROFILE OPTIONS
    "Ja": "Tak",
    "Nein": "Nie",
    // Contact Types
    "Gesetzlicher Betreuer": "Opiekun prawny",
    "Hauptkontakt": "Kontakt główny",
    "Rechnungsempfänger": "Odbiorca rachunku",
    // Pflegegrad
    "Pflegegrad 1": "Stopień pielęgnacyjny 1",
    "Pflegegrad 2": "Stopień pielęgnacyjny 2",
    "Pflegegrad 3": "Stopień pielęgnacyjny 3",
    "Pflegegrad 4": "Stopień pielęgnacyjny 4",
    "Pflegegrad 5": "Stopień pielęgnacyjny 5",
    // Beweglichkeit
    "völlig selbstständig": "Zupełnie samodzielny",
    "selbstständig am Rollator": "Samodzielny z rollatorem",
    "mit leichter Hilfe (kein Heben)": "lekka pomoc (bez podnoszenia)",
    "mit deutlicher Hilfe, etwas Heben": "wyraźna pomoc (podnoszenie)",
    "überwiegend im Rollstuhl (Umsetzen selbständig möglich, mit leichter Hilfe)": "głównie na wózku inwalidzkim (samodzielne przesiadanie lub z lekką pomocą)",
    "sehr stark eingeschränkt (Umsetzen / Umlagern mit Körperlift)": "bardzo ograniczona (przesadzanie/ transfer za pomocą liftu)",
    "komplett bettlägerig": "Osoba leżąca",
    // Einschränkungen
    "keine Einschränkungen, rüstiger Rentner/In": "Bez ograniczeń/ aktywny senior/ka",
    "schwacher Gesamtzustand / Altersschwäche": "Osłabienie starcze",
    "Herz-Kreislauf-Erkrankung": "Choroby układu krążenia ",
    "COPD / Atemnot": "COPD (przewlekła obturacyjna choroba płuc) /duszności",
    "Demenz / Alzheimer": "Demencja/Alzheimer",
    "Parkinson": "Parkinson",
    "Defekt an Knie- oder Hüftgelenken": "Problemy z kolanami lub biodrami",
    "Einschränkung nach Knie- oder Hüft-OP": "Ograniczenia pooperacyjne (kolana, biodra)",
    "Einschränkung nach Knochenbruch": "Ograniczenia po złamaniu",
    "Osteoporose": "Osteoporoza",
    "Gleichgewichtsprobleme / PNP-taube Füße": "Problemy z równowagą/ słoniowatość nóg",
    "Diabetes": "Cukrzyca",
    "Aktuelle Krebserkrankung": "Choroba nowotworowa",
    "Einschränkung der oberen Extremitäten, zB Lähmung nach Schlaganfall": "Ograniczenia górnych części ciała, np. niedowład po udarze",
    "Einschränkung der unteren Extremitäten, zB Lähmung nach Schlaganfall": "Ograniczenia dolnych części ciała, np. niedowład po udarze",
    "Schluckstörung": "Zaburzenia połykania",
    "Nervenleiden / Dauerhafte Schmerzen": "Zaburzenia nerwowe/uporczywy ból",
    "Starke Wassereinlagerung": "Zatrzymywanie wody w organiźmie",
    "Offene Beine Ulcus cruris": "Owrzodzenie nóg/otwarte rany",
    // Sinneswahrnehmung
    "keine Einschränkungen": "Bez ograniczeń",
    "beim Hören eingeschränkt": "Ograniczony słuch",
    "beim Sehen eingeschränkt": "Ograniczone widzenie",
    "beim Sprechen eingeschränkt": "Ograniczenia mowy",
    // Geisteszustand
    "normal": "Normalny",
    "altersbedingt vergesslich": "Zapominalski z powodu wieku",
    "leicht dement": "Lekka demencja",
    "dement": "Demencja",
    // Schlafverhalten
    "ohne Störung": "Bez zakłóceń",
    "1-2x wach (zB Toilettengang)": "Sporadyczne",
    "stark gestört, länger wach": "Często",
    // Essen
    "keine Hilfe": "Zakres pomocy",
    "leichte Hilfe (Happen vorbereiten)": "Lekka pomoc (pokrojenie)",
    "komplette Hilfe, Füttern": "Całkowita pomoc (karmienie)",
    // Grundpflege
    "selbstständig": "Samodzielny/-a",
    "mit leichter Unterstützung": "Z lekkim wsparciem",
    "mit deutlicher Unterstützung": "Wyraźna pomoc",
    "durch komplette Übernahme": "Kompletna pomoc",
    // Hygiene
    "Senior/In schafft es selbstständig": "Samodzielny/-a",
    "Senior/In benötigt Kontrolle / leichte Hilfe": "Lekka pomoc (kontrola po skorzystaniu z toalety)",
    "Senior/In ist inkontinent, komplette Übernahme": "Kompletna pomoc (inkontynencja)",
    // Urinkontrolle
    "Senior/In schafft es selbstständig": "Samokontrola",
    "Senior/In kann noch ansagen, benötigt Einlage / Windel zur Vorsicht": "Pomoc przy zmianie wkładki/ pieluchy",
    "Senior/In ist inkontinent, benötigt Windel mit Wechsel": "Brak kontroli (całkowita pomoc)",
    // Stuhlkontrolle
    "Senior/In schafft es selbstständig": "Samokontrola",
    "Senior/In kann noch ansagen, benötigt Einlage / Windel zur Vorsicht": "Sporadyczna pomoc",
    "Senior/In ist inkontinent, benötigt Windel mit Wechsel": "Brak kontroli (całkowita pomoc)",
    // Ankleiden
    "keine Hilfe": "Samodzielny/-a",
    "leichte Unterstützung": "Lekka pomoc",
    "komplette Übernahme": "Kompletna pomoc",
    // Wohnsituation
    "Senior/In lebt in einer Wohnung in Einfamilienhaus": "Podopieczny/-a zajmuje mieszknie w domu wielorodzinnym",
    "Senior/In lebt in einem Haus (Reihenhaus, DHH, EFH)": "Podopieczny/-a mieszka w domu (szeregowiec, bliźniak, jednorodzinny)",
    "Senior/In lebt in einer Wohnung in einem Mehrparteienhaus": "Podopieczny/-a mieszka w mieszkaniu, w kamienicy lub bloku",
    "Senior/In lebt in Bauernhaus mit aktiver Landwirtschaft": "Podopieczny/-a mieszka w gospodarstwie rolnym",
    // Wohnzustand
    "eher einfach": "Raczej skromny",
    "normal, typisch für eine Seniorenwohnung": "Normalny, typowy dla osoby starszej",
    "behindertengerecht": "Przystosowany do potrzeb osoby niepełnosprawnej",
    "modern": "Nowoczesny",
    "insgesamt eher abgewohnt": "Zaniedbany",
    "besonders sauber": "Bardzo czysty",
    "gehobene Ausstattung": "Podwyższony standard",
    "besonders vornehm und sehr gepflegt": "Wyjątkowo elegancki i bardzo zadbany",
    // Wohnort
    "Großstadt": "Duże miasto",
    "Kleinstadt": "Małe miasto",
    "Dorf": "Wieś",
    // Welche Haustiere
    "Hund": "Pies",
    "Katze": "Kot",
    "Sonstige Kleintiere": "Pozostałe małe zwierzęta",
    // Einkaufsmöglichkeiten
    "ca 5 Minuten bis 500 Meter entfernt": "ok. 5 Min. do 500 m",
    "ca 15 Minuten bis 1500 Meter entfernt": "ok. 15 Min. do 1500 m",
    "ca 30 Minuten bis 3000 Meter entfernt": "ok. 30 Min. do 3000 m",
    "weiter weg": "Daleko",
    // BK Wohnen
    "Zimmer": "Pokój",
    "extra WC": "Osobne WC",
    "eigenes Bad": "Osobna łazienka",
    "eigene Wohnung": "Osobne mieszkanie",
    // Charaktereigenschaften
    "freundlich, gastfreundlich": "Przyjazny, gościnny",
    "gut zu führen": "Ugodowy",
    "dankbar": "Wdzięczny",
    "besonders herzlich": "Serdeczny",
    "humorvoll": "Zabawny",
    "besonders sensibel": "Wrażliwy",
    "eigensinnig": "Uparty",
    "stur": "Bardzo zacięty",
    "verschlossen, introvertiert": "Zamknięty w sobie, introwertyczny",
    "sehr ruhig": "Bardzo spokojny",
    "herablassend": "Protekcjonalny",
    "teilnahmslos": "Zobojętniały, apatyczny",
    "bestimmend": "Dominujący",
    // BK Aktivitäten
    "Begleitung bei Spaziergängen": "Towarzyszenie podczas spacerów",
    "Begleitung bei Arztbesuchen": "Towarzyszenie podczas wizyt u lekarza",
    "Begleitung zu Restaurant etc": "Towarzyszenie podczas wyjść do restauracji itp.",
    "Aktivieren und Motivieren": "Aktywowanie i motywowanie",
    // BK Geschlecht
    "weiblich": "Żeńska",
    "männlich": "Męska",
    // BK Deutschkenntnisse
    "Gute bis sehr gute Kenntnisse, großer Wortschatz, grammatikalische Fehler": "Dobry do bardzo dobry - bogate słownictwo, błędy gramatyczne",
    "Ordentliche bis gute Kenntnisse, ausreichender Wortschatz, um sich gut verständigen zu können": "Dobry- wystarczające słownictwo do swobodnej komunikacji",
    "Grundkenntnisse mit geringem Wortschatz, Verständigung eingeschränkt möglich, Google-Übersetzter via Smartphone empfohlen": "Podstawowy- ubogie słownictwo, korzystanie z translatora w telefonie",


    "Frau": "Pani",
    "Herr": "Pan",
    "Divers": "Inny",
}