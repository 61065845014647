import React from 'react'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'

import LocalizationContext from './scripts/LocalizationContext'
import AppContext from './scripts/AppContext'
import UserContext from './scripts/UserContext'
import Navigation from './Navigation'

function App() {

  return (
    <ActionSheetProvider>
      <LocalizationContext>
      <AppContext>
      <UserContext>
          <Navigation />
      </UserContext>
      </AppContext>
      </LocalizationContext>
    </ActionSheetProvider>
  )

}

export default App