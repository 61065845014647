import React, { useState, useEffect, useContext } from 'react'
import { View, Text } from 'react-native'
import moment from 'moment'
import Selector from './Selector'
import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'

function Datepicker({name, state, setState, label, minYear = 1900, addYears = 0 }) {

    const { mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)
    const [date, setDate] = useState('')
    const [dateParts, setDateParts] = useState({day:'', month:'', year:''})
    
    const days = makeDays()
    const months = makeMonths()
    const years = makeYears()

    
     useEffect(() => {

        let d = state[name]
        if(moment(d).isValid('YYYY-MM-DD')){
            setDate(d)
            setDateParts({
                day: moment(d).format('DD'),
                month: moment(d).format('MM'),
                year: moment(d).format('YYYY'),
            })
        }

    }, [state])


    useEffect(() => {
        
        setState({
            ...state,
            [name]: date
        })

    }, [date])


    useEffect(() => {

        if(dateParts.year && dateParts.month && dateParts.day){
            let d = dateParts.year + '-' + dateParts.month + '-' + dateParts.day
            setDate(d)
        }

    }, [dateParts])




    function makeDays(){        
        let days = []
        for(let i=1; i<=31; i++){
            days.push(addZeros(i, 2))
        }
        return days
    }

    function makeMonths(){        
        let months = []
        for(let i=1; i<=12; i++){
            months.push(addZeros(i, 2))
        }
        return months
    }

    function makeYears(){        
        let years = []
        for(let i = moment().year() + addYears; i >= minYear; i--){
            years.push(String(i))
        }
        return years
    }

    function addZeros(number, digits){
        let withZeros = String(number)
        while(withZeros.length < digits){
            withZeros = '0' + withZeros
        }
        return withZeros
    }


    return (
        <View>
            <Text style={mainStyles.label}>{ label }</Text>
            <View style={mainStyles.formRow}>
                <View style={mainStyles.formCol}>
                    <Selector name="day" options={days.map(d => ({label:d, value:d}) )} state={dateParts} setState={setDateParts} label={t('day')} />
                </View>
                <View style={mainStyles.formCol}>
                    <Selector name="month" options={months.map(m => ({label:m, value:m}))} state={dateParts} setState={setDateParts} label={t('month')} />
                </View>
                <View style={mainStyles.formCol}>
                    <Selector name="year" options={years.map(y => ({label:y, value:y}))} state={dateParts} setState={setDateParts} label={t('year')} />
                </View>
            </View>
        </View>
    )

}

export default Datepicker