import React, { useContext } from 'react'
import { View, ScrollView, Text } from 'react-native'

import { UserContext } from '../../scripts/UserContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { AppContext } from '../../scripts/AppContext'

function ContactsScreen({ navigation }) {

    const { colors, mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)
    const { contacts, contactTypes } = useContext(UserContext)

    function Contact(contact){
        let types = contactTypes.filter(ct => contact.kategorie.includes(ct.id)).map(ct => t(ct.name.split('.').join(''), {defaultValue: ct.name}))
        return (
            <View key={contact.id} style={{borderBottomWidth:1, borderColor:colors.borderGrey, paddingVertical:20}}>
                <View style={{marginBottom:10}}>
                    <Text style={mainStyles.title2}>{contact.fullname}</Text>
                    <Text style={mainStyles.label}>{types.join(', ')}</Text>
                </View>
                <View>
                    <Text style={mainStyles.label}>{t('careEventsAddress')}</Text>
                    <Text style={{color: colors.black}}>{contact.strasse} {contact.hausnummer}</Text>
                    <Text style={{color: colors.black}}>{contact.plz} {contact.ort}</Text>
                    <Text style={{color: colors.black}}>{contact.land}</Text>
                </View>
                <View style={mainStyles.row}>
                    <Text style={mainStyles.label}>{t('fieldEmail')}: </Text>
                    <Text style={{color: colors.black}}>{contact.email}</Text>
                </View>
                <View style={mainStyles.row}>
                    <Text style={mainStyles.label}>{t('fieldPhone')}: </Text>
                    <Text style={{color: colors.black}}>{contact.telefon}</Text>
                </View>
            </View>
        )
    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={mainStyles.screenContainer}>
                { contacts.map(c => Contact(c)) }
            </View>

        </ScrollView>
    )

}

export default ContactsScreen