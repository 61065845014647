import React, { useContext, useState } from 'react'
import { View, ScrollView, Text, Pressable, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native'

import { authAxios } from '../../scripts/Axios'
import RatingSelect from '../../components/RatingSelect'
import Input from '../../components/Input'
import Loader from '../../components/Loader'
import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function RateBKScreen({ route, navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { getOpenRatings } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    
    const [inputs, setInputs] = useState({
        rating_erscheinungsbild: 0,
        rating_aufgeschlossenheit: 0,
        rating_kommunikation: 0,
        rating_kompetenz: 0,
        rating_haushalt: 0,
        rating_sprachkenntnisse: 0,
        rating_text: '',
    })

    const styles = StyleSheet.create({
        container: {
            borderBottomWidth: 1,
            borderColor: colors.borderGrey,
            marginBottom: 10,
            paddingBottom: 10,
        },
    })


    function saveRating(){

        setLoading(true)

        authAxios.post('rating/' + route.params.care_id, inputs)
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                showAlert(t('alertRatingDoneTitle'), t('alertRatingDone'))
                getOpenRatings()
                setLoading(false)
            }

        })
        .catch(error => {
            console.log(error)
        })
        .then(() => {
            navigation.goBack()
        })

    }

    return (
    <KeyboardAvoidingView enabled={Platform.OS === 'ios'} behavior="padding" style={mainStyles.keyboardContent} keyboardVerticalOffset={80}>
        <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
                
            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:40}}>
                    <Text style={mainStyles.title}>{t('rateBKTitle')}</Text>
                    <Text style={[mainStyles.titleText, {marginBottom:15}]}>{t('rateBKText')}</Text>
                    <Text style={mainStyles.titleText}>{t('rateBKText2')}</Text>
                </View>

                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('rateBKCategory1')}</Text>
                        <RatingSelect name="rating_erscheinungsbild" state={inputs} setState={setInputs} />
                    </View>
                </View>

                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('rateBKCategory2')}</Text>
                        <RatingSelect name="rating_aufgeschlossenheit" state={inputs} setState={setInputs} />
                    </View>
                </View>

                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('rateBKCategory3')}</Text>
                        <RatingSelect name="rating_kommunikation" state={inputs} setState={setInputs} />
                    </View>
                </View>
                    
                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('rateBKCategory4')}</Text>
                        <Text style={[mainStyles.lightText, {textAlign:'left'}]}>(Waschen, Kleiden, Umgang mit Inkontinenz, etc.)</Text>
                        <RatingSelect name="rating_kompetenz" state={inputs} setState={setInputs} />
                    </View>
                </View>
                    
                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('rateBKCategory5')}</Text>
                        <Text style={[mainStyles.lightText, {textAlign:'left'}]}>(Putzen, Waschen, Einkaufen, Kochen etc.)</Text>
                        <RatingSelect name="rating_haushalt" state={inputs} setState={setInputs} />
                    </View>
                </View>

                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('rateBKCategory6')}</Text>
                        <RatingSelect name="rating_sprachkenntnisse" state={inputs} setState={setInputs} />
                    </View>
                </View>

                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('rateBKOtherLabel')}</Text>
                        <Input name="rating_text" multiline={true} state={inputs} setState={setInputs} placeholder={t('rateBKOther')} />
                    </View>
                </View>

                <View style={mainStyles.buttonContainer}>
                    <Pressable onPress={() => saveRating()} style={mainStyles.primaryButton}>
                        <Text style={mainStyles.primaryButtonText}>{t('save')}</Text>
                    </Pressable>
                </View>

            </View>

        </ScrollView>
    </KeyboardAvoidingView>
    )

}

export default RateBKScreen