import React, { useState, useEffect, useContext } from 'react'
import { View, ScrollView, Text, Pressable } from 'react-native'
import Axios from 'axios'

import PersonList from '../../components/PersonList'
import Loader from '../../components/Loader'
import { authAxios } from '../../scripts/Axios'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function ProfilePBListScreen({ route, navigation }) {

    const { t, locale } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)

    const [loading, setLoading] = useState(false)
    const [profiles, setProfiles] = useState([])

    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {

        setLoading(true)

        authAxios.get('profiles', {cancelToken: cancelSource.token})
        .then(response => {

            setLoading(false)

            if(response.data.type === 'success'){
                setProfiles(response.data.profiles)
            }

        })
        .catch(error => {
            console.log(error)
        })

        return () => cancelSource.cancel('get profiles canceled')

    }, [])



    useEffect(() => {        
        if(route.params && route.params.profile){
            let profile = route.params.profile
            setProfiles([
                ...profiles.filter(p => p.id !== profile.id),
                route.params.profile
            ])
        }
    }, [route])


    function saveInputs(){
        if(profiles.length < 1){
            showAlert(t('alertMissingProfilesTitle'), t('alertMissingProfiles'))
        }else{
            navigation.navigate('Adressdaten')
        }
    }

    function handleDeleteProfile(id){
        showAlert(
            t('alertDeleteProfileTitle'),
            t('alertDeleteProfile'),
            [
                { text: t('cancel'), style: 'cancel' },
                { text: t('delete'), onPress: () => deleteProfile(id), style: 'destructive' },
            ],
        )
    }

    function deleteProfile(id){
                
        authAxios.delete('profile/' + id)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'success'){
                setProfiles(profiles.filter(p => p.id !== id))
            }

        })
        .catch(error => {
            console.log(error)
        })

    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:30}}>
                    <Text style={mainStyles.title}>{t('profilePBListTitle')}</Text>
                    { profiles.length < 1 ? <Text style={mainStyles.titleText}>{t('profilePBListText')}</Text> : null }
                    { (profiles.length >= 1 && locale.startsWith('de')) ? <Text style={mainStyles.titleText}>{t('profilePBListText2')}</Text> : null }
                </View>
                

                <View style={{marginBottom:20}}>
                    <PersonList data={profiles} del={handleDeleteProfile} navigation={navigation} navigateTo="PB Profil" />
                </View>

                <Pressable onPress={() => navigation.navigate('PB Profil')} style={mainStyles.primaryButton}>                        
                    <Text style={mainStyles.primaryButtonText}>{ profiles.length < 1 ? t('profilePBListProfileButton') : t('profilePBListProfileButton2') }</Text>
                </Pressable>

                
                { profiles.length > 0 &&
                <View style={mainStyles.buttonContainer}>
                    <Pressable onPress={() => saveInputs()} style={mainStyles.secondaryButton}>
                        <Text style={mainStyles.secondaryButtonText}>{t('continue')}</Text>
                    </Pressable>
                </View>
                }

            </View>

        </ScrollView>
    )

}

export default ProfilePBListScreen