import React, { useState, useEffect, useRef, useContext } from 'react'
import { View, ScrollView, Text, Pressable, KeyboardAvoidingView, Platform } from 'react-native'
import { MultiPicker } from '../../components/MultiPicker'
import Selector from '../../components/Selector'
import Input from '../../components/Input'
import Loader from '../../components/Loader'

import { authAxios } from '../../scripts/Axios'
import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function ProfilePersonalDataScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { contactTypes, accountContact } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const [inputs, setInputs] = useState({
        kategorie: '',
        anrede: '',
        titel: '',
        vorname: '',
        nachname: '',
        telefon: '',
        strasse: '',
        hausnummer: '',
        plz: '',
        ort: '',
        land: '',
    })

    const refVorname = useRef()
    const refNachname = useRef()
    const refStrasse = useRef()
    const refHausnummer = useRef()
    const refPLZ = useRef()
    const refOrt = useRef()
    
    useEffect(() => {
        if(accountContact){
            setInputs(accountContact)
        }
    }, [])


    function saveInputs(){

        setLoading(true)

        authAxios.post('contact', inputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{

                navigation.navigate('Pflegebedürftige Personen')

            }

        })
        .catch(error => {
            setLoading(false)
            console.log(error.response)
        })

    }


    return (
        <KeyboardAvoidingView enabled={Platform.OS === 'ios'} behavior="padding" style={mainStyles.keyboardContent} keyboardVerticalOffset={80}>
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:30}}>
                    <Text style={mainStyles.title}>{t('profilePersonalDataPBTitle')}</Text>
                    <Text style={mainStyles.titleText}>{t('profilePersonalDataPBText')}</Text>
                </View>

                <View style={mainStyles.multiSelectContainer}>
                    <Text style={mainStyles.label}>{t('fieldContactTypeLabel')}</Text>
                    <MultiPicker multi={true} options={contactTypes.map(opt => { return {label: opt.name, value: opt.id} })}
                    name="kategorie" state={inputs} setState={setInputs} style={mainStyles.select} />
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Selector name="anrede" options={[
                            {label: t('fieldSalutation1'), value: 'Herr'},
                            {label: t('fieldSalutation2'), value: 'Frau'},
                            {label: t('fieldSalutation3'), value: 'Divers'},
                        ]} state={inputs} setState={setInputs} label={t('fieldSalutationLabel')} />
                    </View>
                    <View style={mainStyles.formCol}>
                        <Input name="titel" state={inputs} setState={setInputs} placeholder={t('fieldTitle')} nextFocus={refVorname} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input reference={refVorname} name="vorname" state={inputs} setState={setInputs} placeholder={t('fieldName')} nextFocus={refNachname} />
                    </View>
                    <View style={mainStyles.formCol}>
                        <Input reference={refNachname} name="nachname" state={inputs} setState={setInputs} placeholder={t('fieldSurname')} nextFocus={refStrasse} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={[mainStyles.formCol, {flex:.75}]}>
                        <Input reference={refStrasse} name="strasse" state={inputs} setState={setInputs} placeholder={t('fieldStreet')} nextFocus={refHausnummer} />
                    </View>
                    <View style={[mainStyles.formCol, {flex:.25}]}>
                        <Input reference={refHausnummer} name="hausnummer" state={inputs} setState={setInputs} placeholder={t('fieldStreetNr')} nextFocus={refPLZ} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input reference={refPLZ} name="plz" state={inputs} setState={setInputs} placeholder={t('fieldPLZ')} nextFocus={refOrt} />
                    </View>
                    <View style={mainStyles.formCol}>
                        <Input reference={refOrt} name="ort" state={inputs} setState={setInputs} placeholder={t('fieldCity')} cityAPI={true} cityPLZ={inputs.plz} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Selector name="land" options={[
                            {label: t('germany'), value: 'DE'},
                            {label: t('poland'), value: 'PL'},
                        ]} state={inputs} setState={setInputs} label={t('fieldCountry')} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input name="telefon" state={inputs} setState={setInputs} placeholder={t('fieldPhone')} />
                    </View>
                </View>

                <View style={mainStyles.buttonContainer}>
                    <Pressable onPress={() => saveInputs()} style={mainStyles.secondaryButton}>
                        <Text style={mainStyles.secondaryButtonText}>{t('continue')}</Text>
                    </Pressable>
                </View>

            </View>

        </ScrollView>
        </KeyboardAvoidingView>
    )

}

export default ProfilePersonalDataScreen