import React, { useState, useEffect, useRef, useContext } from 'react'
import { View, ScrollView, Text, Dimensions, Pressable, Platform, StyleSheet } from 'react-native'
import { useHeaderHeight } from '@react-navigation/stack'
import Axios from 'axios'

import { fieldsFilled } from '../../scripts/Helpers'
import { authAxios } from '../../scripts/Axios'
import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

import { MultiPicker } from '../../components/MultiPicker'
import Loader from '../../components/Loader'
import Input from '../../components/Input'

function ProfileAddBKScreen({ route, navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { profileOptions } = useContext(UserContext)
    const headerHeight = useHeaderHeight()

    const cancelSource = Axios.CancelToken.source()

    const [loading, setLoading] = useState(false)
    const [currentPage, _setCurrenPage] = useState(0)
    const [inputs, setInputs] = useState({
        pflegegrad: [],
        beweglichkeit: [],
        einschraenkungen: [],
        sinneswahrnehmung: [],
        geisteszustand: [],
        schlafverhalten: [],
        essen: [],
        grundpflege: [],
        hygiene: [],
        urinkontrolle: [],
        stuhlkontrolle: [],
        ankleiden: [],
        wohnsituation: [],
        wohnzustand: [],
        wohnort: [],
        haustiere: [],
        haustiere_welche: [],
        einkaufsmoeglichkeiten: [],
        bk_wohnen: [],
        charaktereigenschaften: [],
        raucher: [],
        bk_aktivitaeten: [],
        bk_anrede: [],
        bk_deutsch: [],
        bk_fuehrerschein: [],
        sonstiges: '',
    })
    const mandatoryFields = [
        ['pflegegrad', 'beweglichkeit'],
        ['einschraenkungen'],
        ['sinneswahrnehmung', 'geisteszustand', 'schlafverhalten'],
        ['essen', 'grundpflege', 'hygiene', 'urinkontrolle', 'stuhlkontrolle', 'ankleiden'],
        ['wohnsituation', 'wohnzustand', 'wohnort', 'haustiere', 'einkaufsmoeglichkeiten', 'bk_wohnen'],
        ['charaktereigenschaften', 'raucher'],
        ['bk_aktivitaeten', 'bk_anrede', 'bk_deutsch', 'bk_fuehrerschein'],
    ]
    
    const refCurrentPage = useRef(currentPage)
    const setCurrenPage = (val) => {
        refCurrentPage.current = val
        _setCurrenPage(val)
    }
    
    const refPager = useRef()

    useEffect(() => {

        setLoading(true)
        
        const unsubListener = navigation.addListener('beforeRemove', (e) => {

            e.preventDefault()

            if(refCurrentPage.current === 0){
                showAlert(t('alertDiscardChangesTitle'), t('alertDiscardChanges'), [
                    { text: t('discardContinue'), style: 'cancel' },
                    { text: t('discard'), style: 'destructive', onPress: () => navigation.dispatch(e.data.action) },
                ])
            }else{
                setPage(refCurrentPage.current - 1, 'prev')
            }

        })

        authAxios.get('bk-profile', {cancelToken: cancelSource.token})
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                if(response.data.profile){
                    setInputs(response.data.profile)
                }
            }
        })
        .catch(error => console.log(error))

        return () => {
            cancelSource.cancel('get bk-profile canceled')
            unsubListener // is this working???
        }

    }, [])


    function setPage(index, action = 'next'){
        let check = action === 'next' ? fieldsFilled(inputs, mandatoryFields[index-1]) : {success:true}
        if(check.success){
            setCurrenPage(index)
            refPager.current.setPage(index)
        }else{
            showAlert(t('alertMissingFieldsTitle'), t('alertMissingFields', {fields: check.errors.join(', ')}))
        }
    }

    function saveInputs(){
            
        setLoading(true)

        authAxios.put('profile', inputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{  
                navigation.navigate('BK Medien')
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    const fixHeight = Platform.OS === 'ios' ? Dimensions.get('window').height - headerHeight * 2: Dimensions.get('window').height - headerHeight
    const styles = StyleSheet.create({
        fixContainer: {
            position: 'absolute',
            height: fixHeight,
            backgroundColor: colors.white,
        },
        pager: {
            position: 'relative',
            width: Dimensions.get('window').width,
            height: fixHeight,
        },
        page: {
            // ...
        },
    })

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer}>
                <View key={0} style={styles.page}>

                        <View style={{ marginBottom:30}}>
                            <Text style={mainStyles.title}>{t('profileAddBK1Title')}</Text>
                            <Text style={mainStyles.titleText}>{t('profileAddBK1Text')}</Text>
                        </View>

                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddCareLevelLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'pflegegrad').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="pflegegrad" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddMovementLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'beweglichkeit').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="beweglichkeit" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>

                </View>
                <View key={1} style={styles.page}>

                        <View style={{marginBottom:30}}>
                            <Text style={mainStyles.title}>{t('profileAddBK2Title')}</Text>
                            <Text style={mainStyles.titleText}>{t('profileAddBK2Text')}</Text>
                        </View>

                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddSicknessLabelBK')}</Text>
                            <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'einschraenkungen').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="einschraenkungen" state={inputs} setState={setInputs} style={mainStyles.select} resetID={17} />
                        </View>

                </View>
                <View key={2} style={styles.page}>

                        <View style={{marginBottom:30}}>
                            <Text style={mainStyles.title}>{t('profileAddBK3Title')}</Text>
                            <Text style={mainStyles.titleText}>{t('profileAddBK3Text')}</Text>
                        </View>

                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddSenseLabelBK')}</Text>
                            <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'sinneswahrnehmung').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="sinneswahrnehmung" state={inputs} setState={setInputs} style={mainStyles.select} resetID={38} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddMentalLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'geisteszustand').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="geisteszustand" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddSleepLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'schlafverhalten').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="schlafverhalten" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>

                </View>
                <View key={3} style={styles.page}>

                        <View style={{marginBottom:30}}>
                            <Text style={mainStyles.title}>{t('profileAddBK4Title')}</Text>
                            <Text style={mainStyles.titleText}>{t('profileAddBK4Text')}</Text>
                        </View>

                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddEatingLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'essen').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="essen" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddBaseCareLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'grundpflege').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="grundpflege" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddHygieneLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'hygiene').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="hygiene" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddPeeLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'urinkontrolle').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="urinkontrolle" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddPooLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'stuhlkontrolle').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="stuhlkontrolle" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddClothingLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'ankleiden').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="ankleiden" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>

                </View>
                <View key={4} style={styles.page}>

                        <View style={{marginBottom:30}}>
                            <Text style={mainStyles.title}>{t('profileAddBK5Title')}</Text>
                            <Text style={mainStyles.titleText}>{t('profileAddBK5Text')}</Text>
                        </View>

                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddLivingSituationLabelBK')}</Text>
                            <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'wohnsituation').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="wohnsituation" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddLivingConditionLabelBK')}</Text>
                            <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'wohnzustand').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="wohnzustand" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddLivingPlaceLabelBK')}</Text>
                            <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'wohnort').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="wohnort" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddPetsLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'haustiere').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="haustiere" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        { inputs && inputs.haustiere === 86 &&
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddWhichPetsLabelBK')}</Text>
                            <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'haustiere_welche').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="haustiere_welche" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        }
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddShoppingLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'einkaufsmoeglichkeiten').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="einkaufsmoeglichkeiten" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddLivingBKLabelBK')}</Text>
                            <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'bk_wohnen').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="bk_wohnen" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>

                </View>
                <View key={5} style={styles.page}>

                        <View style={{marginBottom:30}}>
                            <Text style={mainStyles.title}>{t('profileAddBK6Title')}</Text>
                            <Text style={mainStyles.titleText}>{t('profileAddBK6Text')}</Text>
                        </View>

                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddCharacterLabelBK')}</Text>
                            <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'charaktereigenschaften').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="charaktereigenschaften" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddSmokingLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'raucher').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="raucher" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>

                </View>
                <View key={6} style={styles.page}>

                        <View style={{marginBottom:30}}>
                            <Text style={mainStyles.title}>{t('profileAddBK7Title')}</Text>
                            <Text style={mainStyles.titleText}>{t('profileAddBK7Text')}</Text>
                        </View>

                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddActivitiesBKLabelBK')}</Text>
                            <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'bk_aktivitaeten').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="bk_aktivitaeten" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddSexBKLabelBK')}</Text>
                            <MultiPicker multi={true} options={profileOptions.filter(opt => opt.name === 'bk_anrede').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="bk_anrede" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddGermanBKLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'bk_deutsch').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="bk_deutsch" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>
                        <View style={mainStyles.multiSelectContainer}>
                            <Text style={mainStyles.label}>{t('profileAddDrivingBKLabelBK')}</Text>
                            <MultiPicker multi={false} options={profileOptions.filter(opt => opt.name === 'bk_fuehrerschein').map(opt => { return {label: opt.value, value: opt.id} })}
                            name="bk_fuehrerschein" state={inputs} setState={setInputs} style={mainStyles.select} />
                        </View>

                </View>
                <View key={7} style={styles.page}>

                    <View style={{marginBottom:30}}>
                        <Text style={mainStyles.title}>{t('profileAddBK8Title')}</Text>
                        <Text style={mainStyles.titleText}>{t('profileAddBK8Text')}</Text>
                    </View>

                    <View style={mainStyles.formRow}>
                        <View style={mainStyles.formCol}>
                            <Text style={mainStyles.label}>{t('profileAddTextBKLabel')}</Text>
                            <Input name="sonstiges" multiline={true} state={inputs} setState={setInputs} placeholder={t('profileAddTextBK')} />
                        </View>
                    </View>

                </View>
                            
                <View style={[mainStyles.buttonContainer, {marginBottom:30}]}>
                    <Pressable onPress={() => saveInputs()} style={mainStyles.primaryButton}>
                        <Text style={mainStyles.primaryButtonText}>{t('save')}</Text>
                    </Pressable>
                </View>

            </View>

        </ScrollView>
    )

}

export default ProfileAddBKScreen