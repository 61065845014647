import React, { useState, useEffect, useContext } from 'react'
import { View, FlatList, Text, Platform } from 'react-native'
import Axios from 'axios'
import moment from 'moment'

import { authAxios } from '../../scripts/Axios'
import { UserContext } from '../../scripts/UserContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

import UserPreview from '../../components/UserPreview'
import SortSelect from '../../components/SortSelect'
import Loader from '../../components/Loader'
import { AppContext } from '../../scripts/AppContext'

function CareSearchScreen({ route, navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { user } = useContext(UserContext)

    const [reordering, setReordering] = useState(false)
    const [matchedUsers, setMatchedUsers] = useState([])

    const [settings, setSettings] = useState({
        sorting: user.user_role === 1 ? 'matching_desc' : 'availability_asc',
        filter: null,
    })

    const sortOptions = user.user_role === 1 ? [
        {label: t('careSearchSort1'), value: 'matching_desc'},
        {label: t('careSearchSort3'), value: 'rating_desc'},
        {label: t('careSearchSort9'), value: 'startdate_asc'},
        {label: t('careSearchSort7'), value: 'city_asc'},
        {label: t('careSearchSort8'), value: 'city_desc'},
    ] : [
        {label: t('careSearchSort11'), value: 'availability_asc'},
        {label: t('careSearchSort1'), value: 'matching_desc'},
        {label: t('careSearchSort3'), value: 'rating_desc'},
        {label: t('careSearchSort5'), value: 'badge_desc'},
    ]

    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {
        getMatchedUsers()
        return () => cancelSource.cancel('get matched users canceled')
    }, [])

    
    useEffect(() => {
        setReordering(true)
        let sortedUsers = sortUsers(matchedUsers)
        setMatchedUsers([...sortedUsers])
    }, [settings])

    useEffect(() => {
        setReordering(false)
    }, [matchedUsers])

    function getMatchedUsers(){
        
        setReordering(true)

        authAxios.get('matched-users', {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
                setReordering(false)
            }else{
                let users = response.data.users
                let sortedUsers = sortUsers(users)
                setMatchedUsers(sortedUsers)
            }

        })
        .catch(error => console.log(error.response))

    }


    function sortUsers(users){

        let sortedUsers = []

        if(settings.sorting === 'matching_desc'){
            sortedUsers = users.sort(dynamicSort('score', 'desc'))
        }
        else if(settings.sorting === 'matching_asc'){
            sortedUsers = users.sort(dynamicSort('score', 'asc'))
        }
        else if(settings.sorting === 'rating_desc'){
            sortedUsers = users.sort(dynamicSort('rating', 'desc'))
        }
        else if(settings.sorting === 'rating_asc'){
            sortedUsers = users.sort(dynamicSort('rating', 'asc'))
        }
        else if(settings.sorting === 'startdate_desc'){
            sortedUsers = users.sort(dynamicSort('startdatum', 'desc'))
        }
        else if(settings.sorting === 'startdate_asc'){
            sortedUsers = users.sort(dynamicSort('startdatum', 'asc'))
        }
        else if(settings.sorting === 'city_desc'){
            sortedUsers = users.sort(dynamicSort('ort', 'desc'))
        }
        else if(settings.sorting === 'city_asc'){
            sortedUsers = users.sort(dynamicSort('ort', 'asc'))
        }
        else if(settings.sorting === 'badge_desc'){
            sortedUsers = users.sort(dynamicSort('badge', 'desc'))
        }
        else if(settings.sorting === 'badge_asc'){
            sortedUsers = users.sort(dynamicSort('badge', 'asc'))
        }
        else if(settings.sorting === 'availability_desc'){
            sortedUsers = users.sort(dynamicAvailibilitySort('desc'))
        }
        else if(settings.sorting === 'availability_asc'){
            sortedUsers = users.sort(dynamicAvailibilitySort('asc'))
        }
        else{
            sortedUsers = users.sort(dynamicSort('score', 'desc'))
        }

        return sortedUsers

    }


    function dynamicSort(property, order) {
        let sortOrder = order === 'desc' ? -1 : 1
        return (a, b) => {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
            return result * sortOrder
        }
    }

    function dynamicAvailibilitySort(order) {
        return (a, b) => {
            var d1 = moment(a.isAvailableAt).format('YYYY-MM-DD');
            var d2 = moment(b.isAvailableAt).format('YYYY-MM-DD');        
            if (d1 < d2) return order === 'asc' ? -1 : 1;
            if (d1 > d2) return order === 'asc' ? 1 : -1;
            if (a.score < b.score) return 1;
            if (a.score > b.score) return -1;
            return 0;
        }
    }

    const ListItem = ({ item }) => (
        <View style={mainStyles.profileListItem}>
            <View style={mainStyles.profileListItemInner}>
                <UserPreview type="search" viewUser={item} navigation={navigation} />
            </View>
        </View>
    )


    return (
        <View style={Platform.OS !== 'web' ? mainStyles.smallScreenContainer : [mainStyles.scrollContainer, { flex: 1, padding: 0 }]}>

            <Loader visible={reordering} background={colors.transWhite} color={colors.darkGrey} />

            <FlatList data={matchedUsers} renderItem={ListItem} keyExtractor={item => item.id} ListHeaderComponent={() => (                
                <View style={[mainStyles.container, {marginTop:10, marginBottom: Platform.OS === 'web' ? 20 : 0}]}>
                    <Text style={mainStyles.label}>{t('careSearchSortLabel')}</Text>
                    <SortSelect name="sorting" options={sortOptions} state={settings} setState={setSettings} callback={() => setReordering(true)} />
                </View>
            )} />

        </View>
    )

}

export default CareSearchScreen