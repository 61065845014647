import React, { useState, useEffect, useContext } from 'react'
import { View, ScrollView, Text } from 'react-native'
import Axios from 'axios'

import { authAxios } from '../../scripts/Axios'
import { UserContext } from '../../scripts/UserContext'
import RatingList from '../../components/RatingList'
import Loader from '../../components/Loader'
import UserPreview from '../../components/UserPreview'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function RatingsScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, mainStyles, colors } = useContext(AppContext)
    const { user, openRatings } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const [ratings, setRatings] = useState([])

    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {

        setLoading(true)

        authAxios.get('ratings/' + user.id, {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setRatings(response.data.ratings)
            }

        })
        .catch(error => {
            console.log(error.response)
        })
        .then(() => setLoading(false))

        return () => cancelSource.cancel('get ratings canceled')

    }, [])

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer}>

                <View>
                    <Text style={mainStyles.title2}>{t('ratingsTabToRate')}</Text>
                    {openRatings.length < 1 ? <Text style={[mainStyles.lightText, {textAlign: 'left', marginTop: 15}]}>{t('ratingsNothingToRate')}</Text> : null}
                    {openRatings.map((care, index) => (
                    <View key={index} style={mainStyles.openRating}>
                        <UserPreview type="rating" viewUser={care.user} navigation={navigation} care={care} />
                    </View>
                    ))}
                </View>
                
                <View style={{marginTop: 40}}>
                    <Text style={mainStyles.title2}>{t('ratingsTabMyRatings')}</Text>
                    <RatingList rating={user.rating} role={user.user_role} data={ratings} />
                </View>

            </View>

        </ScrollView>
    )

}

export default RatingsScreen