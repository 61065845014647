import React, { useState, useContext } from 'react'
import { Platform, TextInput, View, ActivityIndicator } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { AppContext } from '../scripts/AppContext'
import { axios } from '../scripts/Axios'

function Input({ reference, type = 'default', name, value, state, setState, nextFocus, placeholder = '', style = {}, multiline = false, secure = false, icon, editable = true, cityAPI = false, cityPLZ = null, onEnter }) {

    const { colors, mainStyles } = useContext(AppContext)

    const [isFocused, setIsFocused] = useState(false)
    const [geocoding, setGeocoding] = useState(false)

    let currentValue
    let styles
    let iconStyles
    
    if(!value){
        if(state && state[name]){
            currentValue = state[name]
        }else{
            currentValue = ''
        }
    }else{
        currentValue = value || ''
    }

    if(icon){
        styles = [
            mainStyles.inputWithIcon,
            style,
            Platform.OS === 'web' ? {
                outlineWidth: 0,
                borderColor: isFocused ? colors.primary : colors.borderGrey
            } : {
                borderColor: isFocused ? colors.primary : colors.borderGrey
            }
        ]
        iconStyles = [
            mainStyles.inputIcon,
            { color: isFocused ? colors.primary : colors.mediumGrey }
        ]
    }else if(multiline){
        styles = [
            mainStyles.textarea,
            style,
            Platform.OS === 'web' ? {
                outlineWidth: 0,
                borderColor: isFocused ? colors.primary : colors.borderGrey
            } : {
                borderColor: isFocused ? colors.primary : colors.borderGrey
            }
        ]
    }else{
        styles = [
            mainStyles.input,
            style,
            Platform.OS === 'web' ? {
                outlineWidth: 0,
                borderColor: isFocused ? colors.primary : colors.borderGrey
            } : {
                borderColor: isFocused ? colors.primary : colors.borderGrey
            }
        ]
    }

    async function geoCode(input){
        setGeocoding(true)
        const response = await axios.get('https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&countrycodes=de,pl&q=' + input)
        if(response.data.length > 0){
            let city
            let additionalTries = 2
            for(let i = 0; i < additionalTries; i++){
                if(!response.data[i]) continue
                if(!response.data[i].address) continue
                city = response.data[i].address.city
                if(city) break
            }
            setState({...state, [name]: city})
        }
        setGeocoding(false)
    }

    return (
        <View style={{position:'relative'}}>
            { icon && <FontAwesomeIcon icon={icon} size={20} style={iconStyles} accessibilityHidden={true} /> }
            <TextInput
            ref={reference}
            keyboardType={type}
            value={currentValue}
            onFocus={() => {
                setIsFocused(true)
                if(cityAPI && cityPLZ && currentValue === '') geoCode(cityPLZ)                
            }}
            onBlur={() => setIsFocused(false)}
            onChangeText={val => setState({...state, [name]: val})}
            returnKeyType={nextFocus ? 'next' : 'done'}
            onSubmitEditing={() => {
                if(Platform.OS === 'web' && onEnter){
                    onEnter()
                }else{
                    if(nextFocus) nextFocus.current.focus()
                }
            }}
            blurOnSubmit={nextFocus ? false : true}
            placeholder={placeholder}
            placeholderTextColor={colors.mediumGrey}
            multiline={multiline}
            secureTextEntry={secure}
            editable={editable}
            style={styles}
            maxLength={700}
            />
            { geocoding && <ActivityIndicator size="small" color={colors.darkGrey} style={{position: 'absolute', top: 20, right: 10}} /> }
        </View>
    )

}

export default Input