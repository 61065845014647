import React, { createContext, useState, useEffect } from 'react'
import { Alert, Platform, StatusBar } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import AwesomeAlert from 'react-native-awesome-alerts'
import Loader from '../components/Loader'
import { lightColors, lightStyles, lightWebStyles } from '../styles/Main' 
import { darkColors, darkStyles, darkWebStyles } from '../styles/MainDark' 

export const AppContext = createContext(null)

export default ({children}) => {

    const [loading, setLoading] = useState(true)
    const [loadingProgress, setLoadingProgress] = useState(null)

    const [alert, setAlert] = useState(null)


    const [theme, setTheme] = useState('light')
    const [colors, setColors] = useState(lightColors)
    const [mainStyles, setMainStyles] = useState(lightStyles)
    const [webStyles, setWebStyles] = useState(lightWebStyles)

    useEffect(() => {
        getTheme()
    }, [])

    const getTheme = async () => {
        let t = await AsyncStorage.getItem('theme')
        if(t !== null){
            setTheme(t)
            if(t === 'dark'){
                setColors(darkColors)
                setMainStyles(darkStyles)
                setWebStyles(darkWebStyles)
                StatusBar.setBarStyle('light-content', false)
            }else{
                setColors(lightColors)
                setMainStyles(lightStyles)
                setWebStyles(lightWebStyles)
                StatusBar.setBarStyle('dark-content', false)
            }
        }
    }

    const changeTheme = async (theme) => {
        AsyncStorage.setItem('theme', theme)
        setTheme(theme)
        if(theme === 'dark'){
            setColors(darkColors)
            setMainStyles(darkStyles)
            setWebStyles(darkWebStyles)
            StatusBar.setBarStyle('light-content', false)
        }else{
            setColors(lightColors)
            setMainStyles(lightStyles)
            setWebStyles(lightWebStyles)
            StatusBar.setBarStyle('dark-content', false)
        }
    }


    function showAlert(title, message, actions = null){

        if(Platform.OS === 'web'){
            if(actions){
                setAlert({
                    title: title,
                    message: message,
                    showCancelButton: actions[0].style === 'cancel',
                    cancelText: actions[0] ? actions[0].text : 'Abbrechen',
                    confirmText: actions[1] ? actions[1].text : 'OK',
                    onCancel: null,
                    onConfirm: actions[1] ? actions[1].onPress : null,
                })
            }else{
                setAlert({
                    title: title,
                    message: message,
                    showCancelButton: false,
                    confirmText: 'OK',
                    onCancel: null,
                    onConfirm: null,
                })
            }
        }else{
            Alert.alert(title, message, actions)
        }

    }

    function cancelAlert(){
        if(alert.onCancel){
            alert.onCancel()
        }
        setAlert(null)
    }

    function confirmAlert(){
        if(alert.onConfirm){
            alert.onConfirm()
        }
        setAlert(null)
    }

    function MyAlert(){

        return alert ? (
            <AwesomeAlert
            show={true}
            title={alert.title}
            message={alert.message}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={alert.showCancelButton}
            showConfirmButton={true}
            cancelText={alert.cancelText}
            confirmText={alert.confirmText}
            confirmButtonColor={colors.primary}
            onCancelPressed={cancelAlert}
            onConfirmPressed={confirmAlert}
            />
        ) : null
    }


    const data = {
        loading, setLoading,
        setLoadingProgress,
        showAlert,
        theme, changeTheme, colors, mainStyles, webStyles,
    }

    useEffect(() => {
        setLoadingProgress(null)
    }, [loading])

    return (
        <AppContext.Provider value={data}>

            {children}

            <Loader visible={loading} progress={loadingProgress} color={colors.darkGrey} background={colors.transWhite} />

            <MyAlert />

        </AppContext.Provider>
    )
    
}