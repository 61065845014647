import React, { useEffect, useState, useContext } from 'react'
import { View, ScrollView, Text, Pressable } from 'react-native'
import moment from 'moment'
import Axios from 'axios'
import { authAxios } from '../../scripts/Axios'
import { AppContext } from '../../scripts/AppContext'
import { UserContext } from '../../scripts/UserContext'

function MessagesScreen({ route, navigation }) {
    
    const { showAlert, mainStyles } = useContext(AppContext)
    const { getMessagesBadge } = useContext(UserContext)

    const [messages, setMessages] = useState([])

    const cancelSource = Axios.CancelToken.source()


    useEffect(() => {
        getMessages()
        getMessagesBadge()
        return () => cancelSource.cancel('get messages canceled')
    }, [])


    function getMessages(){ // diese function ist das problem ....

        authAxios.get('messages', {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setMessages(response.data.messages)
            }

        })
        .catch(error => {
            console.log('get messages error', error)
        })

    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={mainStyles.screenContainer}>
                {messages.map((message, index) => {
                    return (
                        <Pressable key={message.id} style={mainStyles.message} onPress={() => navigation.navigate('E-Mail', {message_id: message.id})}>
                            <Text style={mainStyles.messageDate}>{ moment(message.created_at).format('DD.MM.YYYY HH:mm') }</Text>
                            <Text style={[mainStyles.messageSubject, {fontWeight: message.gelesen ? 'normal' : 'bold'}]} numberOfLines={1}>{ message.betreff }</Text>
                        </Pressable>
                    )
                })}
            </View>

        </ScrollView>
    )

}

export default MessagesScreen