import React, { useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faClock, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import { AppContext } from '../scripts/AppContext'
import { LocalizationContext } from '../scripts/LocalizationContext'

function CareStatus({ care, previous }) {

    const { colors } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)
    const status = care.status !== 1 && previous ? 3 : care.status

    const states = [
        { text: t('careEventsStatusOrganized'), color: colors.blue, icon: faClock },
        { text: t('careEventsStatusCanceled'), color: colors.red, icon: faTimesCircle },
        { text: t('careEventsStatusConfirmed'), color: colors.green, icon: faCheckCircle },
        { text: t('careEventsStatusOld'), color: colors.mediumGrey, icon: faCheckCircle },
    ]


    const styles = StyleSheet.create({
        box: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        textBox: {            
            marginLeft: 6,
        },
        careStatus: {
            fontWeight: 'bold',
            fontSize: 14,
            color: states[status].color,
        },
    })

    return (
        <View style={styles.box}>
            <FontAwesomeIcon icon={states[status].icon} size={16} color={states[status].color} />
            <View style={styles.textBox}>
                <Text style={styles.careStatus}>{ states[status].text }</Text>
            </View>
        </View>
    )

}

export default CareStatus