import React, { useState, useEffect, useContext } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import moment from 'moment'

import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'

function UserStatus({ user, size = 12 }) {

    const { colors } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)
    const [state, setState] = useState({
        text: user.user_role === 1 ? t('isAvailable') : t('isAvailable') + ' ' + t('atDate') + ' ' + moment(user.startdatum).format('DD.MM.YYYY'),
        color: colors.green
    })

    useEffect(() => {

        if(user.user_role === 2){    

            if(user.isOrganizing)
                setState({ text: t('careEventsStatusOrganized'), color: colors.blue })
            else if(user.isRequested)
                setState({ text: user.user_role === 1 ? t('isRequestedBK') : t('isRequested'), color: colors.primary })

        }else{

            if(user.isOrganizing)
                setState({ text: t('careEventsStatusOrganized'), color: colors.blue })
            else if(user.isCaring)
                setState({ text: t('profileIsCaring'), color: colors.primary })
            else if(user.isRequested)
                setState({ text: t('isRequestedBK'), color: colors.primary })
            else{
                if(moment(user.isAvailableAt).isSame(moment(), 'day'))
                    setState({ text: t('isAvailable') + ' ' + t('instantly'), color: colors.green })
                else{
                    let availableAt = moment(user.isAvailableAt)
                    if(availableAt.isSame(moment(), 'year')) availableAt = moment(user.isAvailableAt).format('DD.MM.')
                    else availableAt = moment(user.isAvailableAt).format('DD.MM.YYYY')
                    setState({ text: t('isAvailable') + ' ' + t('atDate') + ' ' + availableAt, color: colors.yellowGreen })
                }
            }

        }

    }, [user])

    const styles = StyleSheet.create({
        box: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        userStatus: {
            fontWeight: 'bold',
            fontSize: size,
            color: state.color,
        },
    })

    return (
        <View style={styles.box}>
            <Text style={styles.userStatus}>{ state.text }</Text>
        </View>
    )

}

export default UserStatus