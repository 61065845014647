import React, { useState, useContext, useEffect, useRef } from 'react'
import { View, ScrollView, Text, TextInput, Pressable, KeyboardAvoidingView, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

import FooterMenu from '../../components/FooterMenu'

import { LocalizationContext } from '../../scripts/LocalizationContext'
import { AppContext } from '../../scripts/AppContext'
import { UserContext } from '../../scripts/UserContext'
import { authAxios } from '../../scripts/Axios'

function AccountConfirmationVerifyScreen({ route, navigation }) {

    const { t } = useContext(LocalizationContext)
    const { setLoading, showAlert, mainStyles } = useContext(AppContext)
    const { setUser } = useContext(UserContext)
    
    const { send_type, mobile } = route.params;

    const [codeChar1, setCodeChar1] = useState('')
    const [codeChar2, setCodeChar2] = useState('')
    const [codeChar3, setCodeChar3] = useState('')
    const [codeChar4, setCodeChar4] = useState('')

    const UseFocus = () => {
        const htmlElRef = useRef(null)
        const setFocus = () => {htmlElRef.current && htmlElRef.current.focus()}    
        return [ htmlElRef,  setFocus ] 
    }

    const [codeChar1Ref, setCodeChar1Focus] = UseFocus()
    const [codeChar2Ref, setCodeChar2Focus] = UseFocus()
    const [codeChar3Ref, setCodeChar3Focus] = UseFocus()
    const [codeChar4Ref, setCodeChar4Focus] = UseFocus()

    const [inputCode, setInputCode] = useState('')

    useEffect(() => setInputCode(codeChar1 + codeChar2 + codeChar3 + codeChar4), [codeChar1, codeChar2, codeChar3, codeChar4])

    function checkInput(value){
        if(codeChar1 === ''){
            setCodeChar1(value)
            setCodeChar2Focus()
        }
        else if(codeChar2 === ''){
            setCodeChar2(value)
            setCodeChar3Focus()
        }
        else if(codeChar3 === ''){
            setCodeChar3(value)
            setCodeChar4Focus()
        }
        else if(codeChar4 === ''){
            setCodeChar4(value)
        }
    }

    function checkKey(e){
        if(e.key === 'Backspace'){
            if(inputCode.length === 4){
                setCodeChar4('')
                setCodeChar4Focus()
            }
            else if(inputCode.length === 3){
                setCodeChar3('')
                setCodeChar3Focus()
            }
            else if(inputCode.length === 2){
                setCodeChar2('')
                setCodeChar2Focus()
            }
            else if(inputCode.length === 1){
                setCodeChar1('')
                setCodeChar1Focus()
            }
        }
    }

    function verifyCode(){

        setLoading(true)

        authAxios.post('account-confirmation-verify', {
            send_type: send_type, 
            mobile: mobile,
            code: inputCode,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                const setUserData = async () => {
                    await AsyncStorage.setItem('user', JSON.stringify(response.data.user))
                    setUser(response.data.user)
                }
                setUserData()
            }

        })
        .catch(error => {
            console.log(error.response)
        })

    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:30}}>
                    <Text style={mainStyles.title}>{t('accountConfirmationVerifyTitle')}</Text>                
                    { send_type === 'email' ? <Text style={mainStyles.titleText}>{t('accountConfirmationVerifyEmailText')}</Text> : null }
                    { send_type === 'sms' ? <Text style={mainStyles.titleText}>{t('accountConfirmationVerifySMSText')}</Text> : null }
                </View>
                    
                <KeyboardAvoidingView enabled={Platform.OS === 'ios'} behavior="position" style={mainStyles.keyboardContent} keyboardVerticalOffset={0}>
                    
                    <Text style={mainStyles.titleText}>{t('accountConfirmationVerifyCodeText')}</Text>
                    <View style={{flexDirection:'row', justifyContent:'space-evenly', marginBottom:20,}}>
                        <TextInput keyboardType="number-pad" value={codeChar1} style={mainStyles.codeInput} onChangeText={value => checkInput(value)} onKeyPress={({nativeEvent}) => checkKey(nativeEvent)} maxLength={1} ref={codeChar1Ref} />
                        <TextInput keyboardType="number-pad" value={codeChar2} style={mainStyles.codeInput} onChangeText={value => checkInput(value)} onKeyPress={({nativeEvent}) => checkKey(nativeEvent)} maxLength={1} ref={codeChar2Ref} />
                        <TextInput keyboardType="number-pad" value={codeChar3} style={mainStyles.codeInput} onChangeText={value => checkInput(value)} onKeyPress={({nativeEvent}) => checkKey(nativeEvent)} maxLength={1} ref={codeChar3Ref} />
                        <TextInput keyboardType="number-pad" value={codeChar4} style={mainStyles.codeInput} onChangeText={value => checkInput(value)} onKeyPress={({nativeEvent}) => checkKey(nativeEvent)} maxLength={1} ref={codeChar4Ref} />
                    </View>
                    
                    <Pressable onPress={() => verifyCode()} style={mainStyles.primaryButton}>
                        <Text style={mainStyles.primaryButtonText}>{t('accountConfirmationVerifyButton')}</Text>
                    </Pressable>

                </KeyboardAvoidingView>
                        
                <FooterMenu navigation={navigation} />
            
            </View>

        </ScrollView>
    )

}

export default AccountConfirmationVerifyScreen