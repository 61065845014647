import React, { useContext } from 'react'
import { View, Text } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons'

import { AppContext } from '../scripts/AppContext'
import { LocalizationContext } from '../scripts/LocalizationContext'

function Badge({ value, size = 16 }) {

    const { t } = useContext(LocalizationContext)
    const { colors } = useContext(AppContext)

    const states = [
        {
            title: t('statusBronze'),
            color: '#d47835',
        },
        {
            title: t('statusSilver'),
            color: '#9c9c9c',
        },
        {
            title: t('statusGold'),
            color: '#dda430',
        },
    ]

    return value > 0 ? (
        <View style={{flexDirection:'row', alignItems:'center'}}>
            <FontAwesomeIcon icon={faCrown} color={states[value-1].color} size={size} style={{marginRight:5}} />
            <Text style={{fontSize: size * .8, color: states[value-1].color}}>{ states[value-1].title }</Text>
        </View>
    ) : <Text style={{fontSize: size * .8, color: colors.mediumGrey}}>{t('statusNone')}</Text>

}

export default Badge