import React, { useContext } from 'react'
import { StyleSheet, View, Text, Pressable } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

import { AppContext } from '../scripts/AppContext'

function CheckBox({state, setState, name, label, labelSize, iconSize, navigation = null, linkTo = null, linkToText = ''}) {
    
    const { colors, mainStyles } = useContext(AppContext)

    const styles = StyleSheet.create({
        container: {
            flexDirection:'row',
            alignItems:'center',
        },
        label: {
            fontSize: labelSize || 14,
            color: colors.darkGrey,
            marginLeft: 10,
        }
    })

    function select(){
        setState({...state, [name]: !state[name]})
    }

    return (
        <Pressable onPress={() => select()} hitSlop={10}>
            <View style={ styles.container }>
                <FontAwesomeIcon icon={ faCheckCircle } color={state && state[name] ? colors.green : colors.borderGrey} size={iconSize || 24} style={{flexShrink:0}} />
                <View>
                    <Text style={styles.label}>{ label }</Text>
                    {linkTo ? <Text style={[mainStyles.pinkLink, {marginLeft: 10}]} onPress={() => navigation.navigate(linkTo)}>{ linkToText }</Text> : null}
                </View>
            </View>
        </Pressable>
    )

}

export default CheckBox