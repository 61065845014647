import React, { useContext } from 'react'
import { StyleSheet, View, Text, Pressable, Dimensions, Platform } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faDownload, faEllipsisV, faFile, faFileExcel, faFileImage, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import * as Filesystem from 'expo-file-system'
import * as Sharing from 'expo-sharing'
import { useActionSheet } from '@expo/react-native-action-sheet'

import { authAxios } from '../scripts/Axios'
import { displayFileSize } from '../scripts/Helpers'
import { AppContext } from '../scripts/AppContext'
import { LocalizationContext } from '../scripts/LocalizationContext'

function DocumentList({ data, onDelete, editable = true, attachments = false, directDownload = false }) {

    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)

    const { showActionSheetWithOptions } = useActionSheet()

    const styles = StyleSheet.create({
        container: {
            flexDirection:'column',
            borderColor: colors.borderGrey,
        },
        item: {
            flexDirection:'row',
            borderBottomWidth: 1,
            borderColor: colors.borderGrey,
            padding: 10,
            height: 60,
            alignItems: 'center',
        },
        iconBox: {
            justifyContent: 'center',
            alignItems: 'center',
            width: 36,
            height: 36,
            marginRight: 10,
        },
        label: {
            fontSize: 15,
            width: Dimensions.get('window').width - 36 - 36 - 30,
            color: colors.darkGrey,
        },
        subLabel: {
            fontSize: 13,
            color: colors.mediumGrey,
        },
        editIconBox: {
            marginLeft: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            width: 36,
            height: 36,
        }
    })

    function setActiveDocument(id, index){

        if(editable){
            showActionSheetWithOptions(
                {
                    options: [t('delete'), t('cancel')],
                    cancelButtonIndex: 1,
                    destructiveButtonIndex: 0
                },
                (buttonIndex) => {
                    if(buttonIndex === 0){
                        onDelete(id, index)
                    }
                }
            )
        }else{
            if(directDownload){
                downloadFile(id)
            }else{
                showActionSheetWithOptions(
                    {
                        options: [t('save'), t('cancel')],
                        cancelButtonIndex: 1,
                        destructiveButtonIndex: null
                    },
                    (buttonIndex) => {
                        if(buttonIndex === 0){
                            downloadFile(id)
                        }
                    }
                )
            }
        }
    }

    async function downloadFile(id){

        let endpoint = 'document/' + id
        if(attachments) endpoint = 'attachment/' + id

        let response = await authAxios.get(endpoint)
        if(response.data){

            let document = response.data.document
            let uri = Filesystem.documentDirectory + '/' + document.name
            await Filesystem.writeAsStringAsync(uri, document.base64, { encoding: Filesystem.EncodingType.Base64 })
            await Sharing.shareAsync(uri)

        }else{
            showAlert(t('error'), 'Document not found')
        }
        
    }

    async function downloadFileWeb(id){

        let endpoint = 'document/' + id
        if(attachments) endpoint = 'attachment/' + id

        let response = await authAxios.get(endpoint)
        if(response.data){

            let file = response.data.document
            let link = document.createElement('a')
            link.href = URL.createObjectURL(makeBlob(file))
            link.target = '_blank'
            //link.setAttribute('download', file.name)
            document.body.appendChild(link)
            link.click()

        }else{
            showAlert(t('error'), 'Document not found')
        }       

    }

    function makeBlob(file){
        let byteCharacters = atob(file.base64)
        let byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        let byteArray = new Uint8Array(byteNumbers)
        return new Blob([byteArray], {type: file.typ});
    }

    return (
        <View style={styles.container}>

            { data.length < 1 ? <Text style={[mainStyles.lightText, {marginVertical: 10}]}>{t('noFiles')}</Text> : null }
            
            { data.map((item, index) => {

                let name = item.name ? item.name : t('image')
                let ext
                if(item.datei || item.name){
                    ext = item.datei ? item.datei.split('.') : item.name.split('.')
                }else{
                    ext = item.uri.split('.')
                }
                ext = ext[ext.length-1]
                let size = item.groesse ? displayFileSize(item.groesse) : displayFileSize(item.size)
                let icon = faFile
                let iconColor = colors.darkGrey

                if(['pdf'].includes(ext)){
                    icon = faFilePdf
                    iconColor = '#cc4b4c' 
                }
                else if(['jpg', 'jpeg', 'png'].includes(ext)){
                    icon = faFileImage
                    iconColor = '#14a085' 
                }
                else if(['doc', 'docx'].includes(ext)){
                    icon = faFileWord
                    iconColor = '#0096e6' 
                }
                else if(['xls', 'xlsx'].includes(ext)){
                    icon = faFileExcel
                    iconColor = '#91cda0' 
                }

                return (
                    <View key={index} style={styles.item}>
                        <View style={styles.iconBox}>
                            <FontAwesomeIcon icon={icon} size={24} color={iconColor} />
                        </View>
                        <View style={{flex:1}}>
                            <Text style={styles.label} numberOfLines={1}>{name}</Text>
                            <Text style={styles.subLabel}>{ (size ? size + ' • ' : '') + t('createdAt') + ' ' + moment(item.created_at).format('DD.MM.YYYY') }</Text>
                        </View>
                        { Platform.OS !== 'web' ? (
                            <Pressable style={styles.editIconBox} hitSlop={15} onPress={() => setActiveDocument(item.id, index)}>
                                <FontAwesomeIcon icon={faEllipsisV} size={20} color={colors.mediumGrey} />
                            </Pressable>
                        ):(
                            <Pressable style={styles.editIconBox} hitSlop={15} onPress={() => downloadFileWeb(item.id)}>
                                <FontAwesomeIcon icon={faDownload} size={20} color={colors.mediumGrey} />
                            </Pressable>
                        )}
                    </View>
                )
            }) }

        </View>
    )

}


export default DocumentList