import React, { useContext, useState, useEffect, useRef } from 'react'
import { View, ScrollView, Text, Pressable, KeyboardAvoidingView, Platform } from 'react-native'
import Axios from 'axios'

import Selector from '../../components/Selector'
import Datepicker from '../../components/Datepicker'
import Input from '../../components/Input'
import Loader from '../../components/Loader'

import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { authAxios } from '../../scripts/Axios'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function ProfilePersonalDataBKScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, mainStyles, colors } = useContext(AppContext)
    const { user, token, setUserData } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const cancelSource = Axios.CancelToken.source()

    const [inputs, setInputs] = useState({
        anrede: '',
        titel: '',
        vorname: '',
        nachname: '',
        geburtsdatum: '',
        //groesse: '',
        //gewicht: '',
        nationalitaet: '',
        familienstand: '',
        telefon: '',
        strasse: '',
        hausnummer: '',
        plz: '',
        ort: '',
        land: '',
    })

    const refVorname = useRef()
    const refNachname = useRef()
    const refStrasse = useRef()
    const refHausnummer = useRef()
    const refPLZ = useRef()
    const refOrt = useRef()
    //const refGroesse = useRef()
    //const refGewicht = useRef()
    
    useEffect(() => {

        setLoading(true)
        
        authAxios.get('bk-profile', {cancelToken: cancelSource.token}) 
        .then(response => {

            if(response.data.type === 'success'){
                if(response.data.profile){
                    setInputs({
                        ...response.data.profile,
                        telefon: user.telefon || '',
                        strasse: user.strasse || '',
                        hausnummer: user.hausnummer || '',
                        plz: user.plz || '',
                        ort: user.ort || '',
                        land: user.land || '',
                    })
                }
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
        .then(() => {
            setLoading(false)
        })

        return () => cancelSource.cancel('get bk-profile canceled')

    }, [])


    function saveInputs(){

        setLoading(true)

        authAxios.post('bk-profile', inputs)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setNewUserData(response.data.user)
            }

        })
        .catch(error => {
            setLoading(false)
            console.log(error)
        })

    }

    async function setNewUserData(data){
        await setUserData(data, token)
        navigation.navigate('BK Profil')
    }

    return (
        <KeyboardAvoidingView enabled={Platform.OS === 'ios'} behavior="padding" style={mainStyles.keyboardContent} keyboardVerticalOffset={80}>
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={{...mainStyles.container, marginBottom:30}}>
                <Text style={mainStyles.title}>{t('profilePersonalDataBKTitle')}</Text>
                <Text style={mainStyles.titleText}>{t('profilePersonalDataBKText')}</Text>
            </View>
            
            <View style={mainStyles.container}>

                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Selector name="anrede" options={[
                            {label: t('fieldSalutation1'), value: 'Herr'},
                            {label: t('fieldSalutation2'), value: 'Frau'},
                            {label: t('fieldSalutation3'), value: 'Divers'},
                        ]} state={inputs} setState={setInputs} label={t('fieldSalutationLabel')} />
                    </View>
                    <View style={mainStyles.formCol}>
                        <Input name="titel" state={inputs} setState={setInputs} placeholder={t('fieldTitle')}nextFocus={refVorname} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input reference={refVorname} name="vorname" state={inputs} setState={setInputs} placeholder={t('fieldName')} nextFocus={refNachname} />
                    </View>
                    <View style={mainStyles.formCol}>
                        <Input reference={refNachname} name="nachname" state={inputs} setState={setInputs} placeholder={t('fieldSurname')} />
                    </View>
                </View>
                <Datepicker name="geburtsdatum" state={inputs} setState={setInputs} label={t('fieldBirth')} />
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Selector name="familienstand" options={[
                            {label: t('fieldPersonalState1'), value: 1},
                            {label: t('fieldPersonalState2'), value: 2},
                            {label: t('fieldPersonalState3'), value: 3},
                            {label: t('fieldPersonalState4'), value: 4},
                        ]} state={inputs} setState={setInputs} label={t('fieldPersonalStateLabel')} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input name="nationalitaet" state={inputs} setState={setInputs} placeholder={t('fieldNationality')} />
                    </View>
                </View>
                {/* <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input reference={refGroesse} name="groesse" state={inputs} setState={setInputs} placeholder={t('fieldSize')} nextFocus={refGewicht} />
                    </View>
                    <View style={mainStyles.formCol}>
                        <Input reference={refGewicht} name="gewicht" state={inputs} setState={setInputs} placeholder={t('fieldWeight')} nextFocus={refStrasse} />
                    </View>
                </View> */}
                <View style={mainStyles.formRow}>
                    <View style={{...mainStyles.formCol, flex:.75}}>
                        <Input reference={refStrasse} name="strasse" state={inputs} setState={setInputs} placeholder={t('fieldStreet')} nextFocus={refHausnummer} />
                    </View>
                    <View style={{...mainStyles.formCol, flex:.25}}>
                        <Input reference={refHausnummer} name="hausnummer" state={inputs} setState={setInputs} placeholder={t('fieldStreetNr')} nextFocus={refPLZ} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input reference={refPLZ} name="plz" state={inputs} setState={setInputs} placeholder={t('fieldPLZ')} nextFocus={refOrt} />
                    </View>
                    <View style={mainStyles.formCol}>
                        <Input reference={refOrt} name="ort" state={inputs} setState={setInputs} placeholder={t('fieldCity')} cityAPI={true} cityPLZ={inputs.plz} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Selector name="land" options={[
                            {label: t('germany'), value: 'DE'},
                            {label: t('poland'), value: 'PL'},
                        ]} state={inputs} setState={setInputs} label={t('fieldCountry')} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input name="telefon" state={inputs} setState={setInputs} placeholder={t('fieldPhone')} />
                    </View>
                </View>

                <View style={mainStyles.buttonContainer}>
                    <Pressable onPress={() => saveInputs()} style={mainStyles.secondaryButton}>
                        <Text style={mainStyles.secondaryButtonText}>{t('continue')}</Text>
                    </Pressable>
                </View>

            </View>

        </ScrollView>
        </KeyboardAvoidingView>
    )

}

export default ProfilePersonalDataBKScreen