import React, { createContext, useContext, useState, useEffect } from 'react'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Notifications from 'expo-notifications'
import { axios, authAxios } from './Axios'
import { AppContext } from './AppContext'
import moment from 'moment'
import { LocalizationContext } from './LocalizationContext'

export const UserContext = createContext(null)

export default ({children}) => {

    const { setLoading, showAlert } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)
    //const { getMediaCache, mediaLoaded, setMediaLoaded } = useContext(CacheContext)

    const [user, setUser] = useState(null)
    const [token, setToken] = useState(null)
    const [userDataReady, setUserDataReady] = useState(false)

    const [accountContact, setAccountContact] = useState(null)
    const [accountProfile, setAccountProfile] = useState(null)

    const [openRatings, setOpenRatings] = useState([])

    const [dataLoaded, setDataLoaded] = useState(false)

    const [profilesLoaded, setProfilesLoaded] = useState(false)
    const [profileOptionsLoaded, setProfileOptionsLoaded] = useState(false)
    const [userRequestsLoaded, setUserRequestsLoaded] = useState(false)
    const [userCaresLoaded, setUserCaresLoaded] = useState(false)
    const [contactsLoaded, setContactsLoaded] = useState(false)
    const [contactTypesLoaded, setContactTypesLoaded] = useState(false)

    const maxRequests = 3
    const [availableRequests, setAvailableRequests] = useState([])

    const [profiles, setProfiles] = useState([])
    const [profileOptions, setProfileOptions] = useState([])
    const [userRequests, setUserRequests] = useState([])
    const [userCares, setUserCares] = useState([])
    const [contacts, setContacts] = useState([])
    const [contactTypes, setContactTypes] = useState([])


    const [caresBadgeSum, setCaresBadgeSum] = useState(0)
    const [requestsBadge, setRequestsBadge] = useState(0)
    const [caresBadge, setCaresBadge] = useState(0)
    const [messagesBadge, setMessagesBadge] = useState(0)

    useEffect(() => {
        let req = userRequests.filter(r => r.status === 0).length
        let car = userCares.filter(c => (c.status === 0 && moment(c.startdatum).isAfter(moment())) || (c.status === 2 && moment(c.enddatum).isAfter(moment())) ).length
        setCaresBadgeSum(req + car)
        setRequestsBadge(req)
        setCaresBadge(car)
    }, [userRequests, userCares])
    


    useEffect(() => {
        if(user && token){
            setUserDataReady(true)
        }
    }, [user, token])
    
    useEffect(() => {
        if(userDataReady){
            getUserData()
        }
    }, [userDataReady])

    
    useEffect(() => {

        if(profilesLoaded && profileOptionsLoaded && userRequestsLoaded && userCaresLoaded && contactsLoaded && contactTypesLoaded){
            setDataLoaded(true)
        }

    }, [profilesLoaded, profileOptionsLoaded, userRequestsLoaded, userCaresLoaded, contactsLoaded, contactTypesLoaded])


 /*    useEffect(() => {
        let requestCount = 0
        userRequests.map(request => {
            if(user.user_role === 1 && request.status === 0 && request.user_id === user.id){
                requestCount++
            }
        })
        setAvailableRequests(maxRequests - requestCount) // Problem: weil alles neu rendert :(
    }, [userRequests])
     */


    
    const login = async (inputs) => {

        setLoading(true)

        try {
            let response = await axios.post('login', inputs)
            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{

                await setUserData(response.data.user, response.data.token, inputs.stayloggedin)

            }
        } catch(error) {
            console.log(error)
        }

        setLoading(false)
 
    }


    const register = async (inputs) => {

        setLoading(true)

        try {
            let response = await axios.post('register', inputs)
            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{

                await setUserData(response.data.user, response.data.token)

            }
        } catch(error){
            console.log(error)
        }

        setLoading(false)

    }
    

    const setUserData = async (user, token, stayloggedin = true) => {
        if(!user && !token){
            await AsyncStorage.removeItem('user')
            await AsyncStorage.removeItem('token')
        }
        else if(stayloggedin){
            await AsyncStorage.setItem('user', JSON.stringify(user))
            await AsyncStorage.setItem('token', JSON.stringify(token))
        }
        setUser(user)
        setToken(token)
        return true
    }


    const checkUser = async () => {

        let user = await AsyncStorage.getItem('user')
        user = JSON.parse(user) || null

        let token = await AsyncStorage.getItem('token')
        token = JSON.parse(token) || null

        if(user && token){

            let response = await axios.get('user', {
                headers: { 'Authorization': 'Bearer ' + token }
            })

            if(response && response.data && response.data.user){
                await setUserData(response.data.user, token)
                return true
            }else{
                await setUserData(null, null)
                return false
            }

        }else{
            await setUserData(null, null)
            return false
        }

    }


    const getUserData = () => {
        setDataLoaded(false)

        setProfilesLoaded(false)
        setProfileOptionsLoaded(false)
        setUserRequestsLoaded(false)
        setUserCaresLoaded(false)
        setContactsLoaded(false)

        getProfiles()
        getProfileOptions()
        getUserRequests()
        getUserCares()
        getContacts()
        getContactTypes()
        getOpenRatings()
        getMessagesBadge()
    }


    const getProfiles = () => {

        authAxios.get('profiles')
        .then(response => {
            
            if(response.data.type === 'success'){
                setProfiles(response.data.profiles)
                setProfilesLoaded(true)

                setAccountProfile(response.data.profiles[0])
            }

        })
        .catch(error => {
            console.log('get profiles error', error)
        })

    }

    const getProfileOptions = () => {

        authAxios.get('profile-options')
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setProfileOptions(response.data.options)
                setProfileOptionsLoaded(true)
            }

        })
        .catch(error => {
            console.log('get profileoptions', error)
        })

    }
    

    const getUserRequests = () => {

        authAxios.get('requests')
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setUserRequests(response.data.requests)
                setUserRequestsLoaded(true)
                setAvailableRequests(response.data.availableRequests)
            }

        })
        .catch(error => {
            console.log('get requests error', error)
        })

    }

    const getUserCares = () => {

        authAxios.get('cares')
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setUserCares(response.data.cares)
                setUserCaresLoaded(true)
            }

        })
        .catch(error => {
            console.log('get cares error', error)
        })

    }


    const getContacts = () => {

        authAxios.get('contacts')
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setContacts(response.data.contacts)

                let accUser = response.data.contacts.find(c => c.account === 1)
                setAccountContact(accUser)

                setContactsLoaded(true)
                    
            }

        })
        .catch(error => {
            console.log('get contacts error', error)
        })

    }

    const getContactTypes = () => {

        authAxios.get('contact-types')
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setContactTypes(response.data.types)
                setContactTypesLoaded(true)
            }

        })
        .catch(error => {
            console.log('get contacttypes error', error)
        })

    }

    const getOpenRatings = () => {
        
        authAxios.get('open-ratings')
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setOpenRatings(response.data.openratings)
            }

        })
        .catch(error => {
            console.log('get openratings error', error)
        })

    }

    const getMessagesBadge = () => {
        
        authAxios.get('messages-badge')
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setMessagesBadge(response.data.messages)
            }

        })
        .catch(error => {
            console.log('get message badge error', error)
        })

    }




    // Logout
    const logout = async () => {

        setLoading(true)

        if(Platform.OS !== 'web'){

            let expoToken = (await Notifications.getExpoPushTokenAsync()).data

            authAxios.post('exponent/devices/unsubscribe', {expo_token: expoToken})
            .then(response => {
                //console.log('unsubscribed from push notifications!')
                deleteUserData()
            })
            .catch(error => {
                //console.log('unsubscribe notification error', error)
                deleteUserData()
            })

        }else{
            deleteUserData()
        }


    }



    const deleteUserData = async () => {

        authAxios.post('logout')
        .then(response => {

            AsyncStorage.removeItem('user')
            AsyncStorage.removeItem('token')
            
            setUser(null)
            setToken(null)
            setUserDataReady(false)

        })
        .catch(error => {

            AsyncStorage.removeItem('user')
            AsyncStorage.removeItem('token')
            
            setUser(null)
            setToken(null)
            setUserDataReady(false)        
        })
        .then(() => {
            setLoading(false)
        })
        
        /* setProfilesLoaded(false)
        setProfileOptionsLoaded(false)
        setUserRequestsLoaded(false)
        setUserCaresLoaded(false)
        setContactsLoaded(false) */
        //setMediaLoaded(false)

    }



    const data = {
        login, register, logout,
        user, setUser,
        accountContact, accountProfile,
        openRatings, getOpenRatings,
        token, setToken,
        checkUser, getUserData, setUserData,
        dataLoaded, setDataLoaded,
        getProfiles, profiles,
        getProfileOptions, profileOptions,
        userRequests, setUserRequests, getUserRequests, availableRequests, maxRequests,
        userCares, setUserCares, getUserCares,
        contacts, contactTypes,
        caresBadgeSum, caresBadge, requestsBadge, messagesBadge, getMessagesBadge, setMessagesBadge,
    }

    return (
        <UserContext.Provider value={data}>
            {children}
        </UserContext.Provider>
    )
    
}