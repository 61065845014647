import React, { useEffect, useState, useContext } from 'react'
import { View, StyleSheet, Pressable } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../scripts/AppContext'


function RatingSelect({ name, state, setState }) {

    const { colors } = useContext(AppContext)

    const [value, setValue] = useState(0)
    const size = 36

    useEffect(() => {
        if(state[name]){
            setValue(state[name])
        }
    }, [])

    function selectValue(val){
        setValue(val)
        setState({...state, [name]: val})
    }

    const styles = StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 5,
        },
        star: {
            marginRight: 5,
        }
    })

    return (
        <View style={styles.container}>
            <Pressable onPress={() => selectValue(1)} style={styles.star}>
                <FontAwesomeIcon icon={faStar} size={size} color={value >= 1 ? colors.yellow : colors.borderGrey} />
            </Pressable>
            <Pressable onPress={() => selectValue(2)} style={styles.star}>
                <FontAwesomeIcon icon={faStar} size={size} color={value >= 2 ? colors.yellow : colors.borderGrey} />
            </Pressable>
            <Pressable onPress={() => selectValue(3)} style={styles.star}>
                <FontAwesomeIcon icon={faStar} size={size} color={value >= 3 ? colors.yellow : colors.borderGrey} />
            </Pressable>
            <Pressable onPress={() => selectValue(4)} style={styles.star}>
                <FontAwesomeIcon icon={faStar} size={size} color={value >= 4 ? colors.yellow : colors.borderGrey} />
            </Pressable>
            <Pressable onPress={() => selectValue(5)} style={styles.star}>
                <FontAwesomeIcon icon={faStar} size={size} color={value >= 5 ? colors.yellow : colors.borderGrey} />
            </Pressable>
        </View>
    )

}

export default RatingSelect