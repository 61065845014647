import * as Filesystem from 'expo-file-system'

export const displayFileSize = (bytes, si = true, dp = 1) => {

    const thresh = si ? 1000 : 1024
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10**dp
  
    do {
      bytes /= thresh
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
  
    if(isNaN(bytes)){
      return false
    }else{
      return bytes.toFixed(dp) + ' ' + units[u]
    }

}


export const base64MimeType = (encoded) => {
  
    let result = null

    if (typeof encoded !== 'string') {
      return result
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)

    if (mime && mime.length) {
      result = mime[1]
    }

    return result

}

export const capitalize = (string) => {
  if(typeof string === 'string'){
    return string.charAt(0).toUpperCase() + string.slice(1)
  }else{
    return string
  }
}


export const calculateSize = (target, width, height) => {

    let targetWidth
    let targetHeight
    let square = height === width ? true : false
    let portrait = height > width ? true : false

    if(square){
        targetWidth = targetHeight = target
    }else if(portrait){
        targetWidth = target / height * width
        targetHeight = target
    }else{
        targetWidth = target
        targetHeight = target / width * height
    }

    return { width: targetWidth, height: targetHeight}

}



export const getUri = async (media, token) => {
  
  let path = Filesystem.cacheDirectory + media.datei
  let cached = await Filesystem.getInfoAsync(path)
  if(cached.exists){

    Filesystem.getContentUriAsync(cached.uri).then(cUri => {
      //console.log('cache exists', cUri)
      return { uri: cUri }
    })

  }else{

    //console.log('cache does not exist', media.uri)
    return {
      uri: media.kategorie === 'video' ? media.thumbnail : media.uri,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }

  }

}


export const makeFullname = (user) => {
  
  let arr = []
  if(!empty(user.anrede)){
      arr.push(user.anrede)
  }
  if(!empty(user.titel)){
      arr.push(user.titel)
  }
  if(!empty(user.vorname)){
      arr.push(user.vorname)
  }
  if(!empty(user.nachname)){
      arr.push(user.nachname)
  }
  return arr.join(' ')

}

    
export const makeAnrede = (data, anrede = true) => {

  if(!data) return ''

  let arr = []
  if(anrede){
      if(data.anrede){
          arr.push(data.anrede)
      }
      if(data.titel){
        arr.push(data.titel)
      }
  }
  if(data.vorname){
    arr.push(data.vorname)
  }
  if(data.nachname){
    arr.push(data.nachname)
  }

  let name = arr.join(' ')  
  let anredetext = ''
  
  if(data.anrede === 'Herr'){
      anredetext = 'Sehr geehrter ' + name + ','
  }else if(data.anrede === 'Frau'){
      anredetext = 'Sehr geehrte ' + name + ','
  }else{
      anredetext = 'Hallo ' + name + ','
  }

  return anredetext

}

export const fieldsFilled = (inputs, needed) => {

  let errors = []

  needed.map(name => {
    if(!inputs[name] || inputs[name].length < 1){
      errors.push(name)
    }
  })

  return {
    success: errors.length ? false : true,
    errors: errors,
  }

}

export const isURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i') // fragment locator
  return !!pattern.test(str)
}

