import React, { useEffect, useState, useContext } from 'react'
import { View, ScrollView, Text } from 'react-native'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'
import Axios from 'axios'
import { authAxios } from '../../scripts/Axios'
import DocumentList from '../../components/DocumentList'
import { AppContext } from '../../scripts/AppContext'
import { UserContext } from '../../scripts/UserContext'
import Loader from '../../components/Loader'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { makeAnrede } from '../../scripts/Helpers'

function MessageScreen({ route, navigation }) {
    
    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { user, accountContact, accountProfile } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const [attachments, setAttachments] = useState([])

    const cancelSource = Axios.CancelToken.source()


    useEffect(() => {

        setLoading(true)

        authAxios.get('message/' + route.params.message_id, {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                let parsedMessage = {
                    ...response.data.message,
                    nachricht: response.data.message.nachricht.replace('{{anrede}}', makeAnrede(user.user_role === 2 ? accountContact : accountProfile))
                }
                setMessage(parsedMessage)
            }

        })
        .catch(error => {
            console.log('get messages error', error)
        })        

        authAxios.get('attachments/' + route.params.message_id, {cancelToken: cancelSource.token})
        .then(response => {

            setLoading(false)
            
            if(response.data.type === 'success'){
                setAttachments(response.data.uploads)
            }

        })
        .catch(error => {
            setLoading(false)
            console.log(error)
        })
        
        authAxios.put('message/' + route.params.message_id, {cancelToken: cancelSource.token})

        return () => cancelSource.cancel('get messages canceled')
    }, [])

    return (
        <View style={{flex:1}}>            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
                
                    
                <View style={[mainStyles.messageHeader, {marginHorizontal:-30, marginTop:-30, paddingHorizontal:30}]}>
                    <View style={mainStyles.screenContainer}>
                        <View style={mainStyles.formRow}>
                            <View style={mainStyles.formCol}>
                                <View style={{flexDirection:'row'}}>
                                    <Text>{t('messagesEmailBy')} </Text>
                                    <Text style={{color: colors.primary}}>Senihelp24</Text>
                                </View>
                                <Text>{message && moment(message.created_at).format('DD.MM.YYYY HH:mm')}</Text>
                            </View>
                        </View>
                        <View style={mainStyles.formRow}>
                            <View style={mainStyles.formCol}>
                                <Text style={mainStyles.messageHeaderSubject}>{message && message.betreff}</Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={[mainStyles.screenContainer, {paddingVertical: 30}]}>
                    {message ? <Text>{ReactHtmlParser(message.nachricht)}</Text> : null }
                </View>

                <View style={mainStyles.messageAttachments}>
                    <View style={mainStyles.screenContainer}>
                        <Text style={mainStyles.messageAttachmentsTitle}>{t('messagesEmailAttachments')}</Text>
                        <DocumentList data={attachments} editable={false} attachments={true} />
                    </View>
                </View>

            </ScrollView>
        </View>
    )

}

export default MessageScreen