import React, { useContext } from 'react'
import { View, Text, Pressable } from 'react-native'

import { UserContext } from '../scripts/UserContext'
import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'

function RequestButton({ onSendRequest, onPokeRequest, viewUser, requestView = false }) {

    const { colors, mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)
    const { user, availableRequests } = useContext(UserContext)

    return user.id !== viewUser.id &&
            !requestView &&
            user.konto === 4 &&
            (user.user_role === 1 ? !viewUser.isOrganizing : true) &&
            (user.user_role === 2 ? (!viewUser.isCaring && !viewUser.isOrganizing) : true)
        ? (
        <View>

            {/* viewUser.isCaring &&
            <Pressable style={{marginTop:20}}>
                <View style={[mainStyles.secondaryButton, {backgroundColor: colors.green}]}>
                    <Text style={mainStyles.secondaryButtonText}>{t('profileIsCaring')}</Text>
                </View>
            </Pressable>
             */}

            {availableRequests < 1 && !viewUser.isCaring &&
                <Pressable style={{marginTop:20}}>
                    <View style={mainStyles.secondaryButton}>
                        <Text style={{color: colors.white}}>{user.user_role === 1 ? t('profileRequestButtonDisabledBK') : t('profileRequestButtonDisabled')}</Text>
                    </View>
                </Pressable>
            }
            
            {user.user_role === 1 && availableRequests > 0 &&
                <Pressable onPress={onSendRequest} style={{marginTop:20}}>
                    <View style={viewUser.isRequested ? mainStyles.secondaryButton : mainStyles.primaryButton}>
                        { viewUser.isRequested ?
                            (
                            <Text style={mainStyles.secondaryButtonText}>{t('profileRequestButtonDoneBK')}</Text>
                            ) : (
                            <View>
                                <Text style={mainStyles.primaryButtonText}>{t('profileRequestButtonBK')}</Text>
                                <Text style={{color: colors.white}}>{availableRequests} {t('profileRequestButtonInfoBK')}</Text>
                            </View>
                            )
                        }
                    </View>
                </Pressable>
            }

            { user.user_role === 2 && availableRequests > 0 && !viewUser.isCaring &&
                <Pressable onPress={onPokeRequest} style={{marginTop:20}}>
                    <View style={viewUser.isRequested ? mainStyles.secondaryButton : mainStyles.primaryButton}>
                        { viewUser.isRequested ?
                            ( <Text style={mainStyles.secondaryButtonText}>{t('profileRequestButtonDone')}</Text> )
                            : ( <Text style={mainStyles.primaryButtonText}>{t('profileRequestButton')}</Text> )
                        }
                    </View>
                </Pressable>
            }

        </View>
    ) : null

}

export default RequestButton