import React, { useContext } from 'react'
import { View, ScrollView, Text, Pressable } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { UserContext } from '../../scripts/UserContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { AppContext } from '../../scripts/AppContext'

function ProfileIntroScreen({ navigation }) {

    const { colors, mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)
    const { user } = useContext(UserContext)

    function next(){
        if(user.user_role === 1){
            navigation.navigate('BK Stammdaten')
        }else{
            navigation.navigate('PB Kontakt Stammdaten')
        }
    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={mainStyles.screenContainer}>

                <Text style={mainStyles.title}>{t('profileIntroTitle')}</Text>
                <Text style={mainStyles.titleText}>{t('profileIntroText')}</Text>

                <View style={[mainStyles.infoBox, mainStyles.greenBG, {marginBottom: 20}]}>
                    <View style={{flexDirection:'row', justifyContent:'center'}}>
                        <FontAwesomeIcon icon={faCheckCircle} size={20} color={colors.white} style={{marginRight:10, marginTop:3}} />
                        <Text style={mainStyles.infoBoxText}>{t('profileIntroInfoBar')}</Text>
                    </View>
                </View>
                    
                { user.user_role === 1 ? (
                <View style={mainStyles.centeredContent}>
                    <Text style={mainStyles.titleText}>{t('profileIntroListBKTitle')}</Text>
                    <Text style={mainStyles.li}>• {t('profileIntroListBK1')}</Text>
                    <Text style={mainStyles.li}>• {t('profileIntroListBK2')}</Text>
                    <Text style={mainStyles.li}>• {t('profileIntroListBK3')}</Text>
                    <Text style={mainStyles.li}>• {t('profileIntroListBK4')}</Text>
                </View>
                ) : (
                <View style={mainStyles.centeredContent}>
                    <Text style={mainStyles.titleText}>{t('profileIntroListPBTitle')}</Text>
                    <Text style={mainStyles.li}>• {t('profileIntroListPB1')}</Text>
                    <Text style={mainStyles.li}>• {t('profileIntroListPB2')}</Text>
                    <Text style={mainStyles.li}>• {t('profileIntroListPB3')}</Text>
                    <Text style={mainStyles.li}>• {t('profileIntroListPB4')}</Text>
                    <Text style={mainStyles.li}>• {t('profileIntroListPB5')}</Text>
                    <Text style={mainStyles.li}>• {t('profileIntroListPB6')}</Text>
                </View>
                )}
                    
                <View style={mainStyles.buttonContainer}>
                    <Pressable onPress={() => next()} style={mainStyles.secondaryButton}>
                        <Text style={mainStyles.secondaryButtonText}>{t('profileIntroButton')}</Text>
                    </Pressable>
                </View>

            </View>

        </ScrollView>
    )

}

export default ProfileIntroScreen