import React, { useContext } from 'react'
import { View, ScrollView, Text } from 'react-native'
import { AppContext } from '../scripts/AppContext'
import { LocalizationContext } from '../scripts/LocalizationContext'

function DataPrivacyScreen() {

    const { mainStyles } = useContext(AppContext)
    const { locale } = useContext(LocalizationContext)

    function Gap(){
        return <Text></Text>
    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            { locale.startsWith('de') ? (
            <View style={[mainStyles.container, {marginBottom:50}]}>
                <Text style={mainStyles.title}>Datenschutzerklärung</Text>
                <Gap />
                <Text style={mainStyles.title3}>I. Allgemein</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und behandeln diese
                    vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
                    Datenschutzerklärung. Diese Datenschutzerklärung gilt für unsere mobilen iPhone- und Android-
                    Apps (im Folgenden „APP“). In ihr werden Art, Zweck und Umfang der Datenerhebung im
                    Rahmen der APP-Nutzung erläutert. Wir weisen darauf hin, dass die Datenübertragung im
                    Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
                    durch Dritte ist nicht möglich.
                </Text>
                <Gap />
                <Text style={mainStyles.text}>Verantwortliche Stelle für die Datenverarbeitung im Rahmen dieser App ist:</Text>
                <Gap />
                <Text style={mainStyles.text}>Senihelp Sp. z o.o. Sp. komandytowa</Text>
                <Text style={mainStyles.text}>Ul. Poznańska 21/48</Text>
                <Text style={mainStyles.text}>00-685 Warszawa</Text>
                <Text style={mainStyles.text}>Polen</Text>
                <Text style={mainStyles.text}>E-Mail: info@senihelp24.de, info@senihelp24.pl</Text>
                <Text style={mainStyles.text}>Webseite: www.senihelp24.de, www.senihelp24.pl</Text>
                <Text style={mainStyles.text}>Tel.: 0048 58 558 80-82</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    „Verantwortliche Stelle“ ist die Stelle, die personenbezogene Daten (z. B. Namen, E-Mail-Adressen etc.) 
                    erhebt, verarbeitet oder nutzt. Zugleich ist diese juristische Person auch der Datenschutzbeauftragte.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Allgemeine Speicherdauer personenbezogener Daten</Text>
                <Text style={mainStyles.text}>
                    Vorbehaltlich abweichender oder konkretisierender Angaben innerhalb dieser
                    Datenschutzerklärung werden die von dieser APP erhobenen personenbezogenen Daten
                    gespeichert, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen
                    oder der Zweck für die Datenspeicherung entfällt. Sofern eine gesetzliche Pflicht zur
                    Aufbewahrung oder ein sonstiger gesetzlich anerkannter Grund zur Speicherung der Daten (z. B.
                    berechtigtes Interesse) besteht, werden die betreffenden personenbezogenen Daten nicht vor
                    Wegfall des jeweiligen Aufbewahrungsgrundes gelöscht.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Rechtsgrundlagen zur Speicherung personenbezogener Daten</Text>
                <Text style={mainStyles.text}>
                    Die Verarbeitung personenbezogener Daten ist nur zulässig, wenn eine wirksame
                    Rechtsgrundlage für die Verarbeitung dieser Daten besteht. Sofern wir Ihre Daten verarbeiten,
                    geschieht dies regelmäßig auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO 
                    (z. B. bei freiwilliger Angabe Ihrer Daten in der Anmeldemaske, Angabe der persönlichen Pflegeparameter 
                    der zu betreuenden Person/ne, oder im Rahmen des Kontaktformulars), zum Zwecke der Vertragserfüllung 
                    nach Art. 6 Abs. 1 lit. b DSGVO (z. B. bei Nutzung von In-APP-Käufen, oder der Nutzung sonstiger 
                    kostenpflichtiger APP-Funktionen) oder aufgrund berechtigter Interessen nach Art. 6 Abs. 1 lit. f DSGVO,
                    die stets mit Ihren Interessen abgewogen werden (z. B. im Rahmen von Werbemaßnahmen). Die jeweils 
                    einschlägigen Rechtsgrundlagen werden ggf. an gesonderter Stelle im Rahmen dieser Datenschutzerklärung konkretisiert.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Verschlüsselung</Text>
                <Text style={mainStyles.text}>
                    Diese APP nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher
                    Inhalte, wie zum Beispiel der Anfragen, die Sie an uns als APP-Betreiber senden, oder der
                    Kommunikation der APP-Nutzer untereinander, eine Verschlüsselung. Durch diese
                    Verschlüsselung wird verhindert, dass die Daten, die Sie übermitteln, von unbefugten Dritten
                    mitgelesen werden können.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Änderung dieser Datenschutzerklärung</Text>
                <Text style={mainStyles.text}>
                    Wir behalten uns das Recht vor, diese Datenschutzbestimmungen unter Einhaltung der gesetzlichen Vorgaben jederzeit zu ändern.
                </Text>
                <Gap />
                <Text style={mainStyles.title3}>II. Ihre Rechte</Text>
                <Gap />
                <Text style={mainStyles.text}>Die DSGVO gewährt Betroffenen, deren personenbezogene Daten von uns verarbeitet werden, bestimmte Rechte, über die wir Sie an dieser Stelle aufklären möchten:</Text>
                <Gap />
                <Text style={mainStyles.label}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</Text>
                <Text style={mainStyles.text}>
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer Einwilligung möglich. Diese werden wir vor
                    Beginn der Datenverarbeitung ausdrücklich bei Ihnen einholen. Sie können diese Einwilligung
                    jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
                    der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                </Text>
                <Gap />
                <Text style={mainStyles.text}>
                    WIDERSPRUCHSRECHT GEGEN DIE DATENERHEBUNG IN BESONDEREN FÄLLEN
                    SOWIE GEGEN DIREKTWERBUNG (ART. 21 DSGVO)
                    WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
                    DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
                    IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG SIE
                    BETREFFENDER PERSONENBEZOGENER DATEN WIDERSPRUCH EINZULEGEN; DIES
                    GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE
                    JEWEILIGE RECHTSGRUNDLAGEN, AUF DENEN EINE VERARBEITUNG BERUHT,
                    ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
                    EINLEGEN, WERDEN WIR DIE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
                    MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
                    GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
                    FREIHEITEN ÜBERWIEGEN ODER DIE DER VERARBEITUNG DER GELTENDMACHUNG,
                    AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENEN.
                    WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
                    BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
                    VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
                    DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
                    ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                    WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND
                    NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Beschwerderecht bei einer Aufsichtsbehörde</Text>
                <Text style={mainStyles.text}>
                    Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
                    Aufsichtsbehörde zu. Das Beschwerderecht besteht unbeschadet anderweitiger
                    verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Auskunft, Löschung und Berichtigung</Text>
                <Text style={mainStyles.text}>
                    Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
                    personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
                    Datenverarbeitung sowie ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
                    zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im
                    Impressum angegebenen Adresse an uns wenden.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Recht auf Einschränkung der Verarbeitung</Text>
                <Text style={mainStyles.text}>
                    Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                    verlangen. Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an
                    uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
                </Text>
                <Gap />
                <Text style={mainStyles.text}>
                    • Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
                    Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                </Text>
                <Text style={mainStyles.text}>
                    • Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah / geschieht, können
                    Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
                </Text>
                <Text style={mainStyles.text}>
                    • Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                    Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt
                    der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                </Text>
                <Text style={mainStyles.text}>
                    • Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung
                    zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht,
                    wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen.
                </Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese
                    Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                    Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
                    natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses
                    der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Recht auf Datenübertragbarkeit</Text>
                <Text style={mainStyles.text}>
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
                    Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
                    maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
                    an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                </Text>
                <Gap />
                <Text style={mainStyles.title3}>III. Zugriffsrechte der APP</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Zur Bereitstellung unserer Dienste über die APP benötigen wir die im Folgenden aufgezählten
                    Zugriffsrechte, die es uns ermöglichen, auf bestimmte Funktionen Ihres Geräts zuzugreifen:
                </Text>
                <Gap />
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Standortdaten: Nein</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Gerätenummer Ihres Smartphones: Nein</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Kontakte Ihrer Kontaktliste: Nein</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Fotos, Videos: JA</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Anruflisten: Nein</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Kamera: JA</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Mikrofon: Nein</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Speicher: JA</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Der Zugriff auf die Gerätefunktionen ist erforderlich, um die Funktionalitäten der APP zu
                    gewährleisten. Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes Interesse im
                    Sinne von Art. 6 Abs. 1 lit. f DSGVO, Ihre Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO
                    und/oder – sofern ein Vertrag geschlossen wurde – die Erfüllung unserer vertraglichen
                    Verpflichtungen (Art. 6 Abs. 1 lit. b DSGVO).
                </Text>
                <Gap />
                <Text style={mainStyles.title3}>IV. Erfassung personenbezogener Daten im Rahmen der APP-Nutzung</Text>
                <Gap />
                <Text style={mainStyles.label}>Allgemein</Text>
                <Text style={mainStyles.text}>Wenn Sie unsere APP nutzen, erfassen wir folgende personenbezogene Daten von Ihnen:</Text>
                <Gap />
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Vor- und Nachnamen</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Adressdaten</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>E-Mail-Adressen</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Telefonnummern</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Gesundheitsdaten</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Nutzungsdaten</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Gerätekennung</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Metadaten</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Personenbezogene Daten und Medien werden innerhalb der App und Website im geschlossenen Bereich und dort weitestgehend 
                    anonymisiert veröffentlicht, wobei der Zugang zu diesem internen Bereich nur für solche User möglich ist, die sich 
                    ebenfalls mit dieser Datenschutzerklärung einverstanden erklärt haben, die AGB's bestätigt haben, und darüber hinaus 
                    der Verschwiegenheitsverpflichtung alles User unterliegen.  Die Verarbeitung dieser personenbezogenen Daten ist erforderlich, 
                    um die Funktionalitäten der APP zu gewährleisten. Rechtsgrundlage für diese Datenverarbeitung ist unser berechtigtes 
                    Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO, Ihre Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO und/oder – sofern 
                    ein Vertrag geschlossen wurde – die Erfüllung unserer vertraglichen Verpflichtungen (Art. 6 Abs. 1 lit. b DSGVO).
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Anfrage innerhalb der APP, per E-Mail, Telefon oder Telefax</Text>
                <Text style={mainStyles.text}>
                    Wenn Sie uns kontaktieren (z. B. via Kontaktformular innerhalb der App, per E-Mail, Telefon oder
                    Telefax), wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
                    (z. B. Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und
                    verarbeitet. Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO,
                    sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung
                    vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
                    Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder auf unseren berechtigten Interessen (Art.
                    6 Abs. 1 lit. f DSGVO), da wir ein berechtigtes Interesse an der effektiven Bearbeitung der an uns 
                    gerichteten Anfragen haben. Dazu beschreiben die AGB Genaueres und sind verpflichtend anzuerkennen. 
                    Die von Ihnen an uns per Kontaktanfrage übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung 
                    auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung 
                    entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche 
                    Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt. Wir geben 
                    Ihre Daten nicht ohne Ihre Einwilligung weiter.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Kommentarfunktion in dieser APP</Text>
                <Text style={mainStyles.text}>
                    Für die Kommentarfunktion in dieser APP werden neben Ihrem Kommentar auch Angaben zum
                    Zeitpunkt der Erstellung des Kommentars und, wenn Sie nicht anonym posten, der von Ihnen
                    gewählte Nutzername gespeichert. Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer
                    Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die Kommentare und die damit verbundenen Daten
                    werden gespeichert und verbleiben im Kommentarbereich und auf unseren Servern, bis der
                    kommentierte Inhalt vollständig gelöscht wurde.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Newsletter Daten</Text>
                <Text style={mainStyles.text}>
                    Wenn Sie den in unserer APP angebotenen Newsletter beziehen möchten, benötigen wir von
                    Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie
                    der Inhaber der angegebenen E-Mail-Adresse und mit dem Empfang des Newsletters
                    einverstanden sind. Weitere Daten werden nicht erhoben. Diese Daten verwenden wir
                    ausschließlich für den Versand der angeforderten Informationen und geben sie nicht an Dritte
                    weiter. Der Newsletterversand geschieht auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
                    DSGVO). Diese Einwilligung können Sie jederzeit widerrufen. Die von Ihnen zum Zwecke des
                    Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem
                    Newsletter gespeichert und nach der Austragung gelöscht.
                </Text>
                <Gap />
                <Text style={mainStyles.title3}>V. Datenanalyse</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Wenn Sie auf unsere APP zugreifen, kann Ihr Verhalten mit Hilfe bestimmter Analyse-Werkzeuge
                    statistisch ausgewertet und zu Werbe- und Markforschungszwecken oder zur Verbesserung
                    unserer Angebote analysiert werden. Bei der Verwendung derartiger Tools achten wir auf die
                    Einhaltung der gesetzlichen Datenschutzbestimmungen. Beim Einsatz externer Dienstleister
                    (Auftragsverarbeiter) stellen wir durch entsprechende Verträge mit den Dienstleistern sicher, dass
                    die Datenverarbeitung den deutschen und europäischen Datenschutzstandards entspricht.
                </Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Wir nutzen Google Analytics zur Analyse des Nutzerverhaltens. Anbieter ist die Google Inc., 
                    1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
                </Text>
            </View>
            ):( // ===============================================================================================================================================================================================================
            <View style={[mainStyles.container, {marginBottom:50}]}>
                <Text style={mainStyles.title}>Polityka prywatności</Text>
                <Gap />
                <Text style={mainStyles.title3}>I. Informacje ogólne</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Bardzo poważnie podchodzimy do ochrony Państwa danych osobowych i traktujemy takie dane poufnie, przestrzegając ustawowych przepisów o ochronie danych oraz niniejszej Polityki Prywatności. Niniejsza Polityka Prywatności dotyczy naszych aplikacji mobilnych na iPhone'a i Androida (zwanych dalej „Aplikacjami”). Zawiera ona informacje dotyczące charakteru, celu i zakresu gromadzenia danych w ramach korzystania z Aplikacji. Zwracamy uwagę, że transmisja danych za pośrednictwem Internetu może posiadać luki w zabezpieczeniach. Pełna ochrona danych przed dostępem osób trzecich nie jest możliwa.
                </Text>
                <Gap />
                <Text style={mainStyles.text}>Podmiotem odpowiedzialnym za przetwarzanie danych w ramach Aplikacji jest:</Text>
                <Gap />
                <Text style={mainStyles.text}>Senihelp Sp. z o.o. Sp. komandytowa</Text>
                <Text style={mainStyles.text}>Ul. Poznańska 21/48</Text>
                <Text style={mainStyles.text}>00-685 Warszawa</Text>
                <Text style={mainStyles.text}>Polska</Text>
                <Text style={mainStyles.text}>E-Mail: info@senihelp24.de, info@senihelp24.pl</Text>
                <Text style={mainStyles.text}>Webseite: www.senihelp24.de, www.senihelp24.pl</Text>
                <Text style={mainStyles.text}>Tel.: 0048 58 558 80-82</Text>
                <Gap />
                <Text style={mainStyles.text}>„Administrator danych osobowych” jest to podmiot, który gromadzi, przetwarza lub wykorzystuje dane osobowe (np. nazwiska, adresy e-mail itp.). Jednocześnie ta osoba prawna jest również podmiotem odpowiedzialnym za ochronę danych osobowych.</Text>
                <Gap />
                <Text style={mainStyles.label}>Ogólny okres przechowywania danych osobowych</Text>
                <Text style={mainStyles.text}>
                    O ile w niniejszej Polityce Prywatności nie stwierdzono lub nie określono inaczej, dane osobowe gromadzone przez niniejszą Aplikację będą przechowywane do czasu, aż zażądają Państwo ich usunięcia, cofną zgodę na ich przechowywanie lub cel przechowywania danych stanie się nieaktualny. Jeżeli istnieje prawny obowiązek zachowania danych lub inny prawnie uzasadniony powód do przechowywania danych (np. uzasadniony interes), wówczas dane osobowe nie zostaną usunięte aż do momentu ustania powodu ich przechowywania.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Podstawa prawna przechowywania danych osobowych</Text>
                <Text style={mainStyles.text}>
                    Przetwarzanie danych osobowych jest dozwolone tylko wtedy, gdy istnieje ważna podstawa prawna do przetwarzania tych danych. Jeśli przetwarzamy Państwa dane, odbywa się to zwykle na podstawie Państwa zgody, o której mowa w art. 6 ust. 1 lit. a RODO (np. w przypadku dobrowolnego wprowadzenia przez Państwa danych do formularza rejestracyjnego, podania osobistych parametrów opieki nad osobą trzecią/osobami trzecimi, lub w ramach formularza kontaktowego), w celu realizacji umowy zgodnie z art. 6 ust. 1 lit. b RODO (np. podczas korzystania z zakupów w ramach Aplikacji lub korzystania z innych płatnych funkcji Aplikacji) lub na podstawie uzasadnionego interesu zgodnie z art. 6 ust. 1 lit. f RODO, który zawsze jest ważony z Państwa interesem (np. w ramach działań reklamowych). Odnośna podstawa prawna jest odpowiednio skonkretyzowana w innej części niniejszej Polityki Prywatności.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Szyfrowanie</Text>
                <Text style={mainStyles.text}>
                    Ze względów bezpieczeństwa i w celu ochrony transmisji poufnych treści, takich jak zapytania wysyłane przez Państwa do nas jako operatorów Aplikacji, a także w ceku ochrony komunikacji pomiędzy użytkownikami Aplikacji, wykorzystywane jest szyfrowanie. Szyfrowanie zapobiega odczytywaniu danych przez nieupoważnione osoby trzecie.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Zmiany w niniejszej Polityce Prywatności</Text>
                <Text style={mainStyles.text}>
                    Zastrzegamy sobie prawo do zmiany niniejszej Polityki Prywatności w dowolnym momencie z zachowaniem przepisów prawnych.
                </Text>
                <Gap />
                <Text style={mainStyles.title3}>II. Państwa prawa</Text>
                <Gap />
                <Text style={mainStyles.text}>RODO przyznaje osobom, których dane osobowe są przez nas przetwarzane, określone prawa, które poniżej Państwu przedstawiamy:</Text>
                <Gap />
                <Text style={mainStyles.label}>Prawo do wycofania zgody na przetwarzanie danych</Text>
                <Text style={mainStyles.text}>
                    Wiele operacji przetwarzania danych jest możliwych tylko za Państwa zgodą. Zwrócimy się do Państwa o udzielenie takiej zgody przed rozpoczęciem przetwarzania Państwa danych. Zgodę tę mogą Państwo odwołać w dowolnym momencie. W tym celu wystarczy przesłać nam nieformalne  powiadomienie e-mailem. Legalność operacji przetwarzania danych dokonanych do momentu odwołania zgody pozostaje nienaruszona.
                </Text>
                <Gap />
                <Text style={mainStyles.text}>
                    PRAWO DO SPRZECIWU WOBEC GROMADZENIA DANYCH W SZCZEGÓLNYCH PRZYPADKACH ORAZ WOBEC MARKETINGU BEZPOŚREDNIEGO (ART. 21 RODO). 
                    JEŚLI PRZETWARZANIE DANYCH ODBYWA SIĘ NA PODSTAWIE ART. 6 UST. 1 LIT. E LUB F RODO, MAJĄ PAŃSTWO W KAŻDYM CZASIE PRAWO DO WNIESIENIA SPRZECIWU WOBEC PRZETWARZANIA PAŃSTWA DANYCH OSOBOWYCH Z PRZYCZYN ZWIĄZANYCH Z PAŃSTWA INDYWIDUALNĄ SYTUACJĄ; DOTYCZY TO RÓWNIEŻ PROFILOWANIA NA PODSTAWIE NINIEJSZYCH PRZEPISÓW. ODPOWIEDNIE PODSTAWY PRAWNE, NA KTÓRYCH OPIERA SIĘ PRZETWARZANIE DANYCH, MOŻNA ZNALEŹĆ W NINIEJSZEJ POLITYCE PRYWATNOŚCI. W PRZYPADKU WNIESIENIA SPRZECIWU NIE BĘDZIEMY DŁUŻEJ PRZETWARZAĆ ODNOŚNYCH DANYCH OSOBOWYCH, CHYBA ŻE MOŻEMY WYKAZAĆ ISTOTNĄ PODSTAWĘ PRZETWARZANIA, KTÓRA JEST NADRZĘDNA WOBEC PAŃSTWA INTERESÓW, PRAW I WOLNOŚCI, LUB KTÓRA UZASADNIA PRZETWARZANIE W CELU DOCHODZENIA, REALIZACJI LUB OBRONY ROSZCZEŃ PRAWNYCH.
                    JEŻELI PAŃSTWA DANE OSOBOWE SĄ PRZETWARZANE W CELACH MARKETINGU BEZPOŚREDNIEGO, MAJĄ PAŃSTWO PRAWO DO WNIESIENIA W DOWOLNYM MOMENCIE SPRZECIWU WOBEC PRZETWARZANIA PAŃSTWA DANYCH OSOBOWYCH W TYCH CELACH; DOTYCZY TO RÓWNIEŻ PROFILOWANIA, O ILE JEST ONO ZWIĄZANE Z TAKIM MARKETINGIEM BEZPOŚREDNIM. JEŚLI WNIOSĄ PAŃSTWO SPRZECIW, PAŃSTWA DANE OSOBOWE NIE BĘDĄ JUŻ WYKORZYSTYWANE DO CELÓW MARKETINGU BEZPOŚREDNIEGO.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Prawo do złożenia skargi do organu nadrzędnego</Text>
                <Text style={mainStyles.text}>
                    W przypadku naruszenia RODO, osoby poszkodowane mają prawo do złożenia skargi do organu nadrzędnego. Prawo do skargi przysługuje niezależnie od innych administracyjnych lub sądowych środków odwoławczych.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Prawo do uzyskania informacji, usunięcia i poprawienia danych</Text>
                <Text style={mainStyles.text}>
                    W każdej chwili mają Państwo prawo do bezpłatnej informacji o Państwa danych osobowych, ich pochodzeniu i odbiorcy oraz o celu ich przetwarzania, jak również prawo do żądania poprawienia lub usunięcia tych danych. W tej sprawie oraz w przypadku innych pytań dotyczących danych osobowych, mogą Państwo w każdej chwili skontaktować się z nami pod adresem wskazanym w danych firmy.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Prawo do ograniczenia przetwarzania danych</Text>
                <Text style={mainStyles.text}>
                    Mają Państwo prawo zażądać ograniczenia przetwarzania Państwa danych osobowych. W tym celu mogą Państwo w każdej chwili skontaktować się z nami pod adresem wskazanym w danych firmy. Prawo do ograniczenia przetwarzania danych przysługuje w następujących przypadkach:
                </Text>
                <Gap />
                <Text style={mainStyles.text}>
                    • Jeśli kwestionują Państwo prawidłowość przechowywanych przez nas danych osobowych, zazwyczaj potrzebujemy czasu, aby to sprawdzić. W czasie trwania tego badania mają Państwo prawo zażądać ograniczenia przetwarzania Państwa danych osobowych.
                </Text>
                <Text style={mainStyles.text}>
                    • Jeżeli przetwarzanie Państwa danych osobowych odbywało się/odbywa się niezgodnie z prawem, mogą Państwo zamiast usunięcia danych zażądać ograniczenia ich przetwarzania.
                </Text>
                <Text style={mainStyles.text}>
                    • Jeżeli Państwa dane osobowe nie są nam już potrzebne, ale potrzebują ich Państwo do realizacji, obrony lub dochodzenia roszczeń prawnych, mogą Państwo zażądać ograniczenia przetwarzania Państwa danych osobowych zamiast ich usunięcia.
                </Text>
                <Text style={mainStyles.text}>
                    • Jeśli złożyli Państwo sprzeciw zgodnie z art. 21 ust. 1 RODO, należy wyważyć pomiędzy Państwa interesami a naszymi. Dopóki nie zostało ustalone, czyje interesy przeważają, mogą Państwo zażądać ograniczenia przetwarzania Państwa danych osobowych.
                </Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Jeżeli ograniczyli Państwo przetwarzanie swoich danych osobowych, dane te mogą być (poza ich przechowywaniem) przetwarzane wyłącznie za Państwa zgodą lub w celu dochodzenia, realizacji lub obrony roszczeń prawnych lub dla ochrony praw innej osoby fizycznej lub prawnej lub ze względu na ważny interes publiczny Unii Europejskiej lub państwa członkowskiego.  
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Prawo do przenoszenia danych</Text>
                <Text style={mainStyles.text}>
                    Mogą Państwo zażądać, abyśmy przekazali dane, które przetwarzamy automatycznie na podstawie Państwa zgody lub w ramach realizacji umowy, Państwu lub osobie trzeciej, w powszechnie stosowanym formacie nadającym się do odczytu maszynowego. Jeśli zażądają Państwo przekazania danych bezpośrednio do innego administratora, nastąpi to tylko w takim zakresie, w jakim jest to technicznie wykonalne.
                </Text>
                <Gap />
                <Text style={mainStyles.title3}>III. Prawa dostępu Aplikacji</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    W celu świadczenia naszych usług za pośrednictwem Aplikacji, potrzebujemy wymienionych poniżej praw dostępu, które umożliwiają nam korzystanie z niektórych funkcji Państwa urządzenia:
                </Text>
                <Gap />
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Dane dotyczące lokalizacji: Nie</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Numer sprzętowy Państwa smartfona: Nie</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Kontakty na liście kontaktów: Nie</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Zdjęcia, filmy: TAK</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Listy połączeń: Nie</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Aparat fotograficzny: TAK</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Mikrofon: Nie</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>Pamięć: TAK</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Dostęp do funkcji urządzenia jest niezbędny w celu zapewnienia funkcjonalności Aplikacji. Podstawą prawną tego przetwarzania danych jest nasz uzasadniony interes w rozumieniu art. 6 ust. 1 lit. f RODO, Państwa zgoda w rozumieniu art. 6 ust. 1 lit. a RODO i/lub - o ile została zawarta umowa - wypełnienie naszych zobowiązań umownych (art. 6 ust. 1 lit. b RODO).
                </Text>
                <Gap />
                <Text style={mainStyles.title3}>IV. Gromadzenie danych osobowych w ramach korzystania z Aplikacji</Text>
                <Gap />
                <Text style={mainStyles.label}>Informacje ogólne</Text>
                <Text style={mainStyles.text}>Gdy korzystają Państwo z naszej Aplikacji, zbieramy od Państwa następujące dane osobowe:</Text>
                <Gap />
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>imię i nazwisko</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>dane adresowe</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>adresy e-mail</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>numery telefonów</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>dane dotyczące zdrowia</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>dane dotyczące użytkowania</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>dane identyfikacyjne urządzenia</Text>
                <Text style={[mainStyles.li, {marginTop: 0, marginBottom: 0}]}>metadane</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Dane osobowe i media są publikowane w aplikacji i na stronie internetowej w obszarze zamkniętym oraz w miarę możliwości anonimowo, przy czym dostęp do tego wewnętrznego obszaru jest możliwy tylko dla tych użytkowników, którzy również zaakceptowali niniejszą Politykę Prywatności, potwierdzili Ogólne Warunki Handlowe i podlegają obowiązkowi zachowania poufności. Przetwarzanie tych danych osobowych jest niezbędne do zapewnienia funkcjonalności Aplikacji. Podstawą prawną przetwarzania danych jest w tym wypadku nasz uzasadniony interes w rozumieniu art. 6 ust. 1 lit. f RODO, Państwa zgoda w rozumieniu art. 6 ust. 1 lit. a RODO i/lub - w przypadku zawarcia umowy - wypełnienie naszych zobowiązań umownych (art. 6 ust. 1 lit. b RODO).
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Zapytanie w ramach Aplikacji, drogą elektroniczną, telefoniczną lub faksem</Text>
                <Text style={mainStyles.text}>
                    W przypadku kontaktu z nami (np. przez formularz kontaktowy w aplikacji, pocztą elektroniczną, telefonicznie lub faksem), Państwa zapytanie wraz ze wszystkimi wynikającymi z niego danymi osobowymi (np. nazwisko, zapytanie) będzie przez nas przechowywane i przetwarzane w celu realizacji Państwa zapytania. Przetwarzanie tych danych odbywa się na podstawie art. 6 ust. 1 lit. b RODO, o ile Państwa zapytanie jest związane z realizacją umowy lub jest konieczne do realizacji działań przedumownych. We wszystkich innych przypadkach przetwarzanie danych opiera się na Państwa zgodzie (art. 6 ust. 1 lit. a RODO) i/lub na naszym uzasadnionym interesie (art. 6 ust. 1 lit. f RODO), ponieważ mamy uzasadniony interes w sprawnej obsłudze zapytań, które są do nas kierowane. Więcej informacji na ten temat znajduje się w Ogólnych Warunkach Handlowych, które należy zaakceptować jako wiążące. Dane przesyłane nam w ramach zapytania przez formularz kontaktowy pozostają u nas do momentu, aż zażądają Państwo ich usunięcia, cofną zgodę na ich przechowywanie lub cel przechowywania danych przestanie być aktualny (np. po zrealizowaniu Państwa zapytania). Bezwzględnie obowiązujące przepisy ustawowe - w szczególności dotyczące ustawowych okresów przechowywania danych - pozostają nienaruszone. Nie przekazujemy Państwa danych bez Państwa zgody.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Funkcja komentarza w Aplikacji</Text>
                <Text style={mainStyles.text}>
                    W przypadku funkcji komentarza w Aplikacji, oprócz samego komentarza przechowywane są także informacje dotyczące czasu utworzenia komentarza oraz, jeśli nie publikują go Państwo anonimowo, wybrana przez Państwa nazwa użytkownika. Przechowywanie komentarzy jest oparte na Państwa zgodzie (Art. 6 ust. 1 lit. a RODO). Komentarze i związane z nimi dane będą przechowywane i pozostaną w dziale komentarzy oraz na naszych serwerach do czasu, aż komentowana treść zostanie całkowicie usunięta.
                </Text>
                <Gap />
                <Text style={mainStyles.label}>Dane newslettera</Text>
                <Text style={mainStyles.text}>
                    Aby zapisać się do newslettera oferowanego w naszej Aplikacji, muszą nam Państwo podać adres e-mail, jak również informacje, które pozwolą nam zweryfikować, że są Państwo właścicielem podanego adresu e-mail oraz że wyrażają Państwo zgodę na otrzymywanie newslettera. Inne dane nie będą gromadzone. Używamy tych danych wyłącznie w celu przesyłania zamówionych informacji i nie przekazujemy ich osobom trzecim. Newsletter jest wysyłany na podstawie Państwa zgody (art. 6 ust. 1 lit. a RODO). Zgodę tę można odwołać w dowolnym momencie. Dane, które podali Państwo w celu zasubskrybowania newslettera, będą przez nas przechowywane do momentu wypisania się z newslettera, a następnie zostaną usunięte.
                </Text>
                <Gap />
                <Text style={mainStyles.title3}>V. Analiza danych</Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Gdy używają Państwo naszej Aplikacji, Państwa zachowanie może być badane statystycznie za pomocą określonych narzędzi analitycznych oraz analizowane do celów reklamy i badania rynku lub w celu ulepszenia naszej oferty. Podczas korzystania z tych narzędzi zwracamy uwagę na przestrzeganie ustawowych przepisów o ochronie danych. W przypadku korzystania z usług zewnętrznych usługodawców (podmiotów przetwarzających dane na zlecenie) zapewniamy poprzez odpowiednie umowy z nimi, że przetwarzanie danych będzie realizowane zgodnie z niemieckimi i europejskimi standardami ochrony danych.
                </Text>
                <Gap />
                <Text style={mainStyles.text}>
                    Używamy Google Analytics do analizy zachowań użytkowników. Dostawcą jest Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
                </Text>
            </View>
            )}

        </ScrollView>
    )

}

export default DataPrivacyScreen