import React, { useState, useEffect, useContext } from 'react'
import { View, ScrollView, Text, RefreshControl, Platform } from 'react-native'
import Accordion from 'react-native-collapsible/Accordion'
import Axios from 'axios'
import { LocalizationContext } from '../../scripts/LocalizationContext'

import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { authAxios } from '../../scripts/Axios'
import UserPreview from '../../components/UserPreview'
import RequestStatus from '../../components/RequestStatus'

function CareRequestScreen({ route, navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { user, userRequests, availableRequests, maxRequests } = useContext(UserContext)

    const [refreshing, setRefreshing] = useState(false)
    const [requests, setRequests] = useState(userRequests)
    const [activeSections, setActiveSections] = useState([])

    /* navigation.addListener('focus', () => {
        getRequests()
    }) */

    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {
        getRequests()
        return () => cancelSource.cancel('get requests canceled')
    }, [])

    useEffect(() => {
        setRequests(userRequests)
    }, [userRequests])

    const getRequests = () => {

        setRefreshing(true)

        authAxios.get('requests', {cancelToken: cancelSource.token})
        .then(response => {

            setRefreshing(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setRequests(response.data.requests)
            }

        })
        .catch(error => console.log(error))

    }


    function renderHeader(section){
        return (
            <View style={mainStyles.accordionHeader}>
                <Text style={mainStyles.accordionTitle}>{section.title}</Text>
            </View>
        )
    }

    function renderContent(section){
        let sectionRequests = requests.filter(r => r.status !== 0)
        return (
            <View style={{backgroundColor: colors.lightGrey}}>
                {sectionRequests.length < 1 ? <Text style={[mainStyles.lightText, {marginVertical:15}]}>{t('careRequestsNoRequests')}</Text> : null }
                {sectionRequests.map((request, index) => {
                    return (
                        <View key={index} style={mainStyles.profileListItem}>
                            <View style={mainStyles.profileListItemInner}>
                                <RequestStatus request={request} />
                                <UserPreview type="request" viewUser={request.user} request={request} navigation={navigation} />
                            </View>
                        </View>
                    )
                })}
            </View>
        )
    }

    function updateSections(activeSections){
        setActiveSections(activeSections)
    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer} refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={getRequests} tintColor={colors.black} />
        }>

            <View style={[mainStyles.screenContainer, { paddingHorizontal: Platform.OS === 'web' ? 20 : 0}]}>

                { Platform.OS !== 'web' ? <Text style={[mainStyles.refreshText, {marginBottom:20}]}>{t('refreshInfo')}</Text> : null }

                <View style={{marginBottom:50}}>

                    { user.user_role === 1 ? (
                    <View style={mainStyles.container}>
                        <Text style={mainStyles.title2}>{t('careRequestsRequestCount')}: {availableRequests}/{maxRequests}</Text>
                    </View>
                    ) : (
                    <View style={mainStyles.container}>
                        <Text style={mainStyles.title2}>{t('careRequestsPokeCount')}: {availableRequests}/{maxRequests}</Text>
                    </View>
                    )}

                    {requests.filter(r => r.status === 0).length < 1 ? <Text style={[mainStyles.lightText, {marginVertical:15}]}>{t('careRequestsNoRequests')}</Text> : null }
                    {requests.filter(r => r.status === 0).map((request, index) => {
                        return (
                            <View key={index} style={mainStyles.profileListItem}>
                                <View style={mainStyles.profileListItemInner}>
                                    <RequestStatus request={request} />
                                    <UserPreview type="request" viewUser={request.user} request={request} navigation={navigation} />
                                </View>
                            </View>
                        )
                    })}

                    <Accordion activeSections={activeSections} sections={[
                        { title: t('careRequestsOld') },
                    ]}
                    expandMultiple
                    underlayColor={colors.lightGrey}
                    renderHeader={renderHeader}
                    renderContent={renderContent}
                    onChange={updateSections} />

                </View>

            </View>

        </ScrollView>
    )

}

export default CareRequestScreen