import React, { useContext, useEffect, useState } from 'react'
import { View, ScrollView, Text, Pressable } from 'react-native'
import moment from 'moment'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faMapMarkerAlt, faCaretRight, faWheelchair, faWallet } from '@fortawesome/free-solid-svg-icons'

import { authAxios } from '../../scripts/Axios'
import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

import Rating from '../../components/Rating'
import Loader from '../../components/Loader'
import Carousel from '../../components/Carousel'
import ProfileTable from '../../components/ProfileTable'
import RequestStatus from '../../components/RequestStatus'
import MatchingCircle from '../../components/MatchingCircle'
import Badge from '../../components/Badge'
import RequestButton from '../../components/RequestButton'
import UserStatus from '../../components/UserStatus'

function ProfileScreen({ route, navigation }) {

    const { t, locale } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { user, profileOptions, getUserCares, getUserRequests } = useContext(UserContext)

    const [loading, setLoading] = useState(true)
    const [viewUser, setViewUser] = useState(null)
    const [activeProfile, setActiveProfile] = useState(0)

    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {

        setLoading(true)

        authAxios.get('user/' + route.params.user_id, {cancelToken: cancelSource.token})
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setViewUser(response.data.user)
            }

        })
        .catch(error => {
            console.log('get user error', error)
        })

        return () => cancelSource.cancel('get view-user canceled')

    }, [route.params.user_id])


    
    function handleSendRequest(){
        if(viewUser.isRequested){ return } // test, muss wieder rein, oder?
        showAlert(
            t('alertSendRequestTitle'),
            t('alertSendRequest'),
            [
                { text: t('cancel'), style: 'cancel' },
                { text: t('alertSendRequestTitle'), onPress: () => sendRequest() },
            ],
        )
    }

    function handlePokeRequest(){
        if(viewUser.isRequested){ return } // test, muss wieder rein, oder?
        showAlert(
            t('alertSendPokeTitle'),
            t('alertSendPoke'),
            [
                { text: t('cancel'), style: 'cancel' },
                { text: 'Anstupsen', onPress: () => sendPoke() },
            ],
        )
    }

    function sendRequest(){
        
        authAxios.post('request/' + route.params.user_id, {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(response.data.message)
            }else{
                showAlert(t('alertRequestSentTitle'), t('alertRequestSent'))
            }

        })
        .catch(error => {
            console.log(error.response)
        })
        .then(() => {
            getUserRequests()
        })

    }

    function sendPoke(){
        
        authAxios.post('poke/' + route.params.user_id, {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(response.data.message)
            }else{
                showAlert(t('alertPokeSentTitle'), t('alertPokeSent'))
            }

        })
        .catch(error => {
            console.log(error.response)
        })
        .then(() => {
            getUserRequests()
        })

    }


    function requestResponse(action){

        let req = route.params.request 
        if(action === 'decline'){

            showAlert(t('alertDeclineRequestTitle'), t('alertDeclineRequest'), [
                { text: t('cancel'), style: 'cancel' },
                { text: t('decline'), style: 'destructive', onPress: () => declineRequest(req.id) },
            ])

        }else{

            showAlert(t('alertAcceptRequestTitle'), t('alertAcceptRequest'), [
                { text: t('cancel'), style: 'cancel' },
                { text: t('accept'), style: 'default', onPress: () => acceptRequest(req.id) },
            ])

        }

    }

    function declineRequest(id){

        authAxios.post('decline-request/' + id, {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(response.data.message)
            }else{
                showAlert(t('alertRequestDeclined'))
                //setUserRequests(userRequests.filter(r => r.id !== req.id)) // update context or local state???
                getUserCares()
                getUserRequests()
            }

        })
        .catch(error => {
            console.log(error.response)
        })
        .then(() => {
            navigation.navigate('Anfragen')            
        })

    }

    function acceptRequest(id){

        authAxios.post('accept-request/' + id, {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(response.data.message)
            }else{
                showAlert(t('alertRequestAccepted'))
                //setUserRequests(userRequests.filter(r => r.id !== req.id)) // update context or local state???
                getUserCares()
                getUserRequests()
            }

        })
        .catch(error => {
            console.log(error.response)
        })
        .then(() => {
            navigation.navigate('Termine')            
        })

    }

    
    function downloadSetcard(){

        setLoading(true)

        authAxios.get('setcard/' + viewUser.id, { responseType: 'blob', timeout: 30000})
        .then(response => {

            setLoading(false)
            
            let link = document.createElement('a')
            link.href = URL.createObjectURL(response.data)
            link.setAttribute('download', 'Senihelp24-Setcard')
            document.body.appendChild(link)
            link.click()

        })
        .catch(error => {
            //console.log('setcard err', error)
        })

    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            { viewUser &&
            <View style={{backgroundColor: colors.lightGrey, marginTop: -30, marginHorizontal: -30}}>

                <Carousel data={viewUser.media} />
                            
                { user.user_role === 2 && route.params.request &&
                <View style={{paddingHorizontal:15, paddingBottom:20, marginBottom:20, marginTop:-20}}>
                    <RequestStatus request={route.params.request} userRole={user.user_role} />
                    <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                        <Pressable onPress={() => requestResponse('decline')} style={mainStyles.secondaryButton}>
                            <Text style={mainStyles.secondaryButtonText}>{t('decline')}</Text>
                        </Pressable>
                        <Pressable onPress={() => requestResponse('accept')} style={[mainStyles.primaryButton, {backgroundColor: colors.green}]}>
                            <Text style={mainStyles.primaryButtonText}>{t('accept')}</Text>
                        </Pressable>
                    </View>

                </View>
                }

                <View style={{backgroundColor: colors.white, paddingVertical: 40}}>
                    <View style={mainStyles.screenContainer}>
                        
                        { viewUser.profiles.length > 1 &&
                        <View style={mainStyles.inlineTabContainer}>
                            { viewUser.profiles.map((profil, index) => (
                                <Pressable key={index} onPress={() => setActiveProfile(index)} style={[mainStyles.inlineTab, {borderRightWidth: index + 1 === viewUser.profiles.length ? 0 : 1}]}>
                                    {({pressed}) => {
                                        let bgColor = colors.white
                                        let textColor = colors.darkGrey
                                        if(pressed){
                                            bgColor = colors.borderGrey
                                        }
                                        else if(index === activeProfile){
                                            bgColor = colors.primary
                                            textColor = colors.white
                                        }
                                        return (
                                            <View style={[mainStyles.inlineTabInner, {backgroundColor: bgColor}]}>
                                                <Text style={{padding:8, color: textColor}}>{t('person')} {index + 1}</Text>
                                            </View>
                                        )
                                    }}
                                </Pressable>
                            ))}
                        </View>
                        }

                        { viewUser.profiles.filter((profil, index) => index === activeProfile).map(profil => (
                        <View key={profil.id}>

                            <View style={{width:'100%', flexDirection:'row', alignItems:'center', justifyContent:'space-between', paddingHorizontal: 8}}>       

                                <View>

                                    <Text style={{fontSize: 24, color: colors.darkGrey}}>
                                        { (profil.anrede ? t(profil.anrede) + ' ' : '') + profil.vorname + ' ' + (user.id === viewUser.id ? profil.nachname : profil.nachname.charAt(0) + '.')}
                                    </Text>

                                    <Text style={{fontSize: 16, color: colors.darkGrey}}>                                        
                                        { profil.geburtsdatum ? (locale.startsWith('de') ? moment().diff(moment(profil.geburtsdatum), 'years') + ' ' + t('age') : t('age') + ' ' + moment().diff(moment(profil.geburtsdatum), 'years')) : null }
                                        { profil.groesse ? ', ' + profil.groesse + ' cm' : null }
                                        { profil.gewicht ? ', ' + profil.gewicht + ' kg' : null }
                                    </Text>
                                    
                                    { viewUser.user_role === 1 && viewUser.id !== user.id ? (
                                    <Pressable onPress={downloadSetcard} style={{marginTop: 10, marginBottom: 10}}>
                                        <Text style={{color: colors.primary}}>Download Setcard</Text>
                                    </Pressable>
                                    ) : null }

                                    { viewUser.user_role === 1 && <Badge value={viewUser.badge} /> }

                                    { /* viewUser.user_role === 2 &&
                                    <View style={{flexDirection:'row', alignItems:'center', marginTop: 12}}>
                                        <FontAwesomeIcon icon={faClock} size={16} color={colors.borderGrey} style={{marginLeft:1, marginRight:5}} />
                                        <Text style={{fontSize: 16, color: colors.darkGrey}}>{t('atDate')} {moment(viewUser.startdatum).format('DD.MM.YYYY')}</Text>
                                    </View>
                                    */ }

                                    { viewUser.ort &&
                                    <View style={{flexDirection:'row', alignItems:'center', marginTop: 2}}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} size={16} color={colors.borderGrey} style={{marginRight:4}} />
                                        <Text style={{fontSize: 16, color: colors.darkGrey}}>{t('in')} {viewUser.ort}</Text>
                                    </View>
                                    }

                                    { viewUser.user_role === 2 && profil.pflegegrad &&
                                    <View style={{flexDirection:'row', alignItems:'center', marginTop: 2}}>
                                        <FontAwesomeIcon icon={faWheelchair} size={16} color={colors.borderGrey} style={{marginLeft:1, marginRight:5}} />
                                        <Text style={{fontSize: 16, color: colors.darkGrey}}>{t(profileOptions.find(opt => opt.id === profil.pflegegrad).value)}</Text>
                                    </View>
                                    }

                                    { user.user_role === 1 && viewUser.wage && viewUser.id !== user.id ? (
                                    <View style={{flexDirection:'row', alignItems:'center', marginTop: 2}}>
                                        <FontAwesomeIcon icon={faWallet} size={16} color={colors.borderGrey} style={{marginRight:5}} />
                                        <Text style={{fontSize: 14, color: colors.darkGrey}}>{viewUser.wage} €</Text>
                                    </View>
                                    ) : null}
                                    
                                    { viewUser.id !== user.id ? (
                                    <View style={{flexDirection:'row', alignItems:'center', marginTop: 10}}>
                                        <UserStatus user={viewUser} />
                                    </View>
                                    ) : null }

                                </View> 

                                { user.id !== viewUser.id && <MatchingCircle score={viewUser.score} size="big" /> }

                            </View>
                        

                            <Pressable onPress={() => user.id === viewUser.id ? navigation.navigate('Bewertungen', {user_id: viewUser.id, screen: 'Meine Bewertungen'}) : navigation.navigate('Profil Bewertungen', {user_id: viewUser.id})} style={mainStyles.ratingBox}>
                                <Rating value={viewUser.rating} withText={true} />
                                <View style={{flex:1, flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
                                    <Text style={mainStyles.lightText}>{t('profileRatingsText')}</Text>
                                    <FontAwesomeIcon icon={faCaretRight} size={12} color={colors.borderGrey} style={{marginLeft:5}} />
                                </View>
                            </Pressable>

                            <RequestButton viewUser={viewUser} onSendRequest={handleSendRequest} onPokeRequest={handlePokeRequest} requestView={route.params.request} />
                                          
                            <ProfileTable profil={profil} fields={viewUser.matching_fields} isMe={viewUser.id === user.id} />

                            <RequestButton viewUser={viewUser} onSendRequest={handleSendRequest} onPokeRequest={handlePokeRequest} requestView={route.params.request} />

                        </View>
                        )) }

                    </View>                    
                </View>
                
            </View>
            }

        </ScrollView>
    )

}

export default ProfileScreen