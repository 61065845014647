import React, { useState, useEffect, useContext } from 'react'
import { ScrollView, View, Text, Platform } from 'react-native'
import Accordion from 'react-native-collapsible/Accordion'
import Axios from 'axios'

import { authAxios } from '../../scripts/Axios'
import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

import DocumentList from '../../components/DocumentList'
import Loader from '../../components/Loader'

function DocumentsScreen() {

    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { user } = useContext(UserContext)

    const [loading, setLoading] = useState(false)

    let sections = user.user_role === 1 ? [
        //{ title: t('documentsCategory1'), category: 'vertrag' },
        { title: t('documentsCategory3'), category: 'referenz' },
        { title: t('documentsCategory5'), category: 'lohnabrechnung' },
        { title: t('documentsCategory6'), category: 'sonstiges' },
    ] : [
        { title: t('documentsCategory1'), category: 'vertrag' },
        { title: t('documentsCategory2'), category: 'rechnung' },
        { title: t('documentsCategory4'), category: 'mahnung' },
        { title: t('documentsCategory6'), category: 'sonstiges' },
    ]

    const cancelSource = Axios.CancelToken.source()

    const [documents, setDocuments] = useState([])
    const [activeSections, setActiveSections] = useState([])

    useEffect(() => {
        
        setLoading(true)

        authAxios.get('documents', {cancelToken: cancelSource.token})
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                setDocuments(response.data.documents)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
        .then(() => setLoading(false))

        return () => cancelSource.cancel('get documents canceled')

    }, [])

    function renderHeader(section){
        let count = documents.filter(d => d.kategorie === section.category).length
        return (
            <View style={mainStyles.accordionHeader}>
                <Text style={mainStyles.accordionTitle}>{section.title} ({ count })</Text>
            </View>
        )
    }

    function renderContent(section){
        return <DocumentList data={documents.filter(d => d.kategorie === section.category)} editable={false} />
    }

    function updateSections(activeSections){
        setActiveSections(activeSections)
    }

    
    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer} style={{ paddingHorizontal: Platform.OS === 'web' ? 20 : 0}}>

                <Accordion activeSections={activeSections} sections={sections}
                expandMultiple
                underlayColor={colors.lightGrey}
                renderHeader={renderHeader}
                renderContent={renderContent}
                onChange={updateSections} />

            </View>

        </ScrollView>
    )

}

export default DocumentsScreen