import React, { useState, useContext, useRef } from 'react'
import { View, ScrollView, Text, Image, Pressable, KeyboardAvoidingView, Platform } from 'react-native'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'

import Logo from '../assets/logo.png'

import Checkbox from '../components/Checkbox'
import FooterMenu from '../components/FooterMenu'
import Input from '../components/Input'

import { LocalizationContext } from '../scripts/LocalizationContext'
import { UserContext } from '../scripts/UserContext'
import { AppContext } from '../scripts/AppContext'


function LoginScreen({ navigation }) {

    const { t } = useContext(LocalizationContext)
    const { login } = useContext(UserContext)
    const { setLoading, colors, mainStyles, webStyles } = useContext(AppContext)

    const [inputs, setInputs] = useState({
        email: '',
        password: '',
        stayloggedin: true,
    })

    const refPassword = useRef()

    function handleLogin(){
        setLoading(true)
        login(inputs)
    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={ Platform.OS === 'web' ? webStyles.smallScreenContainer : mainStyles.screenContainer}>

                <Image source={Logo} style={Platform.OS === 'web' ? webStyles.logo : mainStyles.logo} resizeMode="contain" />
                
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input type="email-address" name="email" state={inputs} setState={setInputs} placeholder={t('fieldEmail')} icon={faEnvelope} nextFocus={refPassword} onEnter={handleLogin} />
                    </View>
                </View>
                <View style={mainStyles.formRow}>
                    <View style={mainStyles.formCol}>
                        <Input reference={refPassword} name="password" secure={true} state={inputs} setState={setInputs} placeholder={t('fieldPassword')} icon={faLock} onEnter={handleLogin} />
                    </View>
                </View>

                <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between', marginTop:10, marginBottom:30}}>
                    <View>
                        <Checkbox name="stayloggedin" state={inputs} setState={setInputs} label={ t('loginStayLoggedIn') } iconSize={20} labelSize={12} />
                    </View>
                    <View>
                        <Pressable onPress={() => navigation.navigate('Passwort vergessen')} hitSlop={10}>
                            <Text style={[mainStyles.pinkLink, {fontSize:12}]}>{ t('loginForgotPassword') }</Text>
                        </Pressable>
                    </View>
                </View>

                <Pressable onPress={() => handleLogin()} style={[mainStyles.primaryButton, {width: '100%'}]}>
                    <Text style={mainStyles.primaryButtonText}>{ t('loginButton') }</Text>
                </Pressable>

                <View style={mainStyles.buttonContainer}>
                    
                    <Text style={{textAlign:'center', color: colors.darkGrey}}>{ t('loginNoAccount') }</Text>
                    <Pressable onPress={() => navigation.navigate('Registrieren')} style={mainStyles.registerButton}>
                        <Text style={mainStyles.registerButtonText}>{ t('loginRegisterButton') }</Text>
                    </Pressable>        

                </View>

                <FooterMenu navigation={navigation} />

            </View>

        </ScrollView>
    )

}

export default LoginScreen