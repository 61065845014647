import React, { useContext, useEffect, useState } from 'react'
import { View, Pressable } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { navigationRef } from './scripts/RootNavigation'
import { createStackNavigator } from '@react-navigation/stack'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

// Context
import { AppContext } from './scripts/AppContext'
import { UserContext } from './scripts/UserContext'
import { LocalizationContext } from './scripts/LocalizationContext'

// Web
import Sidemenu from './components/web/Sidemenu'

// Login Screens
import LoginScreen from './screens/Login'
import ForgotPasswordScreen from './screens/ForgotPassword'
import ForgotPasswordVerifyScreen from './screens/ForgotPasswordVerify'
import ResetPasswordScreen from './screens/ResetPassword'
import RegisterScreen from './screens/Register'
import AGBsScreen from './screens/AGBs'
import DataPrivacyScreen from './screens/DataPrivacy'
// Register Screens
import AccountConfirmationScreen from './screens/registration/AccountConfirmation'
import AccountConfirmationVerifyScreen from './screens/registration/AccountConfirmationVerify'
// Create Profile Screens
import ProfileIntroScreen from './screens/registration/ProfileIntro'
import ProfilePersonalDataScreen from './screens/registration/ProfilePersonalData'
import ProfilePersonalDataBKScreen from './screens/registration/ProfilePersonalDataBK'
import ProfileAddressScreen from './screens/registration/ProfileAddress'
import ProfilePBListScreen from './screens/registration/ProfilePBList'
import ProfileAddPBScreen from './screens/registration/ProfileAddPB'
import ProfileAddBKScreen from './screens/registration/ProfileAddBK'
import ProfileMediaPBScreen from './screens/registration/ProfileMediaPB'
import ProfileMediaBKScreen from './screens/registration/ProfileMediaBK'
import ProfileContactsScreen from './screens/registration/ProfileContacts'
import ProfileAddContactScreen from './screens/registration/ProfileAddContact'
import ProfileReferencesScreen from './screens/registration/ProfileReferences'
import ProfileFinishScreen from './screens/registration/ProfileFinish'
import AccountApprovalScreen from './screens/registration/AccountApproval'
// Care Screens
import CareEventScreen from './screens/cares/CareEvents'
import CareSearchScreen from './screens/cares/CareSearch'
import CareRequestScreen from './screens/cares/CareRequests'
import ProfileScreen from './screens/cares/Profile'
import ProfileRatingsScreen from './screens/cares/ProfileRatings'
// Communication Screens
import MessagesScreen from './screens/communication/Messages'
import MessageScreen from './screens/communication/Message'
import ChatScreen from './screens/communication/Chat'
// Profile Screens
import DocumentsScreen from './screens/myprofile/Documents'
import RatingsScreen from './screens/myprofile/Ratings'
import RateBKScreen from './screens/myprofile/RateBK'
import RatePBScreen from './screens/myprofile/RatePB'
import ContactsScreen from './screens/myprofile/Contacts'
import AccountScreen from './screens/myprofile/Account'
import AccountDeleteScreen from './screens/myprofile/AccountDelete'


function Navigation() {

  const { t } = useContext(LocalizationContext)
  const { dataLoaded, setDataLoaded, user, checkUser, logout } = useContext(UserContext)
  const { setLoading, colors, webStyles, showAlert } = useContext(AppContext)

  const [currentRoute, setCurrentRoute] = useState(null)

  useEffect(() => {

    const init = async () => {
      let check = await checkUser()
      if(!check){
        setDataLoaded(true)
      }
    }
    init()

  }, [])
  

  useEffect(() => {

    if(dataLoaded || !user){
      setLoading(false)
    }

  }, [dataLoaded])


  const linking = {
    prefixes: [
      'https://app.senihelp24.de',
      'https://app.senihelp24.pl',
    ],
  }

  const MainStack = createStackNavigator()
  const SubStack = createStackNavigator()

  const headerOptions = {
    headerTintColor: colors.darkGrey,
    headerTitleStyle: {
        marginLeft: 10,
    },
    headerStyle: {
      backgroundColor: colors.white,
    },
    headerRight: () => {
      return user && user.konto <= 3 ? (
        <Pressable onPress={() => handleLogout()} hitSlop={15} style={{marginRight: 20}}>
          <FontAwesomeIcon icon={faSignOutAlt} size={20} color={colors.darkGrey} />
        </Pressable>
      ) : null
    },
  }

  function handleLogout(){
    showAlert(
        t('logout'),
        t('logoutQuestion'),
        [
            { text: t('cancel'), style: 'cancel' },
            { text: t('logout'), onPress: () => logout() },
        ],            
    )
  }

  const transparentHeaderOptions = {
    headerTransparent: true,
    title: '',
    headerTintColor: colors.white,
    headerStyle: {
      backgroundColor: 'transparent',
      elevation: 0,
    },
  }

  return (
    <View style={webStyles.app}>
    <View style={webStyles.wrapper}>

      { dataLoaded &&
      <NavigationContainer linking={linking} ref={navigationRef} onReady={() => {
        let name = navigationRef.current.getCurrentRoute().name
        setCurrentRoute(name)
      }} onStateChange={async () => {
        let name = navigationRef.current.getCurrentRoute().name
        setCurrentRoute(name)
      }}>

        <View style={{flexDirection:'row', height:'100%'}}>
            
          { user !== null && user.konto === 1 && user.verified_at !== null && <Sidemenu type="registration" currentRouteName={currentRoute} /> }
          { user !== null && (user.konto === 2 || user.konto === 3) && user.verified_at !== null && <Sidemenu type="approval" currentRouteName={currentRoute} /> }
          { user !== null && user.konto === 4 && <Sidemenu type="main" currentRouteName={currentRoute} /> }

            <View style={{flex:1, backgroundColor: colors.white}}>
            { user === null &&
                <MainStack.Navigator>
                    <MainStack.Screen name="Login" component={LoginScreen} options={{...headerOptions, title: t('login'), headerShown: false}} />
                    <MainStack.Screen name="Passwort vergessen" component={ForgotPasswordScreen} options={{...headerOptions, title: t('forgotPasswordScreen'), headerShown: true}} />
                    <MainStack.Screen name="Passwort vergessen - Verifizierung" component={ForgotPasswordVerifyScreen} options={{...headerOptions, title: t('forgotPasswordVerifyScreen'), headerShown: true}} />
                    <MainStack.Screen name="Passwort zurücksetzen" component={ResetPasswordScreen} options={{...headerOptions, title: t('resetPasswordScreen'), headerShown: true}} />
                    <MainStack.Screen name="Registrieren" component={RegisterScreen} options={{...headerOptions, title: t('registerScreen'), headerShown: true}} />
                    <MainStack.Screen name="AGB" component={AGBsScreen} options={{...headerOptions, title: t('agb'), headerShown: true}} />
                    <MainStack.Screen name="Datenschutzerklärung" component={DataPrivacyScreen} options={{...headerOptions, title: t('dataPrivacy'), headerShown: true}} />
                </MainStack.Navigator>
            }

            { user !== null && user.konto === 1 && user.verified_at === null &&
                <MainStack.Navigator>
                    <MainStack.Screen name="Konto Verifizierung" component={AccountConfirmationScreen} options={{...headerOptions, title: t('accountConfirmationScreen'), headerShown: true}} />
                    <MainStack.Screen name="Konto Verifizierung 2" component={AccountConfirmationVerifyScreen} options={{...headerOptions, title: t('accountConfirmationVerifyScreen'), headerShown: true}} />
                </MainStack.Navigator>
            }

            { user !== null && user.konto === 1 && user.verified_at !== null &&
                <MainStack.Navigator shifting={false} activeColor={colors.primary} inactiveColor={colors.darkGrey} barStyle={{backgroundColor: colors.lightGrey}}>
                    <MainStack.Screen name="Konto verifiziert" component={ProfileIntroScreen} options={{...headerOptions, title: t('profileIntroScreen'), headerShown: true}} />
                    <MainStack.Screen name="PB Kontakt Stammdaten" component={ProfilePersonalDataScreen} options={{...headerOptions, title: t('profilePersonalDataPBScreen'), headerShown: true}} />
                    <MainStack.Screen name="BK Stammdaten" component={ProfilePersonalDataBKScreen} options={{...headerOptions, title: t('profilePersonalDataBKScreen'), headerShown: true}} />
                    <MainStack.Screen name="Pflegebedürftige Personen" component={ProfilePBListScreen} options={{...headerOptions, title: t('profilePBListScreen'), headerShown: true}} />
                    <MainStack.Screen name="PB Profil" component={ProfileAddPBScreen} options={{...headerOptions, title: t('profileAddPBScreen'), headerShown: true}} />
                    <MainStack.Screen name="BK Profil" component={ProfileAddBKScreen} options={{...headerOptions, title: t('profileAddBKScreen'), headerShown: true}} />
                    <MainStack.Screen name="Adressdaten" component={ProfileAddressScreen} options={{...headerOptions, title: t('profileAddressScreen'), headerShown: true}} />
                    <MainStack.Screen name="PB Medien" component={ProfileMediaPBScreen} options={{...headerOptions, title: t('profileMediaScreen'), headerShown: true}} />
                    <MainStack.Screen name="BK Medien" component={ProfileMediaBKScreen} options={{...headerOptions, title: t('profileMediaScreen'), headerShown: true}} />
                    <MainStack.Screen name="Kontaktpersonen" component={ProfileContactsScreen} options={{...headerOptions, title: t('profilContactListScreen'), headerShown: true}} />
                    <MainStack.Screen name="Kontaktperson hinzufügen" component={ProfileAddContactScreen} options={{...headerOptions, title: t('profilAddContactScreen'), headerShown: true}} />
                    <MainStack.Screen name="Referenzen" component={ProfileReferencesScreen} options={{...headerOptions, title: t('profilReferencesScreen'), headerShown: true}} />
                    <MainStack.Screen name="Registrierung abschliessen" component={ProfileFinishScreen} options={{...headerOptions, title: t('profileFinishScreen'), headerShown: true}} />
                    <MainStack.Screen name="E-Mails" component={MessagesScreen} options={{title: t('messagesTabEmails')}} />
                    <MainStack.Screen name="E-Mail" component={MessageScreen} options={{...headerOptions, title: t('fieldEmail'), headerShown: true}} />
                    <MainStack.Screen name="Chat" component={ChatScreen} options={{title: t('messagesTabChat')}} />
                </MainStack.Navigator>
            }

            { user !== null && (user.konto === 2 || user.konto === 3) &&
                <MainStack.Navigator shifting={false} activeColor={colors.primary} inactiveColor={colors.darkGrey} barStyle={{backgroundColor: colors.lightGrey}}>
                    <SubStack.Screen name="Konto-Freigabe" component={AccountApprovalScreen} options={{...headerOptions, title: t('accountApprovalScreen'), headerShown: true}} />
                    <SubStack.Screen name="Profil" component={ProfileScreen} options={{...transparentHeaderOptions, title: t('profileScreen'), headerShown: true}} />
                    <SubStack.Screen name="Profil Bewertungen" component={ProfileRatingsScreen} options={{...headerOptions, title: t('profileRatingsScreen'), headerShown: true}} />
                    <MainStack.Screen name="E-Mails" component={MessagesScreen} options={{title: t('messagesTabEmails')}} />
                    <MainStack.Screen name="E-Mail" component={MessageScreen} options={{...headerOptions, title: t('fieldEmail'), headerShown: true}} />
                    <MainStack.Screen name="Chat" component={ChatScreen} options={{title: t('messagesTabChat')}} />
                </MainStack.Navigator>
            }

            { user !== null && user.konto === 4 &&
                <MainStack.Navigator initialRouteName="Termine" shifting={false} activeColor={colors.primary} inactiveColor={colors.darkGrey} barStyle={{backgroundColor: colors.lightGrey}}>
                    <MainStack.Screen name="Termine" component={CareEventScreen} options={{...headerOptions, title: t('caresTabEvents'), headerLeft: null, headerShown: true}} />
                    <MainStack.Screen name="Suche" component={CareSearchScreen} options={{...headerOptions, title: t('caresTabSearch'), headerLeft: null, headerShown: true}} />
                    <MainStack.Screen name="Anfragen" component={CareRequestScreen} options={{...headerOptions, title: t('caresTabRequests'), headerLeft: null, headerShown: true}} />
                    <MainStack.Screen name="Profil" component={ProfileScreen} options={{...headerOptions, title: t('profileScreen'), headerShown: true}} />
                    <MainStack.Screen name="Profil Bewertungen" component={ProfileRatingsScreen} options={{...headerOptions, title: t('profileRatingsScreen'), headerShown: true}} />
                    <MainStack.Screen name="E-Mails" component={MessagesScreen} options={{...headerOptions, title: t('messagesTabEmails'), headerLeft: null, headerShown: true}} />
                    <MainStack.Screen name="E-Mail" component={MessageScreen} options={{...headerOptions, title: t('fieldEmail'), headerShown: true}} />
                    <MainStack.Screen name="Chat" component={ChatScreen} options={{...headerOptions, title: t('messagesTabChat'), headerLeft: null, headerShown: true}} />
                    <MainStack.Screen name="Mein Profil Detail" component={ProfileScreen} options={{...headerOptions, title: t('profileScreen'), headerLeft: null, headerShown: true}} />
                    <MainStack.Screen name="Dokumente" component={DocumentsScreen} options={{...headerOptions, title: t('documentsScreen'), headerLeft: null, headerShown: true}} />
                    <MainStack.Screen name="Bewertungen" component={RatingsScreen} options={{...headerOptions, title: t('ratingsScreen'), headerShown: true}} />
                    <MainStack.Screen name="BK Bewerten" component={RateBKScreen} options={{...headerOptions, title: t('rateBKScreen'), headerShown: true}} />
                    <MainStack.Screen name="PB Bewerten" component={RatePBScreen} options={{...headerOptions, title: t('ratePBScreen'), headerShown: true}} />
                    <MainStack.Screen name="Kontakte" component={ContactsScreen} options={{...headerOptions, title: t('contactsScreen'), headerLeft: null, headerShown: true}} />
                    <MainStack.Screen name="Einstellungen" component={AccountScreen} options={{...headerOptions, title: t('settingsScreen'), headerLeft: null, headerShown: true}} />
                    <MainStack.Screen name="Konto löschen" component={AccountDeleteScreen} options={{...headerOptions, title: 'Konto löschen', headerShown: true}} />
                </MainStack.Navigator>
            }
            </View>
          
        </View>

      </NavigationContainer>
      }

    </View>
    </View>
  )

}

export default Navigation