import React, { useContext, useState } from 'react'
import { View, ScrollView, Text, Pressable, StyleSheet, KeyboardAvoidingView, Platform } from 'react-native'

import { authAxios } from '../../scripts/Axios'
import RatingSelect from '../../components/RatingSelect'
import Input from '../../components/Input'
import Loader from '../../components/Loader'
import { UserContext } from '../../scripts/UserContext'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function RatePBScreen({ route, navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { getOpenRatings } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    
    const [inputs, setInputs] = useState({
        rating_wohnqualitaet: 0,
        rating_charakter: 0,
        rating_pflegeaufwand: 0,
        rating_umfeld: 0,
        rating_freizeit: 0,
        rating_text: '',
    })

    const styles = StyleSheet.create({
        container: {
            borderBottomWidth: 1,
            borderColor: colors.borderGrey,
            marginBottom: 10,
            paddingBottom: 10,
        },
    })


    function saveRating(){

        setLoading(true)

        authAxios.post('rating/' + route.params.care_id, inputs)
        .then(response => {

            if(response.data.type === 'error'){
                showAlert(t('error'), response.data.message)
            }else{
                showAlert(t('alertRatingDoneTitle'), t('alertRatingDone'))
                getOpenRatings()
                setLoading(false)
            }

        })
        .catch(error => {
            console.log(error)
        })
        .then(() => {
            navigation.goBack()
        })

    }

    return (
    <KeyboardAvoidingView enabled={Platform.OS === 'ios'} behavior="padding" style={mainStyles.keyboardContent} keyboardVerticalOffset={80}>
        <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:40}}>
                    <Text style={mainStyles.title}>{t('ratePBTitle')}</Text>
                    <Text style={[mainStyles.titleText, {marginBottom:15}]}>{t('ratePBText')}</Text>
                    <Text style={mainStyles.titleText}>{t('ratePBText2')}</Text>
                </View>

                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('ratePBCategory1')}</Text>
                        <RatingSelect name="rating_wohnqualitaet" state={inputs} setState={setInputs} />
                    </View>
                </View>

                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('ratePBCategory2')}</Text>
                        <RatingSelect name="rating_charakter" state={inputs} setState={setInputs} />
                    </View>
                </View>

                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('ratePBCategory3')}</Text>
                        <RatingSelect name="rating_pflegeaufwand" state={inputs} setState={setInputs} />
                    </View>
                </View>
                    
                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('ratePBCategory4')}</Text>
                        <RatingSelect name="rating_umfeld" state={inputs} setState={setInputs} />
                    </View>
                </View>
                    
                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('ratePBCategory5')}</Text>
                        <RatingSelect name="rating_freizeit" state={inputs} setState={setInputs} />
                    </View>
                </View>

                <View style={[mainStyles.formRow, { marginBottom: 20 }]}>
                    <View style={mainStyles.formCol}>
                        <Text style={mainStyles.label}>{t('ratePBOtherLabel')}</Text>
                        <Input name="rating_text" multiline={true} state={inputs} setState={setInputs} placeholder={t('ratePBOther')} />
                    </View>
                </View>

                <View style={mainStyles.buttonContainer}>
                    <Pressable onPress={() => saveRating()} style={mainStyles.primaryButton}>
                        <Text style={mainStyles.primaryButtonText}>{t('save')}</Text>
                    </Pressable>
                </View>
            
            </View>

        </ScrollView>
    </KeyboardAvoidingView>
    )

}

export default RatePBScreen