import React, { useState, useContext, useEffect } from 'react'
import { StyleSheet, View, Text, Pressable, Image } from 'react-native'
import * as RootNavigation from '../../scripts/RootNavigation'
import Logo from '../../assets/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCalendarAlt, faCog, faComment, faEnvelope, faFile, faQuestionCircle, faSearch, faStar, faUser, faUserTie, faWheelchair } from '@fortawesome/free-solid-svg-icons'

import { UserContext } from '../../scripts/UserContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'
import { AppContext } from '../../scripts/AppContext'

function Sidemenu({ type, currentRouteName }) {
    
    const { colors, webStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)
    const { user } = useContext(UserContext)
    const [menu, setMenu] = useState([])

    useEffect(() => {

        if(type === 'main'){
            setMenu([
                {
                    title: t('caresScreen'),
                    screens: [
                        { title: t('caresTabEvents'), screen: 'Termine', icon: faCalendarAlt },
                        { title: t('caresTabSearch'), screen: 'Suche', icon: faSearch },
                        { title: t('caresTabRequests'), screen: 'Anfragen', icon: faQuestionCircle },
                    ],
                },
                {
                    title: t('messagesScreen'),
                    screens: [
                        { title: t('messagesTabEmails'), screen: 'E-Mails', icon: faEnvelope },
                        { title: t('messagesTabChat'), screen: 'Chat', icon: faComment },
                    ],
                },
                {
                    title: t('myProfileScreen'),
                    screens: [
                        { title: user.user_role === 1 ? t('myProfileMenuBK1') : t('myProfileMenuPB1'), screen: 'Mein Profil Detail', icon: user.user_role === 1 ? faUserTie : faWheelchair, data: { user_id: user.id } },
                        { title: t('myProfileMenuPB2'), screen: 'Dokumente', icon: faFile },
                        { title: t('myProfileMenuPB3'), screen: 'Bewertungen', icon: faStar },
                        { title: t('myProfileMenuPB4'), screen: 'Kontakte', icon: faUser },
                        { title: t('myProfileMenuPB5'), screen: 'Einstellungen', icon: faCog },
                    ],
                },
            ])
        }
        else if(type === 'registration'){
            setMenu([
                {
                    title: t('registerScreen'),
                    screens: [
                        { title: t('registerScreen'), screen: user.user_role === 1 ? 'BK Stammdaten' : 'PB Kontakt Stammdaten', icon: faUser },
                    ],
                },
                {
                    title: t('messagesScreen'),
                    screens: [
                        { title: t('messagesTabEmails'), screen: 'E-Mails', icon: faEnvelope },
                        { title: t('messagesTabChat'), screen: 'Chat', icon: faComment },
                    ],
                },
            ])
        }
        else if(type === 'approval'){
            setMenu([
                {
                    title: t('registerScreen'),
                    screens: [
                        { title: t('accountApprovalScreen'), screen: 'Konto-Freigabe', icon: faUser },
                    ],
                },
                {
                    title: t('messagesScreen'),
                    screens: [
                        { title: t('messagesTabEmails'), screen: 'E-Mails', icon: faEnvelope },
                        { title: t('messagesTabChat'), screen: 'Chat', icon: faComment },
                    ],
                },
            ])
        }

    }, [])

    function navigateTo(subItem){
        RootNavigation.navigate(subItem.screen, subItem.data)
    }

    const styles = StyleSheet.create({
        label: {
            marginTop: 30,
            marginBottom: 10,
            paddingHorizontal: 20,
            fontWeight: 'bold',
            color: colors.primary,
            textTransform: 'uppercase',
            fontSize: 12,
        },
        item: {
            minHeight: 50,
            paddingVertical: 15,
            paddingHorizontal: 20,
            marginTop: 2,
            cursor: 'pointer',
            borderRadius: 8,
        },
        itemInner: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        itemInnerHover: {
            opacity: .5,
        },
        title: {
            fontSize: 17,
            color: colors.darkGrey,
            marginLeft: 20,
        },
        titleActive: {
            fontSize: 17,
            color: colors.white,
            marginLeft: 20,
        },
    })

    return (
        <View style={webStyles.sideMenu}>

            <Image source={Logo} style={{flex:1, height:50, flexGrow:0, marginHorizontal:30, marginTop:10, marginBottom:20}} resizeMode="contain" />
            
            { menu.map((item, index) => {
                let key = index
                return (
                    <View key={key}>

                        <Text style={styles.label}>{item.title}</Text>

                        { item.screens.map((subItem, subIndex) => {
                            let isActive = currentRouteName === subItem.screen
                            return (
                                <Pressable key={index+'-'+subIndex} style={[styles.item, { backgroundColor: isActive ? colors.primary : null }]} onPress={() => navigateTo(subItem)}>
                                    {({pressed}) => (
                                        <View style={styles.itemInner}>
                                            {subItem.icon && <FontAwesomeIcon icon={subItem.icon} size={18} color={!isActive ? colors.mediumGrey : colors.white} /> }
                                            <Text style={!isActive ? styles.title : styles.titleActive}>{subItem.title}</Text>
                                        </View>
                                    )}
                                </Pressable>
                            )
                        }) }

                    </View>
                )
            }) }

        </View>
    )

}


export default Sidemenu