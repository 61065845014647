import React, { useState, useRef, useContext } from 'react'
import { StyleSheet, View, Pressable, FlatList } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'

import { AppContext } from '../scripts/AppContext'
import { UserContext } from '../scripts/UserContext'
import AuthImage from '../components/AuthImage'
import AuthVideo from '../components/AuthVideo'

function Carousel({ data }) {

  const { colors, webStyles } = useContext(AppContext)
  const { token } = useContext(UserContext)

  const [currentPage, setCurrentPage] = useState(0)
  const height = 300

  const a = StyleSheet.flatten(webStyles.wrapper).maxWidth
  const b = StyleSheet.flatten(webStyles.sideMenu).flexBasis
  const width = a - b - 15

  const carouselRef = useRef()

  const styles = StyleSheet.create({
    prevButton: {
      position: 'absolute',
      left: 20,
      top: height / 2 - 18,
    },
    nextButton: {
      position: 'absolute',
      right: 20,
      top: height / 2 - 18,
    },
  })
  
  function prevPage(){
    let newIndex = currentPage - 1
    if(newIndex >= 0){
      carouselRef.current.scrollToIndex({animated: true, index: newIndex})
      setCurrentPage(newIndex)
    }
  }

  function nextPage(){
    let newIndex = currentPage + 1
    if(newIndex <= data.length - 1){
      carouselRef.current.scrollToIndex({animated: true, index: newIndex})
      setCurrentPage(newIndex)
    }
  }

  return (
    <View>

      <View style={{flex: 1, width: '100%', height: height, backgroundColor:colors.lightGrey}}>

        <FlatList
          ref={carouselRef}
          contentContainerStyle={{flex:1, height:height}}
          data={data}
          horizontal
          keyExtractor={item => item.id}
          pagingEnabled
          initialScrollIndex={currentPage}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => (
            <View style={{flex:1, height:height, overflow:'hidden'}}>
              { item.kategorie === 'video' ? (
                <AuthVideo video={item} width={width} height={height} />
              ) : (
                <AuthImage image={item} width={width} height={height} mode="contain" />
              )}
            </View>
          )}
          getItemLayout={(data, index) => (
            {length: width, offset: width * index, index}
          )}
        />

        <Pressable onPress={() => prevPage()} hitSlop={30} style={styles.prevButton}>
          <FontAwesomeIcon icon={faCaretLeft} size={36} color={colors.darkGrey} />
        </Pressable>

        <Pressable onPress={() => nextPage()} hitSlop={30} style={styles.nextButton}>
          <FontAwesomeIcon icon={faCaretRight} size={36} color={colors.darkGrey} />
        </Pressable>

      </View>

    </View>
  )

}

export default Carousel
