import React, { useContext } from 'react'
import { Pressable, View, Text } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronRight, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { LocalizationContext } from '../scripts/LocalizationContext'

import Rating from './Rating'
import MatchingCircle from './MatchingCircle'
import Badge from './Badge'
import AuthImage from './AuthImage'
import UserStatus from './UserStatus'
import { AppContext } from '../scripts/AppContext'

function UserPreview({ type, viewUser, navigation, request = null, care = null }) {

    const { colors, mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)

    if(!viewUser) return <Text>Dieser Benutzer exisitiert nicht mehr.</Text>

    if(type === 'care'){
        return (
            <Pressable style={mainStyles.profileListPressable} onPress={() => navigation.navigate('Profil', {user_id: viewUser.id})}>
                <View style={{flexGrow:0}}>
                    <AuthImage image={viewUser.bild} width={56} height={56} styles={{marginRight:10, borderRadius:4}} />
                </View>
                <View style={{flexGrow:1, justifyContent:'center', height:60}}>
                    <Rating value={viewUser.rating} />
                    <Text style={{color: colors.darkGrey, fontWeight:'bold'}}>{ viewUser.profil.fullname }</Text>
                    { viewUser.user_role === 1 ? <Badge value={viewUser.badge} /> : null }
                </View>
                <View>
                    <FontAwesomeIcon icon={faChevronRight} size={20} color={colors.borderGrey} />
                </View>
            </Pressable>
        )
    }
    else if(type === 'rating'){
        return (
            <Pressable style={mainStyles.profileListPressable} onPress={() => navigation.navigate(viewUser.user_role === 1 ? 'BK Bewerten' : 'PB Bewerten', {care_id: care.id})}>
                <View style={{flexGrow:0}}>
                    <AuthImage image={viewUser.bild} width={56} height={56} styles={{marginRight:10, borderRadius:4}} />
                </View>
                <View style={{flexGrow:1, justifyContent:'center', height:60}}>
                    <Text style={{color: colors.darkGrey, fontWeight:'bold'}}>{ viewUser.profil.fullname }</Text>
                    <Text style={[mainStyles.lightText, {fontSize:13, textAlign:'left'}]}>{t('ratingsRateNowText')}</Text>
                    <Text style={[mainStyles.formLabel, {textTransform:'uppercase'}]}>{t('ratingsRateNowText2')}</Text>
                </View>
                <View>
                    <FontAwesomeIcon icon={faChevronRight} size={20} color={colors.borderGrey} />
                </View>
            </Pressable>
        )
    }
    else if(type === 'request'){
        return (
            <Pressable style={mainStyles.profileListPressable} onPress={() => navigation.navigate('Profil', {user_id: viewUser.id, request: request })}>
                <View style={{flexGrow:0}}>
                    <AuthImage image={viewUser.bild} width={80} height={80} styles={{marginRight:10, borderRadius:4}} />
                </View>
                <View style={{flexGrow:1, justifyContent:'center', height:80}}>

                    <Rating value={viewUser.rating} />

                    { viewUser.profil ? (
                        <Text style={{color: colors.darkGrey, fontWeight:'bold'}}>
                            { (viewUser.profil.anrede ? t(viewUser.profil.anrede) + ' ' : '') + viewUser.profil.vorname + ' ' + viewUser.profil.nachname.charAt(0) + '.' }
                        </Text>
                    ) : null }

                    { viewUser.user_role === 1 ? <Badge value={viewUser.badge} /> : null }

                    <View style={{justifyContent:'center', marginTop:'auto'}}>
                        { viewUser.user_role === 2 && viewUser.ort ? (
                        <View style={{flexDirection:'row', alignItems:'center'}}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} size={16} color={colors.borderGrey} style={{marginRight:4}} />
                            <Text style={{color: colors.darkGrey}}>{t('in')} {viewUser.ort}</Text>
                        </View>
                        ) : null }
                        <View style={{flexDirection:'row', alignItems:'center'}}>
                            <FontAwesomeIcon icon={faClock} size={14} color={colors.borderGrey} style={{marginLeft:1, marginRight:5}} />
                            <Text style={{color: colors.darkGrey}}>{t('atDate')} { request.datum ? moment(request.datum).format('DD.MM.YYYY') : '' }</Text>
                        </View>
                    </View>

                </View>
                <View>
                    <MatchingCircle score={viewUser.score} />
                </View>
            </Pressable>
        )
    }
    else if(type === 'search'){
        return (
            <Pressable style={mainStyles.profileListPressable} onPress={() => navigation.navigate('Profil', {user_id: viewUser.id})}>
                <View style={{flexGrow:0}}>
                    <AuthImage image={viewUser.bild} width={80} height={80} styles={{marginRight:10, borderRadius:4}} />
                </View>
                <View style={{flexGrow: 1, flexBasis: 1, justifyContent:'center', minHeight:80}}>

                    <Rating value={viewUser.rating} />

                    { viewUser.profil ? (
                        <Text style={{color: colors.darkGrey, fontWeight:'bold'}}>
                            { (viewUser.profil.anrede ? t(viewUser.profil.anrede) + ' ' : '') + viewUser.profil.vorname + ' ' + viewUser.profil.nachname.charAt(0) + '.' }
                        </Text>
                    ) : null}

                    { viewUser.user_role === 1 ? <Badge value={viewUser.badge} /> : null }

                    <View style={{flexDirection:'row', alignItems:'center', marginTop:'auto'}}>
                        <UserStatus user={viewUser} />
                    </View>

                    { viewUser.user_role === 2 && viewUser.ort ? (
                        <View style={{flexDirection:'row', alignItems:'center', marginTop:'auto'}}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} size={16} color={colors.borderGrey} style={{marginRight:4}} />
                            <Text style={{color: colors.darkGrey}}>{viewUser.plz} {viewUser.ort}</Text>
                        </View>
                    ) : null}

                </View>
                <View>
                    <MatchingCircle score={viewUser.score} animate={false} />
                </View>
            </Pressable>
        )
    }else{
        return null
    }

}

export default UserPreview