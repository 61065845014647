import React, { useContext } from 'react'
import { StyleSheet, View, Text, Pressable } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faMinusCircle, faUser } from '@fortawesome/free-solid-svg-icons'

import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'


function PersonList({ data, isContacts = false, del, navigation, navigateTo }) {
    
    const { colors, mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)

    const styles = StyleSheet.create({
        container: {
            flexDirection:'column',
            borderWidth: 1,
            borderColor: colors.borderGrey,
            borderRadius: 4,
        },
        item: {
            borderWidth: 1,
            borderColor: colors.borderGrey,
        },
        iconBox: {
            justifyContent: 'center',
            alignItems: 'center',
            width: 36,
            height: 36,
            backgroundColor: colors.lightGrey,
            borderRadius: 3,
            marginRight: 10,
        },
        itemInner: {
            flexDirection:'row',
            alignItems:'center',
            height: 54,
            paddingHorizontal: 10,
        },
        itemInnerHover: {
            backgroundColor: colors.borderGrey,
            opacity: .5,
        },
        label: {
            fontSize: 15,
            color: colors.darkGrey,
        }
    })

    return (
        <View style={styles.container}>

            { data.length <= 0 ? <Text style={[mainStyles.lightText, {marginVertical: 10}]}>{t('profilePBListNoProfiles')}</Text> : null }
            
            { data.map(item => {                
                return !isContacts ? (
                    <Pressable key={item.id} style={styles.item} onPress={() => navigation.navigate(navigateTo, {edit: item})}>
                        {({pressed}) => (
                            <View style={!pressed ? styles.itemInner : [styles.itemInner, styles.itemInnerHover]}>
                                <View style={styles.iconBox}>
                                    <FontAwesomeIcon icon={faUser} size={24} color={colors.darkGrey} />
                                </View>
                                <Text style={styles.label}>{item.fullname}</Text>
                                {del &&
                                <Pressable style={{marginLeft:'auto'}} hitSlop={15} onPress={() => del(item.id)}>
                                    <FontAwesomeIcon icon={faMinusCircle} size={20} color={colors.darkGrey} />
                                </Pressable>
                                }
                            </View>
                        )}
                    </Pressable>
                ) : (
                    <Pressable key={item.id} style={styles.item} onPress={() => item.account === 0 ? navigation.navigate(navigateTo, {edit: item}) : null}>
                        {({pressed}) => (
                            <View style={!pressed ? styles.itemInner : [styles.itemInner, styles.itemInnerHover]}>
                                <View style={styles.iconBox}>
                                    <FontAwesomeIcon icon={faUser} size={24} color={colors.darkGrey} />
                                </View>
                                <Text style={styles.label}>{item.fullname}</Text>
                                {del && item.account === 0 &&
                                <Pressable style={{marginLeft:'auto'}} hitSlop={15} onPress={() => del(item.id)}>
                                    <FontAwesomeIcon icon={faMinusCircle} size={20} color={colors.darkGrey} />
                                </Pressable>
                                }
                            </View>
                        )}
                    </Pressable>
                )
            }) }

        </View>
    )

}


export default PersonList