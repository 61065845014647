import React, { useState, useEffect, useContext } from 'react'
import { View, ScrollView, Text, Pressable } from 'react-native'
import Axios from 'axios'

import PersonList from '../../components/PersonList'
import Loader from '../../components/Loader'
import { authAxios } from '../../scripts/Axios'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function ProfileContactsScreen({ route, navigation }) {

    const { t } = useContext(LocalizationContext)
    const { showAlert, mainStyles, colors } = useContext(AppContext)

    const [loading, setLoading] = useState(false)
    const [contacts, setContacts] = useState([])

    const cancelSource = Axios.CancelToken.source()

    useEffect(() => {
        
        setLoading(true)

        authAxios.get('contacts', {cancelToken: cancelSource.token})
        .then(response => {

            setLoading(false)

            if(response.data.type === 'success'){
                setContacts(response.data.contacts)
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

        return () => cancelSource.cancel('get contacts canceled')

    }, [])

    useEffect(() => {        
        if(route.params && route.params.contact){
            let contact = route.params.contact
            setContacts([
                ...contacts.filter(c => c.id !== contact.id),
                route.params.contact
            ])
        }
    }, [route])

    function handleDeleteContact(id){
        showAlert(
            t('alertDeleteContactTitle'),
            t('alertDeleteContact'),
            [
                { text: t('cancel'), style: 'cancel' },
                { text: t('delete'), onPress: () => deleteContact(id), style: 'destructive' },
            ],
        )
    }

    function deleteContact(id){
                
        authAxios.delete('contact/' + id)
        .then(response => {

            setLoading(false)

            if(response.data.type === 'success'){
                setContacts(contacts.filter(c => c.id !== id))
            }

        })
        .catch(error => {
            console.log(error.response.data)
        })

    }

    return (
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>
            
            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />

            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:30}}>
                    <Text style={mainStyles.title}>{t('profileContactListTitle')}</Text>
                    <Text style={mainStyles.titleText}>{t('profileContactListText')}</Text>
                </View>
            
                <View style={{marginBottom:20}}>
                    <PersonList data={contacts} isContacts={true} del={handleDeleteContact} navigation={navigation} navigateTo="Kontaktperson hinzufügen" />
                </View>

                <Pressable onPress={() => navigation.navigate('Kontaktperson hinzufügen')} style={mainStyles.primaryButton}>
                    <Text style={mainStyles.primaryButtonText}>{t('profileContactListButton')}</Text>
                </Pressable>
                
                <View style={mainStyles.buttonContainer}>
                    <Pressable onPress={() => navigation.navigate('Registrierung abschliessen')} style={mainStyles.secondaryButton}>
                        <Text style={mainStyles.secondaryButtonText}>{t('continue')}</Text>
                    </Pressable>
                </View>

            </View>

        </ScrollView>
    )

}

export default ProfileContactsScreen