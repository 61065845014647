import React, { useContext } from 'react'
import { View, Text, Pressable } from 'react-native'
import Constants from 'expo-constants'

import { LocalizationContext } from '../scripts/LocalizationContext'
import { AppContext } from '../scripts/AppContext'

function FooterMenu({ navigation }) {

    const { mainStyles } = useContext(AppContext)
    const { t } = useContext(LocalizationContext)

    return (
        <View style={{marginBottom: 30}}>
            <View style={mainStyles.footerMenu}>
                <Text style={mainStyles.footerText}>{ t('copyright') } • </Text>
                <Pressable onPress={() => navigation.navigate('AGB')}>
                    <Text style={mainStyles.footerText}>{ t('agb') }</Text>
                </Pressable>
                <Text style={mainStyles.footerText}> • </Text>
                <Pressable onPress={() => navigation.navigate('Datenschutzerklärung')}>
                    <Text style={mainStyles.footerText}>{ t('dataPrivacy') }</Text>
                </Pressable>
            </View>
            <View style={[mainStyles.footerMenu, { marginTop: 5}]}>
                <Text style={mainStyles.footerText}>{t('appversion')}: { Constants.manifest.version }</Text>
            </View>
        </View>
    )

}

export default FooterMenu