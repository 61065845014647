import React, { useState, useContext, useRef } from 'react'
import { View, ScrollView, Text, Pressable, KeyboardAvoidingView, Platform } from 'react-native'
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'

import Checkbox from '../components/Checkbox'
import FooterMenu from '../components/FooterMenu'
import Input from '../components/Input'
import { MultiPicker } from '../components/MultiPicker'

import { LocalizationContext } from '../scripts/LocalizationContext'
import { UserContext } from '../scripts/UserContext'
import { AppContext } from '../scripts/AppContext'

function RegisterScreen({ navigation }) {

    const { mainStyles } = useContext(AppContext)
    const { t, locale } = useContext(LocalizationContext)
    const { register } = useContext(UserContext)

    const [inputs, setInputs] = useState({
        user_role: locale.startsWith('de') ? 2 : 1,
        email: '',
        password: '',
        password_confirmation: '',
        agbs: false,
        data_privacy: false,
    })

    const refAlerts = useRef()
    
    const refPassword = useRef()
    const refPasswordConfirmation = useRef()

    return (
        <KeyboardAvoidingView enabled={Platform.OS === 'ios'} behavior="padding" style={mainStyles.keyboardContent} keyboardVerticalOffset={80}>
        <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.scrollContainer}>

            <View style={mainStyles.screenContainer}>

                <View style={{marginBottom:30}}>
                    <Text style={mainStyles.title}>{t('registerTitle')}</Text>
                    <Text style={mainStyles.titleText}>{t('registerText')}</Text>
                </View>

                <View>
                    <View style={mainStyles.multiSelectContainer}>
                        <Text style={mainStyles.label}>{t('registerCategoryLabel')}</Text>
                        <MultiPicker multi={false} options={[
                            {label: t('registerCategoryBK'), value: 1},
                            {label: t('registerCategoryPB'), value: 2}
                        ]} name="user_role" state={inputs} setState={setInputs} style={mainStyles.select} />
                    </View>
                    <Input type="email-address" name="email" state={inputs} setState={setInputs} nextFocus={refPassword} placeholder="E-Mail" icon={faEnvelope} />
                    <Input reference={refPassword} secure={true} name="password" state={inputs} setState={setInputs} nextFocus={refPasswordConfirmation} placeholder={t('fieldPassword')} icon={faLock} />
                    <Input reference={refPasswordConfirmation} secure={true} name="password_confirmation" state={inputs} setState={setInputs} placeholder={t('fieldPasswordRepeat')} icon={faLock} />
                </View>

                <View style={{marginTop:10, marginBottom:40}}>
                    <View style={{marginBottom: 15}}>
                        <Checkbox name="agbs" state={inputs} setState={setInputs} label={t('registerAGBInfo')} navigation={navigation} linkTo="AGB" linkToText={t('agb')} />
                    </View>
                    <View>
                        <Checkbox name="data_privacy" state={inputs} setState={setInputs} label={t('registerDataPrivacyInfo')} navigation={navigation} linkTo="Datenschutzerklärung" linkToText={t('dataPrivacy')} />
                    </View>
                </View>

                <View style={mainStyles.buttonContainer}>
                    <Pressable onPress={() => register(inputs)} style={mainStyles.primaryButton}>
                        <Text style={mainStyles.primaryButtonText}>{t('registerButton')}</Text>
                    </Pressable>
                </View>
                
                <FooterMenu navigation={navigation} />

            </View>

        </ScrollView>
        </KeyboardAvoidingView>
    )

}

export default RegisterScreen