import React, { createContext, useState, useMemo } from 'react'
import * as Localization from 'expo-localization'
import i18n from 'i18n-js'

import { de } from '../assets/translations/de'
import { pl } from '../assets/translations/pl'

i18n.translations = {
    de: de,
    pl: pl,
}

i18n.defaultLocale = 'de'
i18n.locale = Localization.locale
i18n.fallbacks = true


export const LocalizationContext = createContext(null)

export default ({children}) => {

    const [locale, setLocale] = useState(Localization.locale);

    const data = useMemo(
        () => ({
            t: (scope, options) => i18n.t(scope, { locale, ...options }),
            locale,
            setLocale,
        }),
        [locale]
    )

    return (
        <LocalizationContext.Provider value={data}>
            {children}
        </LocalizationContext.Provider>
    )
    
}