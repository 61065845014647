import React, { useEffect, useState, useRef, useContext } from 'react'
import { View, ScrollView, Text, TextInput, Pressable, Keyboard, KeyboardAvoidingView, Platform, Linking } from 'react-native'
import Axios from 'axios'
import moment from 'moment'
import * as ImagePicker from 'expo-image-picker'
import * as ImageManipulator from 'expo-image-manipulator'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import Loader from '../../components/Loader'
import AuthImage from '../../components/AuthImage'
import { authAxios } from '../../scripts/Axios'
import { calculateSize } from '../../scripts/Helpers'
import { AppContext } from '../../scripts/AppContext'
import { LocalizationContext } from '../../scripts/LocalizationContext'

function ChatScreen() {

    const { t } = useContext(LocalizationContext)
    const { showAlert, colors, mainStyles } = useContext(AppContext)
    const { showActionSheetWithOptions } = useActionSheet()

    const chatScroll = useRef(null)
    const [loading, setLoading] = useState(true)
    const [lastTime, setLastTime] = useState(moment())
    const [messages, setMessages] = useState([])
    const [groupedMessages, setGroupedMessages] = useState([])
    const [message, setMessage] = useState({
        text: '',
        attachments: [],
    })

    const imageQuality = .4
    const imageCompression = .7

    const cancelSource = Axios.CancelToken.source()

    useInterval(() => updateChat(), 3000)

    useEffect(() => {

        const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', _keyboardDidHide)
        const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', _keyboardDidShow)

        authAxios.get('chat', {cancelToken: cancelSource.token})
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(response.data.message)
            }else{

                let chats = response.data.chats
                if(chats.length){
                    setMessages(chats)
                }

            }

        })
        .catch(error => {
            console.log(error)
        })

        return () => {
            keyboardDidHideListener.remove()
            keyboardDidShowListener.remove()
            cancelSource.cancel('get chat canceled')
        }

    }, [])


    function _keyboardDidHide () {
        scrollDown(500)
    }

    function _keyboardDidShow () {
        scrollDown(500)
    }


    useEffect(() => {

        let chatGroups = messages.reduce((r, a) => {
            let date = moment(a.created_at).format('DD.MM.YYYY')
            r[date] = [...r[date] || [], a]
            return r
        }, {})

        setGroupedMessages(chatGroups)

    }, [messages])



    useEffect(() => {
        scrollDown(100)
    }, [groupedMessages])


    function scrollDown(delay){
        setTimeout(() => {
            if(chatScroll && chatScroll.current){
                chatScroll.current.scrollToEnd({animated:true})
            }
        }, delay)
    }

    function updateChat(){

        authAxios.put('chat/updates', {
            lasttime: moment(lastTime).format('YYYY-MM-DD HH:mm:ss'),
            cancelToken: cancelSource.token,
        })
        .then(response => {
            
            if(response.data.type !== 'error'){

                let newMessages = response.data.chats

                if(newMessages.length){
                    let newestMsg = newMessages[newMessages.length-1]
                    let newTime = moment(newestMsg.created_at).format('YYYY-MM-DD HH:mm:ss')

                    setLastTime(newTime)
                    setMessages([
                        ...messages,
                        ...newMessages,
                    ])
                }

            }

        })
        .catch(error => {
           // console.log(error)
        })

    }

    
    function useInterval(callback, delay) {
        const savedCallback = useRef();
      
        useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
      
        useEffect(() => {
          function tick() {
            savedCallback.current();
          }
          if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
          }
        }, [delay]);
    }


    function sendMessage(){

        if(message.text === ''){ return }

        setLoading(true)

        authAxios.post('chat', {
            typ: 'text',
            inhalt: message.text.toString(),
            attachments: message.attachments,
            cancelToken: cancelSource.token,
        })
        .then(response => {

            setLoading(false)

            if(response.data.type === 'error'){
                showAlert(response.data.message)
            }else{

                updateChat()
                setMessage({
                    text: '',
                    attachments: [],
                })

            }

        })
        .catch(error => {
            console.log(error.response.data)
        })
    }

    async function sendImage(result){

        let targetSize = calculateSize(1024, result.width, result.height)                
        let resizedImage = await ImageManipulator.manipulateAsync(
            result.uri,
            [{ resize: targetSize }],
            {
                compress: imageCompression,
                format: ImageManipulator.SaveFormat.JPEG
            }
        )
        if(resizedImage){
            result.uri = resizedImage.uri
            result.width = resizedImage.width
            result.height = resizedImage.height

            let data = makeFormData(result)

            authAxios.post('chat-upload', data, {
                onUploadProgress: progress => {
                    //let percentCompleted = Math.round((progress.loaded * 100) / progress.total)
                    //console.log('chat upload:', percentCompleted, '%')
                }
            })
            .then(response => {

                if(response.data.type === 'error'){
                    showAlert(t('error'), response.data.message)
                }else{
                    console.log(response.data)
                }

            })
            .catch(error => {
                console.log(error)
            })
            .then(() => {
                setLoading(false)
            })


        }

    }

    function makeFormData(file){

        let data = new FormData()
        let uriParts = file.uri.split('/');
        let fileName = uriParts[uriParts.length - 1];
        let fileNameParts = fileName.split('.');
        let fileExt = fileNameParts[fileNameParts.length - 1];
        let fileType = file.type + '/' + fileExt
        let newFile = {
            uri: file.uri,
            name: fileName,
            type: fileType
        }
        data.append('file', newFile)
        return data

    }

    function showActionSheet(){ 
        if(Platform.OS === 'web'){
            showActionSheetWithOptions(
                {
                    options: [t('profileMediaChooseImageButton'), t('cancel')],
                    cancelButtonIndex: 1,
                    destructiveButtonIndex: null
                },
                (buttonIndex) => {
                    selectAction(buttonIndex + 1)
                }
            )
        }else{
            showActionSheetWithOptions(
                {
                    options: [t('profileMediaMakeImageButton'), t('profileMediaChooseImageButton'), t('cancel')],
                    cancelButtonIndex: 2,
                    destructiveButtonIndex: null
                },
                (buttonIndex) => {
                    selectAction(buttonIndex)
                }
            )
        }
    }


    async function selectAction(index){

        if(index === 0){

            let result = await ImagePicker.launchCameraAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsMultipleSelection: false,
                quality: imageQuality,
            })
            if (!result.cancelled) {
                showAlert(t('alertSendImageTitle'), t('alertSendImage'), [
                    { text: t('cancel'), style: 'cancel' },
                    { text: t('send'), style: 'default', onPress: () => sendImage(result) },
                ])
            }

        }
        else if(index === 1){

            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsMultipleSelection: false,
                quality: imageQuality,
            })
            if (!result.cancelled) {
                showAlert(t('alertSendImageTitle'), t('alertSendImage'), [
                    { text: t('cancel'), style: 'cancel' },
                    { text: t('send'), style: 'default', onPress: () => sendImage(result) },
                ])
            }

        }

    }

    function convertLinks(msg){
        let messageArray = msg.split(/\s+/)
        return messageArray.map(word => {
            if(word.substring(0, 4) === 'http'){
                return <Text key={word} onPress={() => Linking.openURL(word)}><Text style={{ textDecorationLine: 'underline' }}>{ word }</Text> </Text>
            }else{
                return <Text key={word}>{ word } </Text>
            }
        })
    }

    return (
        <View style={{flex:1, backgroundColor: colors.white}}>

            <Loader visible={loading} background={colors.transWhite} color={colors.darkGrey} />
                
            <ScrollView ref={chatScroll} keyboardShouldPersistTaps="handled" contentContainerStyle={mainStyles.chatScrollContainer}>

                <View style={Platform.OS === 'web' ? mainStyles.screenContainer : null}>

                    <View style={{marginBottom: 30}}>                    
                        <Text style={[mainStyles.title, {textAlign:'left'}]}>{t('messagesChatTitle')}</Text>
                        <Text style={[mainStyles.text, {marginBottom:10}]}>{t('messagesChatText1')}</Text>
                        <Text style={mainStyles.text}>{t('messagesChatText2')}</Text>
                    </View>

                    { groupedMessages &&
                        Object.keys(groupedMessages).map(date => {
                            return (

                                <View key={date}>
                                    <Text style={mainStyles.chatDate}>{ date }</Text>
                                    {
                                    groupedMessages[date].map(msg => {
                                        let isUser = msg.von === 'user' ? true : false
                                        return (
                                            <View key={msg.id} style={[mainStyles.chatLine, {alignSelf: isUser ? 'flex-end' : 'flex-start'}]}>

                                                {isUser ? <Text style={mainStyles.chatTime}>{ moment(msg.created_at).format('HH:mm') }</Text> : null }

                                                <View style={[mainStyles.chatItem, {padding: msg.typ === 'text' ? 10 : 0, backgroundColor: isUser && msg.typ !== 'datei' ? colors.primary : colors.mediumGrey}]}>
                                                    {msg.typ === 'text' ? <Text style={[mainStyles.chatItemText, {color: colors.white}]}>{ convertLinks(msg.inhalt) }</Text> : null }
                                                    {msg.typ === 'datei' &&
                                                    <Pressable>
                                                        <AuthImage image={{id: msg.datei}} width={250} height={250} chat={true} />
                                                    </Pressable>
                                                    }
                                                </View>

                                                {!isUser ? <Text style={mainStyles.chatTime}>{ moment(msg.created_at).format('HH:mm') }</Text> : null }

                                            </View>
                                        )
                                    })
                                    }
                                </View>

                            )
                        })
                    }

                </View>

            </ScrollView>

            <KeyboardAvoidingView behavior={ Platform.OS === 'android' ? 'height' : 'padding' } style={mainStyles.chatContainer} keyboardVerticalOffset={225}>

                <View style={mainStyles.chatInputContainer}>
                    <Pressable hitSlop={10} onPress={() => showActionSheet()} style={mainStyles.addAttachmentIcon}>
                        <FontAwesomeIcon icon={faPaperclip} size={24} color={colors.mediumGrey} />
                    </Pressable>
                    <TextInput multiline={false} value={message.text} onChangeText={value => setMessage({...message, text: value})} placeholder={t('messagesChatInput')} placeholderTextColor={colors.mediumGrey} style={[mainStyles.chatInput, Platform.OS === 'web' ? { outlineWidth: 0 } : null]} />
                    <Pressable onPress={sendMessage} style={mainStyles.chatButton}>
                        <FontAwesomeIcon icon={faPaperPlane} size={20} color={colors.white} />
                    </Pressable>
                </View>

            </KeyboardAvoidingView>

         </View>
    )

}

export default ChatScreen