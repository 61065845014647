export const de = {
    // General
    "appversion": "App-Version",
    "yes": "Ja",
    "no": "Nein",
    "cancel": "Abbrechen",
    "save": "Speichern",
    "delete": "Löschen",
    "continue": "Weiter",
    "back": "Zurück",
    "accept": "Akzeptieren",
    "decline": "Ablehnen",
    "send": "Senden",
    "discard": "Abbrechen",
    "discardContinue": "Weitermachen",
    "error": "Fehler",
    "multiselect": "Mehrfachauswahl möglich",
    "refreshInfo": "Zum Aktualisieren nach unten ziehen",
    "age": "Jahre",
    "day": "Tag",
    "month": "Monat",
    "year": "Jahr",
    "BK": "Betreuungskraft",
    "PB": "Pflegebedürftiger",
    "PB2": "pflegebedürftige Person",
    "PB3": "zu betreuende Person",
    "copyright": "2021 © Senihelp24",
    "agb": "AGBs",
    "dataPrivacy": "Datenschutzerklärung",
    "loadingText": "Lädt ...",
    "noFiles": "Keine Dateien vorhanden",
    "createdAt": "Erstellt am",
    "maxFilesize": "Max. Dateigröße:",
    "login": "Login",
    "logout": "Ausloggen",
    "logoutQuestion": "Möchten Sie sich wirklich ausloggen?",
    "startdate": "Startdatum",
    "from": "vom",
    "until": "bis zum",
    "later": "Später",
    "at": "um",
    "clock": "Uhr",
    "isNotAvailable": "Nicht verfügbar",
    "isAvailable": "Verfügbar",
    "isRequested": "Angefragt",
    "isRequestedBK": "Angestupst",
    "statusLabel": "Ihr Status",
    "statusNone": "Kein Status",
    "statusBronze": "Bronze-Status",
    "statusSilver": "Silber-Status",
    "statusGold": "Gold-Status",
    "in": "in",
    "atDate": "am",
    "instantly": "ab sofort",
    "match": "Match",
    "person": "Person",
    "noMatch": "Keine Übereinstimmung",
    "chooseTheme": "Theme auswählen",
    "themeLight": "Hell",
    "themeDark": "Dunkel",
    "careLevel": "Pflegegrad",
    "image": "Bild",
    // Countries
    "germany": "Deutschland",
    "poland": "Polen",
    // Fields
    "fieldEmail": "E-Mail",
    "fieldPassword": "Passwort",
    "fieldPasswordRepeat": "Passwort wiederholen",
    "fieldMobile": "Handynummer",
    "fieldContactTypeLabel": "Ich bin ...",
    "fieldContactType1": "Gesetzlicher Betreuer",
    "fieldContactType1": "Hauptkontakt",
    "fieldContactType2": "Rechnungsempfänger",
    "fieldSalutationLabel": "Anrede",
    "fieldSalutation1": "Herr",
    "fieldSalutation2": "Frau",
    "fieldSalutation3": "Divers",
    "fieldTitle": "Titel",
    "fieldName": "Vorname",
    "fieldSurname": "Nachname",
    "fieldBirth": "Geburtsdatum",
    "fieldSize": "Größe (cm)",
    "fieldWeight": "Gewicht (kg)",
    "fieldNationality" : "Nationalität",
    "fieldPersonalStateLabel": "Familienstand",
    "fieldPersonalState1": "ledig",
    "fieldPersonalState2": "verheiratet",
    "fieldPersonalState3": "geschieden",
    "fieldPersonalState4": "verwitwet",
    "fieldStreet": "Strasse",
    "fieldStreetNr": "Nummer",
    "fieldPLZ": "PLZ",
    "fieldCity": "Ort",
    "fieldCountry": "Land",
    "fieldPhone": "Telefon",
    "fieldPerMail": "per E-Mail",
    "fieldPerSMS": "per SMS",
    // Alerts
    "alertInvalidLoginTitle": "Fehler",
    "alertInvalidLogin": "Diese Logindaten sind nicht korrekt. Bitte überprüfen Sie Ihre Eingaben.",
    "alertPasswordChangedTitle": "Erfolg",
    "alertPasswordChanged": "Ihr Passwort wurde gespeichert. Sie können sich jetzt einloggen.",
    "alertDiscardChangesTitle": "Eingaben verwerfen?",
    "alertDiscardChanges": "Sie sind nur wenige Minuten vom Ziel entfernt! Wenn Sie jetzt abbrechen, müssen Sie das nächste Mal von Vorne beginnen.",
    "alertMissingFieldsTitle": "Fehler",
    "alertMissingFields": "Bitte füllen Sie die Felder %{fields} aus.",
    "alertMissingProfilesTitle": "Fehler",
    "alertMissingProfiles": "Bitte erstellen Sie mindestens 1 Profil.",
    "alertDeleteProfileTitle": "Profil löschen",
    "alertDeleteProfile": "Möchten Sie dieses Profil wirklich löschen?",
    "alertNoMediaPermissionTitle": "Fehler",
    "alertNoMediaPermission": "Die App benötigt die Berechtigung auf Ihre Mediathek/Fotos zuzugreifen. Bitte ändern Sie diese Option in Ihren Handyeinstellungen.",
    "alertNoCameraPermissionTitle": "Fehler",
    "alertNoCameraPermission": "Die App benötigt die Berechtigung Ihre Kamera zu verwenden. Bitte ändern Sie diese Option in Ihren Handyeinstellungen.",
    "alertMissingMediaBKTitle": "Fehler",
    "alertMissingMediaBK": "Bitte laden Sie mindestens 1 Foto von sich hoch.",
    "alertMissingMediaPBTitle": "Fehler",
    "alertMissingMediaPB": "Bitte laden Sie in jeder Kategorie mindestens 1 Bild bzw. Video hoch. In der App (über Smartphone) können Sie hier direkt Fotos/ Video aufnehmen.",
    "alertDeleteContactTitle": "Kontaktperson löschen",
    "alertDeleteContact": "Möchten Sie diese Kontaktperson wirklich löschen?",
    "alertDeleteFileTitle": "Datei löschen",
    "alertDeleteFile": "Möchten Sie diese Datei wirklich löschen?",
    "alertInvalidStartDateTitle": "Fehler",
    "alertInvalidStartDate": "Bitte geben Sie ein gültiges Startdatum ein.",
    "alertSaveStartDateTitle": "Sicher?",
    "alertSaveStartDate": "Ist der %{date} Ihr gewünschtes Startdatum?",
    "alertSendRequestTitle": "Anfrage senden",
    "alertSendRequest": "Das Absenden einer Anfrage ist verbindlich. Sie können maximal 3 Anfragen gleichzeitig senden. Wollen Sie diese Anfrage wirklich abschicken?",
    "alertSendPokeTitle": "Anstupsen",
    "alertSendPoke": "Wollen Sie diese Betreuungskraft wirklich anstupsen? Sie können maximal 3 Betreuungskräfte pro Tag anstupsen.",
    "alertRequestSentTitle": "Anfrage gesendet",
    "alertRequestSent": "Ihre Anfrage wurde versendet. Die Person hat ab sofort 1 Tag Zeit um Ihre Anfrage zu beantworten.",
    "alertPokeSentTitle": "Erfolg!",
    "alertPokeSent": "Sie haben die Betreuungskraft angestupst. Mal sehen, ob sie Ihnen eine Anfrage schickt. Wenn ja, erhalten Sie umgehend eine Nachricht.",
    "alertDeclineRequestTitle": "Anfrage ablehnen",
    "alertDeclineRequest": "Möchten Sie diese Anfrage wirklich ablehnen?",
    "alertAcceptRequestTitle": "Anfrage akzeptieren",
    "alertAcceptRequest": "Möchten Sie diese Anfrage verbindlich akzeptieren? Wir melden uns umgehend, um das weitere Vorgehen abzustimmen!",
    "alertRequestDeclinedTitle": "Anfrage abgelehnt",
    "alertRequestDeclined": "Sie haben die Anfrage abgelehnt.",
    "alertRequestAcceptedTitle": "Anfrage akzeptiert",
    "alertRequestAccepted": "Sie haben die Anfrage akzeptiert. In Kürze meldet sich ein Mitarbeiter, um das weitere Vorgehen abzustimmen.",
    "alertSendImageTitle": "Senden?",
    "alertSendImage": "Möchten Sie dieses Bild an Senihelp24 senden?",
    "alertRatingDoneTitle": "Fertig",
    "alertRatingDone": "Ihre Bewertung wurde gespeichert.",
    "alertDeleteAccountTitle": "Konto löschen",
    "alertDeleteAccount": "Möchten Sie Ihr Konto inkl. aller Daten wirklich löschen?",
    // Login Screen
    "loginForgotPassword": "Passwort vergessen?",
    "loginStayLoggedIn": "Eingeloggt bleiben",
    "loginNoAccount": "Noch keinen Account?",
    "loginButton": "Einloggen",
    "loginRegisterButton": "Jetzt registrieren",
    // Forgot Password Screen
    "forgotPasswordScreen": "Passwort vergessen",
    "forgotPasswordTitle": "Passwort vergessen?",
    "forgotPasswordText": "Kein Problem, hier können Sie ein neues Passwort vergeben. Zur Verifizierung erhalten Sie einen Code. Wie sollen wir diesen senden?",
    "forgotPasswordButton": "Passwort zurücksetzen",
    // Forgot Password Verify Screen
    "forgotPasswordVerifyScreen": "Passwort vergessen",
    "forgotPasswordVerifyTitle": "Verifizierung",
    "forgotPasswordVerifyEmailText": "Sie haben eine E-Mail mit einem Code von uns erhalten. Bitte geben Sie den Zahlencode hier ein.",
    "forgotPasswordVerifySMSText": "Sie haben eine SMS mit einem Code von uns erhalten. Bitte geben Sie den Zahlencode hier ein.",
    "forgotPasswordVerifyCodeText": "Code eingeben:",
    "forgotPasswordVerifyButton": "Verifizieren",
    // Reset Password Screen
    "resetPasswordScreen": "Passwort zurücksetzen",
    "resetPasswordTitle": "Neues Passwort vergeben",
    "resetPasswordText": "Geben Sie nun Ihr neues Passwort ein.",
    "resetPasswordPlaceholder": "Neues Passwort",
    "resetPasswordPlaceholderRepeat": "Neues Passwort wiederholen",
    "resetPasswordButton": "Neues Passwort speichern",
    // Register Screen
    "registerScreen": "Registrierung",
    "registerTitle": "Jetzt registrieren",
    "registerText": "Registrieren Sie sich jetzt kostenlos bei Senihelp24 und definieren Sie Ihre persönlichen Anforderungen.",
    "registerCategoryLabel": "Ich bin ...",
    "registerCategoryBK": "Betreuungskraft",
    "registerCategoryPB": "Pflegebedürftiger / Kontaktperson",
    "registerAGBInfo": "Ich habe die AGBs gelesen und stimme zu",
    "registerDataPrivacyInfo": "Ich habe die Datenschutzerklärung gelesen und stimme zu",
    "registerButton": "Jetzt registrieren",
    // Account Confirmation Screen
    "accountConfirmationScreen": "Konto Verifizierung",
    "accountConfirmationTitle": "Verifizierung",
    "accountConfirmationText": "Datenschutz ist uns sehr wichtig!",
    "accountConfirmationText2": "Bitte bestätigen Sie Ihre Registrierung. Zur Verifizierung erhalten Sie einen Code.",
    "accountConfirmationText3": "Wie sollen wir diesen senden?",
    "accountConfirmationButton": "Bestätigung senden",
    // Account Confirmation Verify Screen
    "accountConfirmationVerifyScreen": "Konto Verifizierung",
    "accountConfirmationVerifyTitle": "Verifizierung",
    "accountConfirmationVerifySMSText": "Sie haben eine SMS mit einem Code von uns erhalten. Bitte geben Sie den Zahlencode hier ein.",
    "accountConfirmationVerifyEmailText": "Sie haben eine E-Mail mit einem Code von uns erhalten. Bitte geben Sie den Zahlencode hier ein.",
    "accountConfirmationVerifyCodeText": "Code eingeben:",
    "accountConfirmationVerifyButton": "Verifizieren",
    // Profile Intro Screen
    "profileIntroScreen": "Konto verifiziert",
    "profileIntroTitle": "Willkommen bei Senihelp24",
    "profileIntroText": "Sie können nun mit der Eingabe Ihrer persönlichen Daten fortfahren.",
    "profileIntroInfoBar": "Konto verifiziert",
    "profileIntroListBKTitle": "Wir werden nacheinander folgende Daten über Sie abfragen:",
    "profileIntroListBK1": "Ihre Stammdaten",
    "profileIntroListBK2": "Angaben zu gewünschten Pflegepersonen",
    "profileIntroListBK3": "Bilder von Ihnen",
    "profileIntroListBK4": "Ihre Referenzen",
    "profileIntroListPBTitle": "Wir werden nacheinander folgende Daten über Sie und die zu betreuende(n) Person(en) abfragen:",
    "profileIntroListPB1": "Ihre Stammdaten",
    "profileIntroListPB2": "Die Stammdaten und Gesundheitsdaten der betreuenden Person(en)",
    "profileIntroListPB3": "Die Adresse der zu betreuenden Person(en)",
    "profileIntroListPB4": "Bilder und Videos der zu betreuenden Person(en)",
    "profileIntroListPB5": "Weitere Kontakperson(en)",
    "profileIntroListPB6": "Gewünschter Betreuungsbeginn",
    "profileIntroButton": "Daten eingeben",
    // Profile PersonalData Screen
    "profilePersonalDataPBScreen": "Stammdaten",
    "profilePersonalDataPBTitle": "Persönliche Daten",
    "profilePersonalDataPBText": "Geben Sie bitte zunächst an wer Sie sind.",
    // Profile PersonalData BK Screen
    "profilePersonalDataBKScreen": "Stammdaten",
    "profilePersonalDataBKTitle": "Persönliche Daten",
    "profilePersonalDataBKText": "Geben Sie bitte zunächst alle Stammdaten von Ihnen ein.",
    // Profile PB List Screen
    "profilePBListScreen": "Profile",
    "profilePBListTitle": "Pflegebedürftige Personen",
    "profilePBListText": "Erstellen Sie das Profil der pflegebedürftigen Person.",
    "profilePBListText2": "Gibt es eine weitere Person im Haushalt? Bitte erstellen Sie hier das Profil der weiteren Person, auch wenn Sie nicht pflegebedürftig ist.",
    "profilePBListNoProfiles": "Sie haben noch kein Profil angelegt.",
    "profilePBListProfileButton": "Profil anlegen",
    "profilePBListProfileButton2": "Weiteres Profil anlegen",

    // Profile Add PB-General Screen
    "profileAddPBScreen": "Profil hinzufügen",
    "profileAddPBTitle": "Allgemeine Daten",
    "profileAddPBText": "Geben Sie hier bitte allgemeine Daten der pflegebedürftigen Person ein.",
    "profileAddPB1Title": "Gesundheitsdaten",
    "profileAddPB1Text": "Geben Sie hier bitte die Gesundheitsdaten der pflegebedürftigen Person ein.",
    "profileAddPB2Title": "Gesundheitsdaten 2",
    "profileAddPB2Text": "Geben Sie hier bitte die Gesundheitsdaten der pflegebedürftigen Person ein.",
    "profileAddPB3Title": "Gesundheitsdaten 3",
    "profileAddPB3Text": "Geben Sie hier bitte die Gesundheitsdaten der pflegebedürftigen Person ein.",
    "profileAddPB4Title": "Betreuungs-Daten",
    "profileAddPB4Text": "Geben Sie hier bitte die Betreuungs-Daten der pflegebedürftigen Person ein.",
    "profileAddPB5Title": "Wohnsituation",
    "profileAddPB5Text": "Bitte machen Sie hier Angaben zum häuslichen Umfeld der pflegebedürftigen Person.",
    "profileAddPB6Title": "Persönlichkeit",
    "profileAddPB6Text": "Bitte geben Sie die genauen Daten zum Charakter der pflegebedürftigen Person ein.",
    "profileAddPB7Title": "Sonstige Anforderungen",
    "profileAddPB7Text": "Geben Sie hier bitte die Anforderungen der pflegebedürftigen Person ein.",
    "profileAddPB8Title": "Stellenbeschreibung",
    "profileAddPB8Text": "Beschreiben Sie möglichst umfassend, wie das tägliche Miteinander gestaltet werden soll.",
    // Profile Add BK-General Screen
    "profileAddBKScreen": "Profil hinzufügen",
    "profileAddBK1Title": "Gesundheitsdaten",
    "profileAddBK1Text": "Welche gesundheitlichen Probleme können Sie übernehmen?",
    "profileAddBK2Title": "Gesundheitsdaten 2",
    "profileAddBK2Text": "Welche gesundheitlichen Probleme können Sie übernehmen?",
    "profileAddBK3Title": "Gesundheitsdaten 3",
    "profileAddBK3Text": "Welche gesundheitlichen Probleme können Sie übernehmen?",
    "profileAddBK4Title": "Betreuungs-Daten",
    "profileAddBK4Text": "Welche gesundheitlichen Probleme können Sie übernehmen?",
    "profileAddBK5Title": "Wohnsituation",
    "profileAddBK5Text": "Bitte machen Sie hier Angaben, in welchem häuslichen Umfeld Sie arbeiten möchten.",
    "profileAddBK6Title": "Persönlichkeit",
    "profileAddBK6Text": "Welche Charaktereigenschaften der pflegebedürftigen Personen können Sie aktzeptieren?",
    "profileAddBK7Title": "Sonstige Anforderungen",
    "profileAddBK7Text": "Welche Anforderungen sind Sie bereit zu erfüllen?",
    "profileAddBK8Title": "Freitext – über mich",
    "profileAddBK8Text": "Erzählen Sie hier ein bisschen über sich selbst, über Ihren Charakter und Ihre Hobby's.",

    // Profile Add-1 Screen
    "profileAddCareLevelLabel": "Die zu betreuende Person hat",
    "profileAddMovementLabel": "Die Beweglichkeit der zu betreuenden Person ist",
    // Profile Add-2 Screen
    "profileAddSicknessLabel": "Krankheiten / Gesundheitliche Einschränkungen mit Auswirkung auf die Betreuung",
    "profileAddCureLabel": "Ist eine Verbesserung des aktuellen Zustandes zu erwarten?",
    "profileAddOtherSicknessLabel": "Bitte geben Sie sonstige gesundheitliche Einschränkungen ein",
    "profileAddOtherSickness": "Sonstige Einschränkungen",
    // Profile Add-3 Screen
    "profileAddSenseLabel": "Die Sinneswahrnehmung der zu betreuenden Person",
    "profileAddMentalLabel": "Der Geisteszustand der zu betreuenden Person",
    "profileAddSleepLabel": "Das Schlafverhalten der zu betreuenden Person",
    // Profile Add-4 Screen
    "profileAddEatingLabel": "Beim Essen benötigt die zu betreuende Person",
    "profileAddBaseCareLabel": "Grundpflege / Körperhygiene der zu betreuenden Person erfolgt",
    "profileAddHygieneLabel": "Hygiene nach Toilettengang",
    "profileAddPeeLabel": "Urinkontrolle",
    "profileAddPooLabel": "Stuhlkontrolle",
    "profileAddClothingLabel": "Beim Ankleiden benötigt die zu betreuende Person",
    // Profile Add-5 Screen
    "profileAddLivingSituationLabel": "Wohnsituation der zu betreuenden Person",
    "profileAddLivingConditionLabel": "Zustand / Ausstattung des Haushaltes der zu betreuenden Person",
    "profileAddLivingPlaceLabel": "Der Wohnort der zu betreuenden Person",
    "profileAddPetsLabel": "Haustiere im Haushalt der zu betreuenden Person",
    "profileAddWhichPetsLabel": "Welche Haustiere?",
    "profileAddShoppingLabel": "Einkaufsmöglichkeiten zu Fuss erreichbar",
    "profileAddLivingBKLabel": "Sie stellen der Betreuungskraft folgendes zur Verfügung",
    // Profile Add-6 Screen
    "profileAddCharacterLabel": "Charaktereigenschaften der zu betreuenden Person",
    "profileAddSmokingLabel": "Die zu betreuende Person ist Raucher",
    // Profile Add-7 Screen
    "profileAddActivitiesBKLabel": "Neben Haushalt und Betreuung erwarte ich für die zu betreuende Person",
    "profileAddSexBKLabel": "Das Geschlecht der Betreuungskraft soll sein",
    "profileAddGermanBKLabel": "Die Deutschkenntnisse der Betreuungskraft sollen mindestens wie folgt sein",
    "profileAddSmokingBKLabel": "Die Betreuungskraft soll Nichtraucher sein",
    "profileAddDrivingBKLabel": "Die Betreuungskraft soll einen Führerschein und Fahrbereitschaft haben",
    // Profile Add-8 Screen
    "profileAddTextHobbiesLabel": "Tagesbeschäftigungen",
    "profileAddTextHobbies": "z.B. Zeitung lesen, TV/Radio, mit Familie/Freunden telefonieren",
    "profileAddTextEnvironmentLabel": "Soziales Umfeld",
    "profileAddTextEnvironment": "z.B. 2 Kinder mit Familie die regelmäßig zu Besuch kommen, guter Kontakt zu Nachbarn",
    "profileAddTextOtherLabel": "Sonstiges",
    "profileAddTextOther": "z.B. Oma legt großen Wert auf Ihr Äußeres, gute Umgangsformen sind wichtig, ist sehr sensibel und muss erst Vertrauen aufbauen",

    // Profile Add-1 Screen BK
    "profileAddCareLevelLabelBK": "Ich betreue Personen mit maximal",
    "profileAddMovementLabelBK": "Ich betreue Personen mit mindestens folgender Beweglichkeit",
    // Profile Add-2 Screen BK
    "profileAddSicknessLabelBK": "Ich betreue Personen mit folgenden gesundheitlichen Einschränkungen",
    // Profile Add-3 Screen BK
    "profileAddSenseLabelBK": "Ich betreue Personen mit folgender Sinneswahrnehmung",
    "profileAddMentalLabelBK": "Ich betreue Personen mit maximal folgendem Geisteszustand",
    "profileAddSleepLabelBK": "Ich betreue Personen mit maximal folgendem Schlafverhalten",
    // Profile Add-4 Screen BK
    "profileAddEatingLabelBK": "Ich betreue Personen mit maximal folgender Hilfe beim Essen",
    "profileAddBaseCareLabelBK": "Ich betreue Personen mit maximal folgender Grundpflege",
    "profileAddHygieneLabelBK": "Ich betreue Personen mit maximal folgender Hygiene nach Toilettengang",
    "profileAddPeeLabelBK": "Ich betreue Personen mit maximal folgender Urinkontrolle",
    "profileAddPooLabelBK": "Ich betreue Personen mit maximal folgender Stuhlkontrolle",
    "profileAddClothingLabelBK": "Ich betreue Personen mit maximal folgender Hilfe beim Ankleiden",
    // Profile Add-5 Screen BK
    "profileAddLivingSituationLabelBK": "Ich möchte in folgender Wohnsituation arbeiten",
    "profileAddLivingConditionLabelBK": "Ich möchte in Haushalten mit folgendem Zustand arbeiten",
    "profileAddLivingPlaceLabelBK": "Ich möchte in folgendem Wohnort arbeiten",
    "profileAddPetsLabelBK": "Ich kann mit Haustieren im Haushalt arbeiten",
    "profileAddWhichPetsLabelBK": "Welche Haustiere?",
    "profileAddShoppingLabelBK": "Einkaufsmöglichkeiten zu Fuss erreichbar",
    "profileAddLivingBKLabelBK": "Im Haushalt erwarte ich mindestens",
    // Profile Add-6 Screen BK
    "profileAddCharacterLabelBK": "Die Person kann folgende Charaktereigenschaften haben",
    "profileAddSmokingLabelBK": "Ich kann mit Personen arbeiten die Raucher sind",
    // Profile Add-7 Screen BK
    "profileAddActivitiesBKLabelBK": "Ich kann die zu betreuende Person",
    "profileAddSexBKLabelBK": "Das Geschlecht der zu betreuenden Person soll sein",
    "profileAddGermanBKLabelBK": "Meine Deutschkenntnisse sind",
    "profileAddSmokingBKLabelBK": "Sind Sie Raucher?",
    "profileAddDrivingBKLabelBK": "Haben Sie einen Führerschein?",
    // Profile Add-8 Screen BK    
    "profileAddTextBKLabel": "Sonstiges",
    "profileAddTextBK": "Ich bin eine föhliche und offene Person, die sich mit viel Herz um die Senioren kümmert. Bisher habe ich mit Demenz, Schlaganfall, Osteoporose ... meine Hobbys ...",

    // Profile Address Screen
    "profileAddressScreen": "Adresse",
    "profileAddressTitle": "Adressdaten",
    "profileAddressText": "Geben Sie hier bitte Telefon und Adresse der zu betreuenden Person(en) an",
    // Profile Media PB Screen
    "profileMediaScreen": "Medien",
    "profileMediaTitle": "Fotos und Videos hochladen",
    "profileMediaText": "1 Bild sagt mehr als 1000 Worte! Keine Sorge, diese Medien werden nur im internen Bereich und nur für verifizierte, passende Mitarbeiter/innen zugänglich sein.",
    "profileMediaCategory1": "Fotos der zu betreuenden Person(en)",
    "profileMediaCategory2": "Fotos der Wohnung",
    "profileMediaCategory3": "Video-Rundgang der Wohnung",
    "profileMediaImageInfo": "%{count} Bilder ausgewählt",
    "profileMediaVideoInfo": "%{count} Videos ausgewählt",
    "profileMediaActionLabel": "Aktion wählen",
    "profileMediaMakeImageButton": "Neues Foto machen",
    "profileMediaChooseImageButton": "Foto wählen …",
    "profileMediaMakeVideoButton": "Neues Video machen",
    "profileMediaChooseVideoButton": "Video wählen ...",
    "profileMediaButton": "Medien hochladen",
    // Profile Media BK Screen
    "profileMediaBKTitle": "Fotos hochladen",
    "profileMediaBKText": "1 Bild sagt mehr als 1000 Worte! Keine Sorge, diese Medien werden nur im internen Bereich und nur für verifizierte Kunden/innen zugänglich sein.",
    "profileMediaBKCategory": "Fotos von Ihnen",
    // Profile Contact List Screen
    "profilContactListScreen": "Kontaktpersonen",
    "profileContactListTitle": "Kontaktpersonen",
    "profileContactListText": "Sie haben sich als Kontaktperson angelegt und alle Angaben bezüglich der zu betreuenden Person(en) im System eingetragen. Sie können hier noch weitere Kontaktpersonen anlegen.",
    "profileContactListButton": "Weitere Kontaktperson anlegen",
    // Profile Add Contact Screen
    "profilAddContactScreen": "Kontaktpersonen hinzufügen",
    "profileAddContactTitle": "Kontaktperson",
    "profileAddContactText": "Geben Sie bitte folgende Informationen an.",
    // Profile References Screen
    "profilReferencesScreen": "Referenzen",
    "profilReferencesTitle": "Ihre Referenzen",
    "profilReferencesText": "Laden Sie hier Ihre Referenzen hoch, wie zum Beispiel einen Führerschein oder Zeugnisse.",
    "profilReferencesButton": "Datei wählen",
    // Profile Finish Screen
    "profileFinishScreen": "Registrierung abschliessen",
    "profileFinishTitle": "Wann soll es losgehen?",
    "profileFinishText": "Bitte geben Sie zum Abschluss noch das gewünschte Startdatum für den Betreuungsbeginn an. Sie können das Startdatum jederzeit verändern. Bitte rufen Sie uns dazu an.",
    "profileFinishDateLabel": "Startdatum",
    "profileFinishText2": "Sie wissen es noch nicht genau?! Dann geben Sie den nächsten Monatsersten an.",
    "profileFinishButton": "Anfrage abschliessen",
    // Account Approval Screen
    "accountApprovalScreen": "Konto-Freigabe",
    "accountApprovalTitle": "Ihr Konto wird geprüft",
    "accountApprovalText1": "Bitte warten Sie, bis wir Ihre Angaben überprüft haben. Bis dahin haben Sie eingeschränkten Zugriff auf die App-Inhalte.",
    "accountApprovalText2": "In Kürze wird Sie ein Mitarbeiter persönlich kontaktieren, um die weiteren Schritte der Zusammenarbeit zu besprechen.",
    "accountApprovalText3": "Vielen Dank, dass Sie sich für Senihelp24 entschieden haben.",
    "accountApprovalText4": "Vorschau der ersten 3 Betreuungskräfte",
    "accountApprovalText4BK": "Vorschau der ersten 3 pflegebedürftigen Personen",



    // Cares Screen
    "caresScreen": "Betreuungen",
    "caresTabEvents": "Termine",
    "caresTabSearch": "Suche",
    "caresTabRequests": "Anfragen",
    // Events
    "careEventsWelcome": "Willkommen",
    "careEventsWelcomeTextBK1": "Ihre Angaben wurden überprüft und das Betreuungs-Profil ist nun freigeschaltet.",
    "careEventsWelcomeTextBK2": "Sie können bis zu 3 Personen auf einmal eine Anfrage senden.",
    "careEventsWelcomeTextBK3": "Sobald die zu betreuende Person die Anfrage annimmt, nehmen wir mit Ihnen Kontakt auf, um die weitere Zusammenarbeit zu besprechen und den Arbeitsvertrag abzuschließen.",
    "careEventsWelcomeButtonBK": "Pflegebedürftige ansehen",
    "careEventsWelcomeTextPB1": "Ihre Angaben wurden überprüft und das Betreuungs-Profil ist nun freigeschaltet.",
    "careEventsWelcomeTextPB2": "Wie geht es weiter?",
    "careEventsWelcomeTextPB3": "Entsprechend Ihren Anforderungen sehen Sie hier Ihre Betreuungskräfte zur Auswahl. Sie können bis zu 3 Personen gleichzeitig anstupsen, um auf sich aufmerksam zu machen.",
    "careEventsWelcomeTextPB4": "Sobald Ihnen eine Betreuungskraft eine Anfrage sendet, können Sie entscheiden, ob Sie von dieser Person betreut werden möchten.",
    "careEventsWelcomeButtonPB": "Betreuungskräfte ansehen",
    "careEventsQuestionsText1": "Haben Sie Fragen?",
    "careEventsQuestionsText2": "Schreiben Sie uns per Chat:",
    "careEventsQuestionsButton": "Zum Chat",
    "careEventsCategory1": "Aktueller Termin",
    "careEventsCategory2": "Kommende Termine",
    "careEventsCategory3": "Abgeschlossene Termine",
    "careEventsCategory4": "Stornierte Termine",
    "careEventsStatusOrganized": "Betreuungstermin wird organisiert ...",
    "careEventsStatusCanceled": "Betreuungstermin storniert",
    "careEventsStatusConfirmed": "Betreuungstermin bestätigt",
    "careEventsStatusOld": "Betreuungstermin vergangen",
    "careEventsNoEvents": "Keine Termine vorhanden.",
    "careEventsAddress": "Adresse",
    "careEventsRatingTitle": "Bewerten Sie Ihre Betreuungskraft",
    "careEventsRatingText": "Ihre Meinung ist uns wichtig. Deshalb möchten wir Sie herzlich bitten, uns Ihre Erfahrung bezüglich der Zusammenarbeit mit unserem Betreuungspersonal mitzuteilen.",
    "careEventsRatingTitleBK": "Bewerten Sie die zu betreuende Person",
    "careEventsRatingTextBK": "Ihre Meinung ist uns wichtig. Deshalb möchten wir Sie herzlich bitten, uns Ihre Erfahrung bezüglich der Zusammenarbeit mit unserem Kunden mitzuteilen.",
    // Search
    "careSearchSortLabel": "Sortierung",
    "careSearchSort1": "Matching absteigend",
    "careSearchSort2": "Matching aufsteigend",
    "careSearchSort3": "Bewertung absteigend",
    "careSearchSort4": "Bewertung aufsteigend",
    "careSearchSort5": "Status absteigend",
    "careSearchSort6": "Status aufsteigend",
    "careSearchSort7": "Ort A-Z",
    "careSearchSort8": "Ort Z-A",
    "careSearchSort9": "Frühestes Startdatum",
    "careSearchSort10": "Spätestes Startdatum",
    "careSearchSort11": "Früheste Verfügbarkeit",
    "careSearchSort12": "Späteste Verfügbarkeit",
    // Requests
    "careRequestsNoRequests": "Keine Anfragen vorhanden.",
    "careRequestsPokeCount": "Verbleibende Anstupser",
    "careRequestsRequestCount": "Verbleibende Anfragen",
    "careRequestsOld": "Alte Anfragen",
    "requestStatusPending": "Anfrage beantworten",
    "requestStatusDeclined": "Anfrage abgelehnt",
    "requestStatusAccepted": "Anfrage angenommen",
    "requestAvailableTime": "Verbleibende Zeit",
    "requestTimerFormat": "h [Stunde(n)] m [Minute(n)]",
    // Profile
    "profileScreen": "Profil",
    "profileRatingsText": "Bewertungen anzeigen",
    "profileIsCaring": "In Betreuung",
    "profileRequestButton": "Anstupsen",
    "profileRequestButtonDone": "Angestupst",
    "profileRequestButtonDisabled": "Keine verbleibenden Anstupser",
    "profileRequestButtonInfo": "verbleibende Anstupser",
    "profileRequestButtonBK": "Anfragen",
    "profileRequestButtonDoneBK": "Angefragt",
    "profileRequestButtonDisabledBK": "Keine verbleibenden Anfragen",
    "profileRequestButtonInfoBK": "verbleibende Anfragen",
    "profileBeforeTableText": "Folgende Parameter stimmen mit Ihren Anforderungen überein:",
    "profileBeforeTableTextBK": "Nachfolgend sehen Sie die Anforderungen/Umstände bei der pflegebedürftigen Person. Übereinstimmungen sind mit grünem Häkchen gekennzeichnet:",
    // Profile Table
    "profileCategory1": "Gesundheit",
    "profileCategory2": "Hilfe",
    "profileCategory3": "Wohnen",
    "profileCategory4": "Person",
    "profileCategory5": "Anforderungen an Ihre Betreuungskraft",
    "profileCategory6": "Freitexte",
    "profileMovementLabel" : "Beweglichkeit",
    "profileSicknessLabel": "Krankheiten / Gesundheitliche Einschränkungen",
    "profileOtherSicknessLabel": "Sonstige Einschränkungen",
    "profileSenseLabel": "Sinneswahrnehmung",
    "profileMentalLabel": "Geisteszustand",
    "profileSleepLabel": "Schlafverhalten",
    "profileEatingLabel": "Hilfe beim Essen",
    "profileBaseCareLabel": "Grundpflege / Körperhygiene",
    "profileHygieneLabel": "Hygiene nach Toilettengang",
    "profilePeeLabel": "Urinkontrolle",
    "profilePooLabel": "Stuhlkontrolle",
    "profileClothingLabel": "Hilfe beim Ankleiden",
    "profileLivingSituationLabel": "Wohnsituation",
    "profileLivingConditionLabel": "Zustand / Ausstattung des Haushaltes der zu betreuenden Person",
    "profileLivingPlaceLabel": "Wohnort",
    "profilePetsLabel": "Haustiere im Haushalt",
    "profileShoppingLabel": "Einkaufsmöglichkeiten zu Fuss erreichbar",
    "profileLivingBKLabel": "Steht Betreuungskraft zur Verfügung",
    "profileCharacterLabel": "Charaktereigenschaften",
    "profileSmokingLabel": "Ich bin Raucher",
    "profileActivitiesBKLabel": "Aktivitäten",
    "profileSexBKLabel": "Geschlecht",
    "profileSexBKLabel2": "Arbeitet mit",
    "profileGermanBKLabel": "Deutschkenntnisse",
    "profileSmokingBKLabel": "Raucher",
    "profileDrivingBKLabel": "Führerschein",
    "profileHobbiesLabel": "Tagesbeschäftigungen",
    "profileEnvironmentLabel": "Soziales Umfeld",
    "profileOtherLabel": "Sonstiges",
    // Profile Ratings Screen
    "profileRatingsScreen": "Bewertungen",
    // Messages Screen
    "messagesScreen": "Nachrichten",
    "messagesTabEmails": "E-Mails",
    "messagesTabChat": "Chat",
    // Chat
    "messagesChatTitle": "Wie können wir helfen?",
    "messagesChatText1": "Schreiben Sie uns einfach, geben Sie dazu Ihre Nachricht in das Textfeld unten ein und klicken auf den Senden-Button.",
    "messagesChatText2": "Sie können auch ein Bild senden, indem Sie auf das Büroklammer-Symbol klicken.",
    "messagesChatInput": "Nachricht eingeben ...",
    // E-Mails
    "messagesEmailBy": "von",
    "messagesEmailAttachments": "Anhänge:",
    // My Profile Screen
    "myProfileScreen": "Mein Profil",
    "myProfileHello": "Guten Tag",
    "myProfileMenuPB1": "Zu betreuende Personen",
    "myProfileMenuPB2": "Dokumente",
    "myProfileMenuPB3": "Bewertungen",
    "myProfileMenuPB4": "Kontaktpersonen",
    "myProfileMenuPB5": "Einstellungen",
    "myProfileMenuBK1": "Mein Profil",
    "myProfileMenuBK2": "Dokumente",
    "myProfileMenuBK3": "Bewertungen",
    "myProfileMenuBK4": "Einstellungen",
    // My Profile Documents Screen
    "documentsScreen": "Dokumente",
    "documentsCategory1": "Verträge",
    "documentsCategory2": "Rechnungen",
    "documentsCategory3": "Meine Referenzen",
    "documentsCategory4": "Mahnungen",
    "documentsCategory5": "Lohnabrechnungen",
    "documentsCategory6": "Sonstiges",
    // My Profile Ratings Screen
    "ratingsScreen": "Bewertungen",
    "ratingsTabToRate": "Zu bewerten",
    "ratingsTabMyRatings": "Meine Bewertungen",
    "ratingsNothingToRate": "Aktuell nichts zu bewerten.",
    "ratingsNoRatings": "Keine Bewertungen vorhanden.",
    "ratingsCategoryBK1": "Erscheinungsbild",
    "ratingsCategoryBK2": "Aufgeschlossenheit",
    "ratingsCategoryBK3": "Zusammenarbeit",
    "ratingsCategoryBK4": "Kompetenz",
    "ratingsCategoryBK5": "Haushalt",
    "ratingsCategoryBK6": "Sprachkenntnisse",
    "ratingsCategoryPB1": "Wohnqualität",
    "ratingsCategoryPB2": "Charakter",
    "ratingsCategoryPB3": "Grundpflegeaufwand",
    "ratingsCategoryPB4": "Soziales Umfeld",
    "ratingsCategoryPB5": "Freizeitregelung",
    "ratingsRateNowText": "Ihre Meinung ist uns wichtig.",
    "ratingsRateNowText2": "Jetzt bewerten!",
    // My Profile Rate BK Screen
    "rateBKScreen": "Betreuungskraft bewerten",
    "rateBKTitle": "Bewertung abgeben",
    "rateBKText": "Damit tragen Sie aktiv dazu bei, dass die Erwartungen unserer Kunden künftig noch besser zufrieden gestellt werden können. Für unser Betreuungspersonal ist Ihr Feedback ein wichtiger Baustein in Ihrem Berufsleben.",
    "rateBKText2": "Bewerten Sie mit Sternen von 1 (mangelhaft) bis 5 (sehr gut) die Arbeitsqualität des Betreuer",
    "rateBKCategory1": "Äußeres Erscheinungsbild des Betreuers",
    "rateBKCategory2": "Aufgeschlossenheit gegenüber dem Patienten und seinen Bedürfnissen",
    "rateBKCategory3": "Kommunikation mit dem Patienten und den Familienangehörigen",
    "rateBKCategory4": "Fachliche Kompetenz im Bereich der Körperpflege",
    "rateBKCategory5": "Haushaltsführung",
    "rateBKCategory6": "Sprachkenntnisse",
    "rateBKOtherLabel": "Sonstige Anmerkungen",
    "rateBKOther": "Persönliche Worte zur Ihrer Betreuungskraft",
    // My Profile Rate PB Screen
    "ratePBScreen": "Pflegebedürftigen bewerten",
    "ratePBTitle": "Bewertung abgeben",
    "ratePBText": "Bitte bewerten Sie Ihren Gast-Haushalt. Damit helfen Sie Ihren nachfolgenden Kollegen/Innen",
    "ratePBText2": "Bewerten Sie mit Sternen von 1 (mangelhaft) bis 5 (sehr gut) die Arbeitsqualität des Betreuer",
    "ratePBCategory1": "Wohnqualität",
    "ratePBCategory2": "Charakter",
    "ratePBCategory3": "Grundpflegeaufwand",
    "ratePBCategory4": "Soziales Umfeld",
    "ratePBCategory5": "Freizeitregelung",
    "ratePBOtherLabel": "Sonstige Anmerkungen",
    "ratePBOther": "Kurze Beschreibung des Gast-Haushaltes",
    // My Profile Contacts Screen
    "contactsScreen": "Kontakte",
    // My Profile Settings Screen
    "settingsScreen": "Einstellungen",
    "settingsDeleteAccountInfo": "Möchten Sie Ihren Account löschen?",
    "settingsDeleteAccountAction": "Klicken Sie hier",
    "settingsDeleteAccount": "Konto löschen",
    "settingsDeleteAccountText1": "Die Löschung Ihres Kontos kann nicht rückgängig gemacht werden!",
    "settingsDeleteAccountText2": "Ihre Konto-Löschung hat folgende Konsequenzen:",
    "settingsDeleteAccountText3": "Ihr Konto wird gelöscht",
    "settingsDeleteAccountText4": "All Ihre persönlichen Informationen werden gelöscht",
    "settingsDeleteAccountText5": "All Ihre geplanten oder laufenden Betreuungen werden storniert",

    "Frau": "Frau",
    "Herr": "Herr",
    "Divers": "Divers",

    "Pflegegrad 1": "Pflegegrad 1",
    "Pflegegrad 2": "Pflegegrad 2",
    "Pflegegrad 3": "Pflegegrad 3",
    "Pflegegrad 4": "Pflegegrad 4",
    "Pflegegrad 5": "Pflegegrad 5",
}